import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../store/ModalStore'
import ToastStore from 'store/ToastStore'
import backendApis from 'utils/backendApis'
import movingFinger from '../../json/gifJson/movingFinger.json'
import LottieGif from './LottieGif'

const FloatingButtonNudgeModal = observer(({ token }) => {
  const [softOnboardingFinished, setSoftOnboardingFinished] = useState(
    window.localStorage.getItem('softOnboardingFinished') === 'true',
  )
  const [onboardingStep, setOnboardingStep] = useState(1)
  const [firstPage, setFirstPage] = useState(true)
  const [imageUri, setImageUri] = useState(
    'url(../icon/floatingButtonNudge/floatingNudgeModalOne.png)',
  )
  const [isButtonPressed, setIsButtonPressed] = useState(false)

  useEffect(() => {
    const handleOverlayPermission = ({ data }) => {
      const parsedData = JSON.parse(data)
      if (parsedData?.type === 'checkOverlayPermission') {
        if (parsedData?.result === true) {
          // logger
          backendApis.logABclick(token, 'PUT', {
            location: 'nudgeModal',
            // 이미 백그라운드 권한이 있음
            action: 'overlayPermissionGranted',
            data: {
              description: 'permission already granted and button turned on',
              overlayRequestResult: parsedData?.result,
            },
          })

          ToastStore.toastOn({
            type: 'success',
            message: '알림화분이 생겼어요!',
            duration: 3000,
          })
          window.location.href = '#floatingButtonShow'
          ModalStore.setIsModalOpen('basic')
        } else {
          setFirstPage(false)
          setImageUri(
            'url(../icon/floatingButtonNudge/floatingNudgeModalTwo.png)',
          )
        }
      }

      if (parsedData?.type === 'overlayRequest') {
        const overlayRequestResult = parsedData?.result

        if (overlayRequestResult === true) {
          // logger
          backendApis.logABclick(token, 'PUT', {
            location: 'nudgeModal',
            // 백그라운드 권한이 없었음. 권한 새로 받음
            action: 'overlayPermissionGranted',
            data: {
              description: 'permission granted now and button turned on',
              overlayRequestResult: parsedData?.result,
            },
          })

          ToastStore.toastOn({
            type: 'success',
            message: '알림화분이 생겼어요!',
            duration: 4000,
          })
          window.location.href = '#floatingButtonShow'
          ModalStore.setIsModalOpen('basic')
        } else {
          // logger
          backendApis.logABclick(token, 'PUT', {
            location: 'nudgeModal',
            // 백그라운드 권한이 없었음. 권한 주는거 거절함.
            action: 'overlayPermissionRejected',
            data: {
              description: 'permission rejected',
              overlayRequestResult: parsedData?.result,
            },
          })

          ToastStore.toastOn({
            type: 'emoji',
            message: `권한을 허용하지 않으면 이용할 수 없어요`,
            emoji: '😢',
            duration: 4000,
          })
          ModalStore.setIsModalOpen('basic')
        }
      }
    }

    document.addEventListener('message', handleOverlayPermission)
    return () => {
      document.removeEventListener('message', handleOverlayPermission)
    }
  }, [])

  useEffect(() => {
    if (!softOnboardingFinished) {
      setTimeout(() => {
        setOnboardingStep(2)
      }, 1500)
      setTimeout(() => {
        setOnboardingStep(3)
      }, 3000)
    }
  }, [softOnboardingFinished])

  if (!softOnboardingFinished) {
    return (
      <div className='flex-1 fixed left-0 top-0 z-[1100] w-full h-full bg-[rgba(0,0,0,0.8)]'>
        {onboardingStep < 6 && (
          <div>
            <img
              className='absolute w-[30vw] h-[30vw] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 animate-fade-in z-10'
              src='/icon/floatingButtonNudge/floating_icon.png'
              alt=''
            />
            <img
              className='absolute w-[50vw] h-[50vw] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 animate-spin-3s'
              src='/icon/floatingButtonNudge/floating_background.png'
              alt=''
            />
          </div>
        )}
        {onboardingStep >= 6 && onboardingStep < 8 && (
          <div>
            <img
              className='absolute w-[30vw] h-[30vw] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 animate-fade-in-out z-10'
              src='/icon/floatingButtonNudge/floating_icon.png'
              alt=''
            />
            <img
              className='absolute w-[50vw] h-[50vw] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 animate-spin-3s'
              src='/icon/floatingButtonNudge/floating_background.png'
              alt=''
            />
          </div>
        )}

        {onboardingStep >= 2 && onboardingStep < 4 && (
          <div className='absolute top-1/2 left-1/2 mt-[30vw] -translate-x-1/2 -translate-y-1/2 animate-fade-in bg-[#F9F0DE] text-[#402C24] text-[5vw] px-[6vw] py-[3vw] rounded-md'>
            이 화분은 뭐지..?!
          </div>
        )}

        {onboardingStep === 3 && (
          <button
            onClick={() => {
              setOnboardingStep(4)
              setTimeout(() => {
                setOnboardingStep(5)
              }, 2500)
            }}
          >
            <LottieGif
              animationData={movingFinger}
              className='absolute w-[30vw] top-1/2 left-1/2 ml-[10vw] mt-[46vw] -translate-x-1/2 -translate-y-1/2 animate-fade-in'
            />
            <div className='absolute w-[46vw] top-1/2 left-1/2 mt-[44vw] -translate-x-1/2 -translate-y-1/2 animate-fade-in text-[4vw] text-end'>
              다음 >
            </div>
          </button>
        )}

        {onboardingStep >= 4 && onboardingStep < 6 && (
          <>
            <button
              onClick={() => {
                setOnboardingStep(6)
                setTimeout(() => {
                  setOnboardingStep(7)
                }, 2500)
              }}
            >
              <div
                className='absolute top-1/2 left-1/2 mt-[30vw] -translate-x-1/2 -translate-y-1/2 animate-fade-in bg-[#F9F0DE] text-[#402C24] text-[5vw] px-[6vw] py-[3vw] rounded-md'
                style={{ width: '90vw' }}
              >
                "저는 물 마시는걸 잊지않도록 도와주는 화분이에요!"
              </div>
              {onboardingStep === 5 && (
                <>
                  <div className='absolute w-[90vw] top-1/2 left-1/2 mt-[46vw] -translate-x-1/2 -translate-y-1/2 animate-fade-in text-[4vw] text-end'>
                    다음 >
                  </div>
                  <LottieGif
                    animationData={movingFinger}
                    className='absolute w-[30vw] top-1/2 left-1/2 ml-[32vw] mt-[50vw] -translate-x-1/2 -translate-y-1/2 animate-fade-in'
                  />
                </>
              )}
            </button>

            <img
              className='absolute w-[90vw] top-1/2 left-1/2 mt-[80vw] -translate-x-1/2 -translate-y-1/2 animate-fade-in'
              src='/icon/floatingButtonNudge/floating_missionComponent.png'
              alt=''
            />
          </>
        )}

        {onboardingStep >= 6 && onboardingStep < 8 && (
          <>
            <button
              onClick={() => {
                setOnboardingStep(8)
                setTimeout(() => {
                  setOnboardingStep(9)
                }, 2500)
              }}
            >
              <div
                className='absolute top-1/2 left-1/2 mt-[30vw] -translate-x-1/2 -translate-y-1/2 animate-fade-in bg-[#F9F0DE] text-[#402C24] text-[5vw] px-[6vw] py-[3vw] rounded-md'
                style={{ width: '90vw' }}
              >
                "미션이 가능해지면 잠시 나타났다가, 완료하면 사라져요"
              </div>
              {onboardingStep === 7 && (
                <>
                  <div className='absolute w-[90vw] top-1/2 left-1/2 mt-[46vw] -translate-x-1/2 -translate-y-1/2 animate-fade-in text-[4vw] text-end'>
                    다음 >
                  </div>
                  <LottieGif
                    animationData={movingFinger}
                    className='absolute w-[30vw] top-1/2 left-1/2 ml-[32vw] mt-[50vw] -translate-x-1/2 -translate-y-1/2 animate-fade-in'
                  />
                </>
              )}
            </button>
          </>
        )}

        {onboardingStep >= 8 && (
          <div>
            <img
              className='absolute w-[30vw] h-[30vw] mt-[-10vw] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 animate-move-left-right'
              src='/icon/floatingButtonNudge/floating_icon.png'
              alt=''
            />
            <img
              className='absolute w-[14vw] h-[14vw] mt-[6vw] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 animate-move-left-right'
              src='/icon/floatingButtonNudge/floating_finger.png'
              alt=''
            />
            <div
              className='absolute top-1/2 left-1/2 mt-[30vw] -translate-x-1/2 -translate-y-1/2 animate-fade-in bg-[#F9F0DE] text-[#402C24] text-center text-[5vw] px-[6vw] py-[3vw] rounded-md'
              style={{ width: '90vw' }}
            >
              "자유롭게 드래그해서 움직일 수 있고, 클릭하면 올팜으로 이동해요"
            </div>
            {onboardingStep === 9 && (
              <button
                onClick={() => {
                  setSoftOnboardingFinished(true)
                  window.localStorage.setItem('softOnboardingFinished', 'true')
                }}
              >
                <div className='absolute w-[90vw] top-1/2 left-1/2 mt-[46vw] -translate-x-1/2 -translate-y-1/2 animate-fade-in text-[4vw] text-end'>
                  다음 >
                </div>
                <LottieGif
                  animationData={movingFinger}
                  className='absolute w-[30vw] top-1/2 left-1/2 ml-[34vw] mt-[50vw] -translate-x-1/2 -translate-y-1/2 animate-fade-in'
                />
              </button>
            )}
          </div>
        )}
      </div>
    )
  }

  return (
    <div className='flex-1 fixed left-0 top-0 z-[1100] w-full h-full bg-[rgba(0,0,0,0.8)]'>
      <div
        className='absolute flex flex-col justify-end items-center w-[90vw] h-[120vw] top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 pb-[6vw]'
        style={{
          backgroundImage: imageUri,
          backgroundSize: '100% 100%',
          backgroundRepeat: 'cover',
        }}
      >
        <button
          className='w-[70%] py-[14px] text-[#442B22] text-[5vw] font-bold rounded-3xl'
          style={{
            background: isButtonPressed
              ? 'linear-gradient(180deg, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
              : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
            boxShadow: isButtonPressed
              ? 'inset 4px 4px 5px rgba(109, 40, 1, 0.15)'
              : '',
          }}
          onPointerDown={() => {
            setIsButtonPressed(true)
          }}
          onPointerCancel={() => {
            setIsButtonPressed(false)
          }}
          onPointerUp={() => {
            setIsButtonPressed(false)
          }}
          onClick={() => {
            if (firstPage) {
              // logger
              backendApis.logABclick(token, 'PUT', {
                location: 'nudgeModal',
                action: 'clickedFirstPageButton',
                data: {
                  description: 'clicked CTA button on the first intro page',
                },
              })

              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  type: 'checkOverlayPermission',
                }),
              )
            } else {
              // logger
              backendApis.logABclick(token, 'PUT', {
                location: 'nudgeModal',
                action: 'clickedSecondPageButton',
                data: {
                  description:
                    'clicked CTA button on the second permission page',
                },
              })

              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  type: 'requestOverlayPermission',
                }),
              )
            }
          }}
        >
          {firstPage && '네, 알려주세요!'}
          {!firstPage && '확인'}
        </button>
        {firstPage && (
          <button
            className='text-[3.8vw] py-[4px] w-[70%] text-[#736E64] mt-[2vw]'
            onClick={() => {
              // logger
              backendApis.logABclick(token, 'PUT', {
                location: 'nudgeModal',
                action: 'clickedFirstPageRejectButton',
                data: {
                  description: 'clicked reject button on the first intro page',
                },
              })

              ModalStore.setIsModalOpen('basic')
            }}
          >
            아니요, 괜찮아요
          </button>
        )}
        <button
          className=' z-[1101] w-[8%] top-[0vw] right-[4vw] absolute'
          onClick={() => {
            // logger
            backendApis.logABclick(token, 'PUT', {
              location: 'nudgeModal',
              action: 'clickedCloseButton',
              data: {
                description: 'clicked close button',
                page: firstPage ? 'firstPage' : 'secondPage',
              },
            })

            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img className='z-[1101]' src='/icon/modalCloseButton.png' alt='' />
        </button>
      </div>
    </div>
  )
})

export default FloatingButtonNudgeModal
