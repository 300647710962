import backendApis from 'utils/backendApis'

export const ONLY_ONE_PER_DAY_ERROR = 'Exceeds the minimum interval to create a gifticon'

// 수확 완료에 대한 검증
export const farmFinish = async (token) => {
  const result = await backendApis.finishedFarm(token, 'PUT', {})

  if (result?.status === 200) {
    return true
  }

  throw new Error()
}

export const harvestComplete = async ({ token, itemType, requestId }) => {
  const result = await backendApis.harvestComplete(token, itemType, requestId)
  if (result?.status === 200) {
    return true
  }

  if (result?.status === 500 && result?.msg === ONLY_ONE_PER_DAY_ERROR) {
    throw new Error(ONLY_ONE_PER_DAY_ERROR)
  }

  throw new Error(result.message)
}

export const resetGame = async () => {
  const result = await backendApis.resetGame()
  if (result?.status === 200) {
    return true
  }

  throw new Error()
}
