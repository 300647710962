import ModalFrame from 'comps/atoms/Modals/ModalFrame'
import { observer } from 'mobx-react-lite'
import ModalStore from 'store/ModalStore'

const BadahamRewardModal = observer(
  ({rewardInfo}) => {

    const {rewardType, rewardAmount} = (()=>{
      if (rewardInfo?.water) {
        return {rewardType: 'water', rewardAmount: rewardInfo?.water}
      }
      if (rewardInfo?.fertilizer) {
        return {rewardType: 'fertilizer', rewardAmount: rewardInfo?.fertilizer}
      }
      if (rewardInfo?.fertilizerSet) {
        return {rewardType: 'fertilizerSet', rewardAmount: rewardInfo?.fertilizerSet}
      }
    })()

    const getRewardDescription = ({ rewardType, rewardAmount }) => {
      if (rewardType === 'water') {
        return `물 ${rewardAmount}g 획득`
      }
      if (rewardType === 'fertilizer') {
        return `비료 ${rewardAmount}개 획득`
      }
      if (rewardType === 'fertilizerSet') {
        return `고급비료 ${rewardAmount} 획득`
      }
    }
    return (
      <>
        <ModalFrame
          closeButtonStyle={styles.closeButton}
          modalContentStyle={styles.modalContent}
        >
          <div className='flex flex-col gap-[4vw]'>
            <div className='text-[#6A3615] text-center text-[30px] mt-[4vw]'>
              미션 완료
            </div>
            <div className='flex justify-center items-center gap-[4vw]'>
              <img
                src={`../icon/${rewardType}.png`}
                alt='reward'
                className='w-[24%]'
              />
            </div>
            <div className='text-[#6A3615] text-[4vw] text-center mt-[2vw]'>
              {getRewardDescription({
                rewardAmount,
                rewardType
              })}
            </div>
            <div
              className='p-[2vw] bg-[#FFC634] w-[45vw] rounded-[8vw] text-[#6A3615] 
              shadow-[0px_2px_0px_0px_#FFA234,0px_-1px_0px_0px_#FFD874_inset] 
              h-[11vw] flex justify-center items-center text-[4vw] mx-auto'
              onClick={() => {
                console.log('badahamRecordModal 확인 클릭')
                ModalStore?.setIsModalOpen('basic')
              }}
            >
              확인
            </div>
          </div>
        </ModalFrame>
      </>
    )
  },
)

const styles = {
  closeButton: {
    position: 'absolute',
    top: '-18vw',
    right: '0vw',
    width: '8vw',
  },
  modalContent: {
    width: '70vw',
    height: '80vw',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'relative',
    background: '#F9F0DE',
    border: '2vw solid #946134',
    borderRadius: '5vw',
  },
}

export default BadahamRewardModal
