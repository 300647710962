const translateOfferwallDescription = (type) => {
  switch (type) {
    case 'cpm':
    case 'cpc':
      return '클릭하'
    case 'cpa':
      return '참여하'
    case 'cpk':
      return '카카오톡 채널 추가하'
    case 'cpq':
    case 'cpqlite':
      return '퀴즈풀'
    case 'cpi':
      return '설치하'
    case 'cps':
      return '구매하'
    case 'cptiktok':
      return '틱톡 팔로우하'
    case 'cpl':
      return '페이스북 좋아요하'
    case 'cpyoutube':
      return '유튜브 구독하'
    case 'cpnstore':
      return '네이버 스토어 알림설정하'
    case 'cpinsta':
      return '인스타그램 팔로우하'
    case 'cpylike':
      return '유튜브 구독 및 좋아요하'
    case 'cpe':
      return '이벤트 참여하'
    case 'cpcquiz':
      return '퀴즈풀'
    default:
      return '참여하'
  }
}

export default translateOfferwallDescription