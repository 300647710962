import React from 'react'
import { observer } from 'mobx-react-lite'
import WhiteTextTimer from 'comps/WhiteTextTimer'

const MissionButton = observer(
  ({ dailyMissionInfo, quest, setLastTimeDealChecked }) => {
    if (dailyMissionInfo?.missionStatus[quest?.type]) {
      return (
        <div>
          <img
            style={{
              width: '16vw',
            }}
            src={quest?.clearButton}
            alt=''
          />
        </div>
      )
    } else if (quest?.cooltime) {
      return (
        <div
          style={{
            fontSize: '3.5vw',
            marginLeft: '1vw',
          }}
        >
          <WhiteTextTimer
            onTimeEnd={() => {
              if (quest?.type === 'timeDealBrowsing') {
                setLastTimeDealChecked(null)
              }
              if (quest?.type === 'quiz') {
                setCanSolveQuiz(true)
              }
            }}
            timeStamp={quest?.cooltime}
            timerMinutes={0}
            color='#442b22'
          />
        </div>
      )
    } else if (!quest?.canClear) {
      return (
        <div
          onClick={() => {
            quest?.actionClick()
          }}
        >
          <img
            style={{
              width: '16vw',
            }}
            src={quest?.moveButton}
            alt=''
          />
        </div>
      )
    } else if (quest?.customButton) {
      return (
        <div
          className='flex flex-col justify-center items-center'
          style={{
            backgroundImage: `url(/dailyMissionV2/buttonBackground.png)`,
            width: '16vw',
            height: '13vw',
          }}
          onClick={() => {
            quest?.onclick()
          }}
        >
          <img
            className='relative top-[0.5vw] w-[6vw] h-[6vw]'
            src={'/icon/waterWithoutPadding.png'}
            alt=''
          />
          <span style={{ fontSize: '3vw' }}>물 10g</span>
        </div>
      )
    } else {
      return (
        <div
          onClick={() => {
            quest?.onclick()
          }}
        >
          <img
            style={{
              width: '16vw',
            }}
            src={quest?.activeButton}
            alt=''
          />
        </div>
      )
    }
  },
)

export default MissionButton
