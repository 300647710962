import React, { useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import dayjs from 'dayjs'
import ModalStore from '../../../store/ModalStore'
import DealAutomationStore from '../../../store/DealAutomationStore'
import TimeStore from '../../../store/TimeStore'
import AB from 'utils/ab'

import moment from 'moment'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
const day = moment().date()

//패션 소픔
//https://assets.ilevit.com/fa4f3409-d313-4b66-8cd6-be6c16068a06.png

const ImageSprite = () => {
  if (
    TimeStore.currentDayJS >
      dayjs(DealAutomationStore.firstWinnerDealData.timeRangeStart) &&
    TimeStore.currentDayJS <
      dayjs(DealAutomationStore.firstWinnerDealData.timeRangeEnd)
  ) {
    return {
      modalImage: DealAutomationStore.firstWinnerDealData.background,
      purchaseScreenBanner:
        DealAutomationStore.firstWinnerDealData.purchaseScreenBanner,
      purchaseScreenText:
        DealAutomationStore.firstWinnerDealData.purchaseScreenText,
    }
  } else if (
    TimeStore.currentDayJS >
      dayjs(DealAutomationStore.secondWinnerDealData.timeRangeStart) &&
    TimeStore.currentDayJS <
      dayjs(DealAutomationStore.secondWinnerDealData.timeRangeEnd)
  ) {
    return {
      modalImage: DealAutomationStore.secondWinnerDealData.background,
      purchaseScreenBanner:
        DealAutomationStore.secondWinnerDealData.purchaseScreenBanner,
      purchaseScreenText:
        DealAutomationStore.secondWinnerDealData.purchaseScreenText,
    }
  }
}

const WinnerDealModal = observer(
  ({ token, farmData, setFarmData, isLoading }) => {
    const throttleRef = useRef({})
    const [isButtonPressed, setIsButtonPressed] = useState(false)
    const naverWinnerDealLogic = AB(farmData?.userId, 'naverWinnerDealLogic', [
      50,
    ])
    return (
      <>
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 5004,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <button
            style={{
              width: '10%',
              top: '18%',
              right: '15%',
              position: 'absolute',
              zIndex: 1113,
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
            }}
          >
            <img
              style={{
                position: 'absolute',
                zIndex: 101,
              }}
              src='/icon/modalCloseButton.png'
              alt=''
            />
          </button>
          <div className='w-[80vw] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex absolute justify-around items-center flex-col'>
            <img
              src={ImageSprite()?.modalImage}
              alt='abc'
              style={{ width: '80vw' }}
            />
            <div
              style={{
                background: isButtonPressed ? '#F7C82A' : '#F7C82A',
                boxShadow: isButtonPressed
                  ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                  : '',
                width: '60vw',
                height: '14vw',
                borderRadius: 99,
                color: 'black',
                fontFamily: 'maplestory',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '4.5vw',
                position: 'absolute',
                bottom: '8vw',
                left: '10vw',
              }}
              // 오늘의특가 진입 분기
              onClick={() => {
                AlfarmEventLogger({
                  throttleRef,
                  locationType: 'modal',
                  locationName: ModalStore.isModalOpen,
                  eventType: 'click',
                  eventName: `cta`,
                  data: {},
                  collection: 'UserAlfarmClickLog',
                })

                if (naverWinnerDealLogic !== 'a') {
                  if (day % 2 === 0) {
                    window.location.href = `#generalNavigator.${JSON.stringify({
                      screen: 'AlfarmNaverDealScreen',
                      params: {
                        dealType: 'Naver',
                        title: ImageSprite()?.purchaseScreenText,
                        image: ImageSprite()?.purchaseScreenBanner,
                        ratio: 99 / 360,
                        enteringLocation: 'modal',
                      },
                    })}`
                    ModalStore.setIsModalOpen('winnerDeal')
                  } else if (day % 2 !== 0) {
                    window.location.href = `#generalNavigator.${JSON.stringify({
                      screen: 'AlfarmWinnerDealScreen',
                      params: {
                        dealType: 'Winner',
                        title: ImageSprite()?.purchaseScreenText,
                        image: ImageSprite()?.purchaseScreenBanner,
                        ratio: 99 / 360,
                        enteringLocation: 'modal',
                      },
                    })}`
                    ModalStore.setIsModalOpen('winnerDeal')
                  }
                } else {
                  if (day % 2 === 0) {
                    window.location.href = `#generalNavigator.${JSON.stringify({
                      screen: 'AlfarmWinnerDealScreen',
                      params: {
                        dealType: 'Winner',
                        title: ImageSprite()?.purchaseScreenText,
                        image: ImageSprite()?.purchaseScreenBanner,
                        ratio: 99 / 360,
                        enteringLocation: 'modal',
                      },
                    })}`
                    ModalStore.setIsModalOpen('winnerDeal')
                  } else if (day % 2 !== 0) {
                    window.location.href = `#generalNavigator.${JSON.stringify({
                      screen: 'AlfarmNaverDealScreen',
                      params: {
                        dealType: 'Naver',
                        title: ImageSprite()?.purchaseScreenText,
                        image: ImageSprite()?.purchaseScreenBanner,
                        ratio: 99 / 360,
                        enteringLocation: 'modal',
                      },
                    })}`
                    ModalStore.setIsModalOpen('winnerDeal')
                  }
                }
              }}
              // onClick={() => {

              //   window.location.href = `#generalNavigator.${JSON.stringify({
              //     screen: "AlfarmWinnerDealScreen",
              //     params: {
              //       dealType: "Winner",
              //       title: ImageSprite()?.purchaseScreenText,
              //       image: ImageSprite()?.purchaseScreenBanner,
              //       ratio: 99 / 360,
              //     },
              //   })}`;
              //   ModalStore.setIsModalOpen("winnerDeal");
              // }}

              onPointerDown={() => {
                setIsButtonPressed(true)
              }}
              onPointerCancel={() => {
                setIsButtonPressed(false)
              }}
              onPointerUp={() => {
                setIsButtonPressed(false)
              }}
            >
              상품 구매하고 혜택 받기
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default WinnerDealModal
