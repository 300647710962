import React from 'react'

import { observer } from 'mobx-react-lite'
import OfferwallModalStore from 'store/OfferwallModalStore'
import { OfferwallButton } from './OfferwallButton'

export const OfferwallModal = observer(() => {
  const currentModal = OfferwallModalStore?.currentModal
  const onCancel = currentModal?.onCancel
  const onSubmit = currentModal?.onSubmit || (() => {})
  const showClickButton = currentModal?.showClickButton ?? true

  const closeModal = (onClick) => {
    OfferwallModalStore.setCurrentModal({
      ...currentModal,
      isClosing: true,
    })
    if (onClick) {
      onClick()
    }
    setTimeout(() => {
      OfferwallModalStore.setCurrentModal({
        open: false,
        isClosing: false,
      })
    }, 400)
  }

  if (!currentModal?.open) return null

  let fadeInOut
  if (currentModal?.open) {
    if (currentModal?.isClosing) {
      fadeInOut = 'scale-out-center'
    } else {
      fadeInOut = 'scale-in-center'
    }
  } else {
    fadeInOut = ''
  }

  return (
    <div className='fixed top-0 bottom-0 left-0 right-0 z-40 flex items-center justify-center bg-black bg-opacity-50'>
      <div
        className={`${fadeInOut} absolute z-50 flex flex-col items-center justify-center p-5 bg-white max-w-80 rounded-xl min-w-72`}
      >
        <div className='flex flex-col items-center w-full '>
          <div className='my-2 text-2xl font-bold text-black'>{currentModal?.title}</div>

          {currentModal?.imgUrl && (
            <img
              alt='main'
              src={currentModal.imgUrl}
              className='w-full rounded-xl'
            />
          )}

          <p className='w-full p-4 text-[#616161] mb-4 font-[500] text-lg leading-7 text-center break-words whitespace-pre-wrap text-pretty max-h-[440px] overflow-y-auto'>
            {currentModal?.content}
          </p>

          <div className='flex flex-row justify-between w-full gap-4'>
            {onCancel && (
              <OfferwallButton
                className='shadow-none bg-grey40'
                style={{ margin: 0 }}
                clickable
                text={currentModal?.onCancelText || '취소'}
                onClick={() => {
                  closeModal(onCancel)
                }}
              />
            )}
            {showClickButton && (
              <OfferwallButton
                className='shadow-none'
                style={{ margin: 0 }}
                clickable
                text={currentModal?.onSubmitText || '확인'}
                onClick={() => closeModal(onSubmit)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
})
