import { action, makeObservable, observable } from 'mobx'

class OfferwallModalStoreClass {
  currentModal = {
    open: false,
    isClosing: false,
    title: '',
    onSubmitText: '확인',
    imgUrl: '',
  }
  modalForce = false

  constructor() {
    makeObservable(this, {
      currentModal: observable,
      modalForce: observable,

      setCurrentModal: action,
      setModalForce: action,
    })
  }

  setCurrentModal(currentModal) {
    this.currentModal = currentModal
  }

  setModalForce(modalForce) {
    this.modalForce = modalForce
  }
}

const OfferwallModalStore = new OfferwallModalStoreClass()
export default OfferwallModalStore
