import { useState, useEffect } from 'react'

const InitializeTimer = ({ className, suffix = '후 전체 초기화', showMilliseconds = false }) => {
  const [remainTime, setRemainTime] = useState('')
            
  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date()
      const tomorrow = new Date()
      tomorrow.setHours(24, 0, 0, 0)
      const diff = tomorrow - now
      
      const hours = Math.floor(diff / (1000 * 60 * 60))
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((diff % (1000 * 60)) / 1000)
      const milliseconds = Math.floor((diff % 1000) / 100)
      
      const timeString = showMilliseconds 
        ? `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.${milliseconds}`
        : `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`

      setRemainTime(`${timeString} ${suffix}`)
    }, showMilliseconds ? 100 : 1000)  // milliseconds 표시 여부에 따라 interval 조정

    return () => clearInterval(timer)
  }, [suffix, showMilliseconds])

  return <span className={className}>{remainTime}</span>
}

export default InitializeTimer