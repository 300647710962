import useCheckCodePushVersion from '../../utils/customHooks/useConvertCodePushVersion'
import dayjs from 'dayjs'
import { nanoid } from 'nanoid'
import React, { useState } from 'react'
import GganbuInviteModal from './gganbuInviteModal'
import ModalStore from '../../store/ModalStore'
import TimeStore from '../../store/TimeStore'
import { useNavigate } from 'react-router-dom'
import UserStore from '../../store/UserStore'
import backendApis from 'utils/backendApis'
import ABStore from 'store/ABStore'

const IconButton = ({
  codePushVerStandard = '',
  onClick = () => {},
  src = '',
}) => {
  return (
    <button
      style={{
        marginRight: '2vw',
        paddingTop: '0.5vw',
      }}
      onClick={onClick}
    >
      <img
        style={{
          width: '18vw',
          height: '17vw',
        }}
        alt=''
        src={src}
      />
    </button>
  )
}

const GganbuIntro = ({ props }) => {
  const {
    gganbuData: { gganbuList },
  } = props
  const gganbuLength = gganbuList?.length
  if (gganbuLength) return null
  return (
    <IconButton
      onClick={() => {
        ModalStore.setIsModalOpen('gganbuFarmIntro')
      }}
      src={`/icon/gganbuIntrov2.png`}
    />
  )
}

const shareKakao = () => {
  window.location.href = '#alfarmGganbuInvite'
}

const AddGganbu = ({ props }) => {
  const { gganbuData, userId } = props
  const gganbuLength = gganbuData?.gganbuList?.length

  if (gganbuLength && gganbuLength >= 20) return null
  return (
    <div>
      <button
        onClick={() => {
          ModalStore.setIsModalOpen('gganbuInvite')
        }}
      >
        <img
          style={{
            height: '15.5vw',
            width: '11.63vw',
            marginLeft: 4,
            marginTop: '0.5vw',
          }}
          alt=''
          src={'/icon/gganbuInviteButtonNew.png'}
        />
      </button>
      {ModalStore?.isModalOpen === 'gganbuInvite' && (
        <GganbuInviteModal token={props.token} userId={userId} />
      )}
    </div>
  )
}

const DeleteGganbu = ({ props }) => {
  const { gganbuLength, setShowGganbuDeleteModal } = props
  if (gganbuLength < 1) return null
  return (
    <div>
      <button
        onClick={() => {
          ModalStore.setIsModalOpen('gganbuDelete')
        }}
      >
        <img
          style={{
            height: '15.5vw',
            width: '11.63vw',
            marginTop: '0.5vw',
            marginLeft: 4,
          }}
          alt=''
          src={'/icon/gganbuDeleteButtonNew.png'}
        />
      </button>
    </div>
  )
}

const SeeAllGganbu = ({ props }) => {
  const { token, randomString, setLoadingToGganbuIndicator } = props
  const navigate = useNavigate()

  const onClick = () => {
    backendApis.logABclick(token, 'PUT', {
      location: 'gganbuFarm',
      action: 'enterGganbuFarmList',
      data: {
        testingGroup: 'b',
      },
    })
    navigate(`/gganbuFarmList/?token=${token}&randomNumber=${randomString}`)
  }
  return (
    <button
      style={{
        position: 'relative',
      }}
      onClick={() => {
        setLoadingToGganbuIndicator(true)
        onClick()
        setTimeout(() => {
          setLoadingToGganbuIndicator(false)
        }, 3000)
        window.localStorage.setItem('gganbu_list', true)
      }}
    >
      {!window.localStorage.getItem('gganbu_list') && (
        <div
          style={{
            position: 'absolute',
            background: '#EA3323',
            borderRadius: '5vw',
            padding: '1.5vw',
            right: '1vw',
          }}
        />
      )}
      <img
        className='w-[13vw] h-[13vw] mx-[1vw] mt-[0.5vw]'
        src={'/icon/gganbuFarm/icn_gganbuFarmList_visitFarm_AllList.png'}
      />
      <div className='text-[3vw] mx-[1vw] pt-[0.5vw] font-maplestory'>
        맞팜목록
      </div>
    </button>
  )
}

const EachGganbu = ({ item, props, gganbuId }) => {
  const {
    token,
    itemType,
    randomString,
    scrollPositionX,
    gganbuData,
    setLoadingToGganbuIndicator,
  } = props
  const navigate = useNavigate()

  if (!gganbuData?.gganbuList?.length) return null
  const current = gganbuId && item?.userId?.toString() === gganbuId?.toString()
  const onClick = () => {
    if (current) return
    navigate(
      `/gganbuFarm/${item?.userId}?token=${token}&itemType=${itemType}&gganbuName=${item?.userName}&randomNumber=${randomString}&prevScrollPosition=${scrollPositionX}`,
    )
  }
  return (
    <button
      style={{
        textAlign: 'center',
      }}
      onClick={() => {
        setLoadingToGganbuIndicator(true)
        onClick()
        setTimeout(() => {
          setLoadingToGganbuIndicator(false)
        }, 3000)
      }}
    >
      <div
        style={{
          position: 'relative',
        }}
      >
        <img
          style={{
            width: '13vw',
            height: '13vw',
            borderRadius: 55,
            marginRight: 8,
            marginTop: '0.5vw',
            border: current ? '3px solid #F6C64C' : null,
          }}
          src={item?.userImage}
          onError={(e) => {
            e.target.src = UserStore?.default_profile_image
          }}
        />
        {item?.lastGetWaterAt === undefined && (
          <div
            style={{
              position: 'absolute',
              bottom: '-0.7vw',
              right: '-1vw',
            }}
          >
            <img
              style={{
                width: 35,
                height: 35,
              }}
              src='/icon/canGetWaterGganbu.png'
              alt=''
            />
          </div>
        )}
        {TimeStore.currentDayJS - dayjs(item?.lastGetWaterAt) >= 14400000 && (
          <div
            style={{
              position: 'absolute',
              bottom: '-0.7vw',
              right: '-1vw',
            }}
          >
            <img
              style={{
                width: 35,
                height: 35,
              }}
              src='/icon/canGetWaterGganbu.png'
              alt=''
            />
          </div>
          // )
        )}
        {item?.lastGetWaterAt &&
          TimeStore.currentDayJS - dayjs(item?.lastGetWaterAt) < 14400000 && (
            <div
              style={{
                position: 'absolute',
                bottom: '-0.5vw',
                right: '-1vw',
              }}
            >
              <img
                style={{
                  width: 35,
                  height: 35,
                }}
                src='/icon/waitGetWaterGganbu.png'
                alt=''
              />
            </div>
          )}
      </div>

      <div
        style={{
          color: current ? '#F6C64C' : 'white',
          fontFamily: 'maplestory',
          fontSize: '3vw',
          paddingTop: '0.5vw',
        }}
      >
        {item?.userName?.length > 5 ? (
          <>{item?.userName.slice(0, 5)}..</>
        ) : (
          <>{item?.userName.slice(0, 5)}</>
        )}
      </div>
    </button>
  )
}

const Gganbus = (props) => {
  const { gganbuData, isFromGganbuIntro } = props
  const gganbuList = gganbuData?.gganbuList
  const gganbuId = props?.gganbuId

  return (
    <div className='flex-shrink-0 flex flex-row'>
      {/* {!isFromGganbuIntro && <GganbuIntro props={props} />} */}
      <SeeAllGganbu props={props} />
      {gganbuList?.map((item) => (
        <EachGganbu
          key={nanoid + Math.random()}
          item={item}
          props={props}
          gganbuId={gganbuId}
        />
      ))}
      <AddGganbu props={props} />
      <DeleteGganbu props={props} />
    </div>
  )
}

export default Gganbus
