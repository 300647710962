import moment from 'moment'
import React, { useState } from 'react'
import UserStore from 'store/UserStore'

const sprite = {
  getWaterButton: {
    images: [`/icon/bottomMenu/getWaterButtonv2.png`],
  },
  getFertButton: {
    images: [`/icon/bottomMenu/getFertButtonv2.png`],
  },
  canGetFertButton: {
    images: [`/icon/bottomMenu/canGetFertButtonv2.png`],
  },
  canGetWaterButton: {
    images: [`/icon/bottomMenu/canGetWaterButtonv2.png`],
  },
  attendanceWater: {
    images: [`/icon/bottomMenu/attendanceWaterv2.png`],
  },
  canGetAttendanceWaterIcon: {
    images: [`/icon/bottomMenu/canGetAttendanceWaterIconv2.png`],
  },
  feed: {
    images: [`/icon/bottomMenu/feedv3.png`],
  },
  canFeed: {
    images: [`/icon/bottomMenu/canFeedv2.png`],
  },
  luckydraw: {
    images: [`/icon/bottomMenu/luckydrawv2.png`],
  },
  minigame: {
    images: [`/icon/bottomMenu/minigamev3.png`],
  },
  newYearIcon: {
    images: [`/icon/bottomMenu/newYearIcon.png`],
  },
  alphabetEvent: {
    images: [`/alphabetEvent/entryIcon.png`],
  },
  jangbogi: {
    images: [`/icon/bottomMenu/jangbogi.png`],
  },
  familyDeal: {
    images: [`/icon/bottomMenu/familyDeal.png`],
  },
  badaham: {
    images: [`/icon/bottomMenu/badahamMission.png`],
  },
  fashionWeek: {
    images: [`/fashionWeek/entryIcon.png`],
  },
  blackfriday: {
    images: [`/dealEvent/blackFriday/entryIcon.png`],
  },
  tripleSeven: {
    images: [`/dealEvent/tripleSeven/entryIcon.png`],
  },
  chuseokDeal24: {
    images: [`/dealEvent/chuseokDeal24/entryIcon.png`],
  },
  sinsunMarket: {
    images: [`/dealEvent/sinsunMarket/entryIcon.png`],
  },
}

const GetResourceButton = ({
  action = 'getFertButton',
  onClick,
  disabled,
  isCleared,
  showCondition,
  tutorialZindex = null,
  onYutnori,
  onWinnerDeal,
  onQuiz,
}) => {
  if (!showCondition) return

  return (
    <>
      <button
        disabled={disabled}
        onClick={onClick}
        style={{
          position: 'relative',
          width: '16vw',
          zIndex: tutorialZindex ? tutorialZindex : 100,
        }}
      >
        <img src={sprite[action].images} alt='' />
        {isCleared && (
          <div
            style={{
              position: 'absolute',
              width: '8vw',
              top: '-1vw',
              right: 0,
              zIndex: 1,
            }}
          >
            <img src='/icon/!.png' alt='' />
          </div>
        )}
        {(onWinnerDeal || onQuiz) && (
          <div
            className='blinking-slow'
            style={{
              position: 'absolute',
              background: '#EA3323',
              borderRadius: '5vw',
              paddingTop: '1.2vw',
              paddingBottom: '1.2vw',
              paddingRight: '1.2vw',
              paddingLeft: '1.2vw',
              right: '2.3vw',
              top: '1.0vw',
              zIndex: 0,
            }}
          ></div>
        )}
        {onYutnori && (
          <div
            className='blinking-slow'
            style={{
              position: 'absolute',
              background: '#EA3323',
              borderRadius: '5vw',
              paddingTop: '1.2vw',
              paddingBottom: '1.2vw',
              paddingRight: '1.2vw',
              paddingLeft: '1.2vw',
              right: '2.3vw',
              top: '1.0vw',
            }}
          ></div>
        )}
      </button>
    </>
  )
}

export default GetResourceButton
