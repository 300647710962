import ModalFrame from 'comps/atoms/Modals/ModalFrame'
import { observer } from 'mobx-react-lite'
import ModalStore from 'store/ModalStore'

const PearlReceivedModal = observer(() => {
  // 바다햄 : 보상 받았다는 이미지 교체 필요
  return (
    <div>
      <ModalFrame
      // closeButtonStyle={styles.closeButton}
      // modalContentStyle={styles.modalContent}
      >
        <div className='flex flex-col justify-center items-center h-screen w-screen'>
          <img
            src='/ads/badahamMission/receivePearl.png'
            alt='pearlReceived'
            className='w-[80vw]'
          />
          <div className='text-[6vw] font-bold -mt-[2vh]'>진주를 받았어요!</div>
          <button
            className='modal-button mt-[3vh]'
            style={{
              backgroundColor: '#3096A8',
            }}
            onClick={() => ModalStore.setIsModalOpen('basic')}
          >
            <span className='modal-button-text'>확인</span>
          </button>
        </div>
      </ModalFrame>
    </div>
  )
})

export default PearlReceivedModal
