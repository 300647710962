import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../../store/ModalStore'
import { getCPMRecommendedItemsWithDefaults } from '../../../utils/utils'
import sendToCPMPurchaseScreen from '../../../utils/sendToCPMPurchaseScreen'

const RecommendedProductAdModal = observer(() => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)
  const [cpmItems, setCpmItems] = useState([])
  const [itemIsLoading, setItemIsLoading] = useState(false)
  const [buttonText, setButtonText] = useState('상품 추천 중')
  const [dots, setDots] = useState('..')

  useEffect(() => {
    fetchCPMItems()
  }, [])

  const BROWSING_TIME_SEC = 30
  const LOADING_ANIMATION_DOT_MAX_COUNT = 3 // ... 개수

  const RECOMMENDED_PRODUCT_AD_PROPS = {
    title: '추천 상품 구경하기',
    image: 'https://assets.ilevit.com/2cfa0fa4-f146-429f-a44c-5a5375f48bf5.png',
    ratio: 99 / 360,
    enteringComponent: 'Alfarm_recommendedProductAd',
    questReward: 30,
    questRewardType: 'water',
    questRewardText: '초간 구경하면 물을 최대 50g 받아요!',
    questTimer: BROWSING_TIME_SEC / 60,
    isTimerOn: true,
  }

  //버튼 에니메이션 -  dots 변경시 로딩 중일 때만 buttonText 업데이트
  useEffect(() => {
    const handleDotsInterval = setInterval(() => {
      setDots((prev) =>
        prev.length >= LOADING_ANIMATION_DOT_MAX_COUNT ? '' : prev + '.',
      )
    }, 200)

    return () => clearInterval(handleDotsInterval)
  }, [])

  useEffect(() => {
    if (itemIsLoading) {
      setButtonText(`상품 추천 중${dots}`)
    }
  }, [dots, itemIsLoading])

  const fetchCPMItems = async () => {
    setItemIsLoading(true)
    const cpmItems = await getCPMRecommendedItemsWithDefaults()
    setCpmItems(cpmItems)
    if (cpmItems.length > 0) {
      setItemIsLoading(false)
      setButtonText(`상품 구경하기`)
      return
    }
    setButtonText('오늘 상품이 마감됐어요')
  }

  const BrowsingButton = () => {
    const buttonClasses = `w-[60vw] h-[14vw] rounded-[99px] text-black font-maplestory text-[4.5vw] flex justify-center items-center absolute bottom-[8vw] left-[10vw]
    ${itemIsLoading ? 'bg-[#B2B2B2]' : 'bg-[#F7C82A]'}
    ${isButtonPressed ? 'shadow-[inset_3px_3px_4px_rgba(79,19,0,0.15)]' : ''}`

    return (
      <div
        className={buttonClasses}
        onPointerDown={() => {
          if (itemIsLoading) return
          setIsButtonPressed(true)
        }}
        onPointerCancel={() => {
          if (itemIsLoading) return
          setIsButtonPressed(false)
        }}
        onPointerUp={() => {
          if (itemIsLoading) return
          setIsButtonPressed(false)
          sendToCPMPurchaseScreen(cpmItems, RECOMMENDED_PRODUCT_AD_PROPS)
        }}
      >
        {buttonText}
      </div>
    )
  }

  const CloseButton = () => {
    return (
      <button
        className='w-[10%] absolute top-[18%] right-[15%] z-[1113]'
        onClick={() => {
          ModalStore.setIsModalOpen('basic')
        }}
      >
        <img
          className='absolute z-[101]'
          src='/icon/modalCloseButton.png'
          alt='close modal'
        />
      </button>
    )
  }

  const ModalImage = () => {
    return (
      <img
        className='w-[80vw]'
        src='https://assets.ilevit.com/5f918ec7-ce90-4c2a-8618-8138e35330c5.png'
        alt='abc'
      />
    )
  }

  return (
    <>
      <div className='flex-1 fixed left-0 top-0 z-[5004] w-full h-screen bg-black/80'>
        {/* 닫기 버튼 */}
        <CloseButton />
        <div className='w-[80vw] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex absolute justify-around items-center flex-col'>
          {/* 모달 이미지 */}
          <ModalImage />
          {/* 구경하기 버튼 */}
          <BrowsingButton />
        </div>
      </div>
    </>
  )
})

export default RecommendedProductAdModal
