import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../store/ModalStore'
import moment from 'moment'

const IMAGE_SRC = {
  water: '/icon/water.png',
  fertilizer: '/icon/fertilizer.png',
  fertilizerSet: '/icon/fertilizerSet.png',
  fertilizerVoucher: '/icon/tileTwoIcon.png',
  puppy: '/icon/tileThreeIcon.png',
  coupon: '/icon/tileSevenIcon.png',
  specialPurchase: '/icon/specialPurchase.png',
}
const GAME_MSG = {
  dailyAttendanceLogin: '출석보상 획득',
  giveWaterBasketFive: '물주기 5회',
  giveWaterBasket: '물주기 1회',
  getWaterdailyAttendance: '매일 출석하기',
  weeklyPurchaseReward: '상품 구매',
  completeTimeDealScreen: '타임딜 구경',
  timerWater: '우물 획득',
  dailyGiveWaterGet: '어제 준 물',
  bonusRewardBox: '친구 농장',
  InvitefriendQuest: '친구초대 완료',
  canceledPurchaseReward: '일반상품 취소',
  dailyAttendanceLoginFinalDate: '전체 출석보상',
  luckydraw: '럭키드로우',
  luckydraw101: '럭키드로우 비료',
  luckydraw102: '럭키드로우 비료바우처',
  luckydraw103: '럭키드로우 바둑이',
  luckydraw104: '럭키드로우 물',
  luckydraw105: '럭키드로우 물',
  luckydraw106: '럭키드로우 고급비료',
  luckydraw107: '럭키드로우 500원 쿠폰',
  luckydraw108: '럭키드로우 물',
  giveWaterBasketToGganbu: '친구 농장',
  giveWaterBasketFiveToGganbu: '친구 농장',
  setLevelUpRewardTwo: '레벨업2',
  setLevelUpRewardThree: '레벨업3',
  setLevelUpRewardFour: '레벨업4',
  setLevelUpRewardFive: '레벨업5',
  setLevelUpRewardSix: '레벨업6',
  luckydrawStart: '럭키드로우 뽑기',
  waterFromGganbu: '친구 농장',
  puppyGift: '바둑이 선물',
  clearedTutorial: '바둑이 선물',
  newUserEvent: '새친구 환영',
  inviteGganbuQuest: '맞팜 퀘스트',
  cx: '고객센터 지급',
  comebackEvent: '복귀 이벤트',
  checkReviewelse: '생생후기 보기',
  waterFromAlwayzFarm: '올팜 농장',
  newbiePurchaseWaterReward: '첫구매 이벤트',
  canceledPurchaseNewbiePurchaseWaterReward: '첫구매 이벤트 취소',
  alwayzFarm: '맞팜 체험',
  purchasedForGganbu: '맞팜친구 품앗이',
  canceledPurchaseForGganbu: '맞팜친구 품앗이 취소',
  secretScratchReward: '비밀복권 당첨',
  canceledPurchaseSecretWaterReward: '비밀복권 취소',
  leftGganbuInvite: '다시 돌아온 친구',
  cherryPickPurchase: '특별 구매',
  canceledPurchaseCherryPickPurchaseWaterReward: '특별 구매 취소',
  waterToFertilizer: '물 비료 교환',
  dailyLottery: '타임추첨 당첨',
  waterOneOneBattle: '물 맞짱',
  checkReview: '후기보기',
  dailyWaterPurchaseReward: '일반상품 구매',
  canceledDailyWaterPurchaseReward: '일반상품 구매 취소',
  waterBombReward: '물풍선',
  lotteryDrawReward: '캡슐 뽑기',
  minigameCompleteReward: '아케이드 완료 보상',
  QRBonus: 'QR 선물',
  lotteryDrawRewardSpecialPurchase: '특별 구매',
  offerwallWater: '미션하고 물 받기',
}

const MinigameHistoryModal = observer(({ token }) => {
  const [miniGameHistory, setMiniGameHistory] = useState([])
  const [emptyModal, setEmptyModal] = useState(false)

  const getMinigameHistory = async () => {
    const result = await backendApis.getMinigameHistory(token)

    if (result?.data?.length === 0) {
      setEmptyModal(true)
    }
    let description
    const customData = result?.data?.map((item) => {
      description = GAME_MSG[item.type] || '기타'
      return { ...item, description }
    })

    setMiniGameHistory(customData)
  }

  useEffect(() => {
    getMinigameHistory()
  }, [token])

  return (
    <>
      <div
        style={{
          flex: 1,
          position: 'fixed',
          left: 0,
          top: 0,
          zIndex: 1000,
          width: '100%',
          height: '100vh',
          background: 'rgba(0,0,0,0.8)',
        }}
      />
      <div
        style={{
          width: '80%',
          height: '110vw',
          position: 'absolute',
          zIndex: 1001,
          top: '55vw',
          left: '10%',
          background: 'white',
          borderRadius: 20,
        }}
      >
        <div
          style={{
            fontFamily: 'maplestory',
            color: '#402C24',
            fontSize: 22,
            textAlign: 'center',
            marginTop: '5vw',
            marginBottom: '1vw',
          }}
        >
          아케이드 기록
        </div>
        <div
          style={{
            backgroundColor: '#f2f2f2',
            width: '100%',
            height: '0.5vw',
            marginTop: 12,
          }}
        />
        <div
          style={{
            position: 'relative',
            overflowY: 'scroll',
            height: '93vw',
          }}
        >
          <div>
            {miniGameHistory?.length > 0 &&
              miniGameHistory?.map((item) => {
                let rewardAmount = Math.max(
                  item?.water,
                  item?.fertilizer,
                  item?.fertilizerSet,
                )
                let rewardType = 'water'
                if (item?.fertilizer > 0) {
                  rewardType = 'fertilizer'
                } else if (item?.fertilizerSet > 0) {
                  rewardType = 'fertilizerSet'
                }
                let src = IMAGE_SRC[rewardType]
                if (item?.type === 'luckydraw102') {
                  src = IMAGE_SRC['fertilizerVoucher']
                } else if (item?.type === 'luckydraw103') {
                  src = IMAGE_SRC['puppy']
                } else if (item?.type === 'luckydraw107') {
                  src = IMAGE_SRC['coupon']
                } else if (item?.type === 'lotteryDrawRewardSpecialPurchase') {
                  src = IMAGE_SRC['specialPurchase']
                }
                let suffix = item?.action === 'plus' ? '+' : '-'
                let unit = rewardType === 'water' ? 'g' : '개'

                if (rewardType === 'water') {
                  return (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          flex: 1,
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginTop: 8,
                        }}
                      >
                        <div
                          style={{
                            marginLeft: 18,
                          }}
                        >
                          <div
                            style={{
                              color: '#5F2D0C',
                              zIndex: 1999,
                              fontFamily: 'maplestory',
                              fontSize: 16,
                              marginTop: 8,
                              marginRight: 16,
                            }}
                          >
                            {item?.description}
                          </div>
                          <div
                            style={{
                              color: '#855C36',
                              fontFamily: 'maplestory',
                              fontSize: 12,
                              marginTop: 10,
                            }}
                          >
                            {moment(item?.loggedAt).format(`MM월 DD일 HH:mm`)}
                          </div>
                        </div>
                        <div
                          style={{
                            color: '#EE7B63',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            marginRight: 18,
                            display: 'flex',
                            flex: 1,
                            fontSize: 20,
                            fontFamily: 'maplestory',
                            marginTop: 8,
                          }}
                        >
                          {rewardAmount > 0
                            ? `${suffix} ${rewardAmount}${unit}`
                            : ''}
                          <div
                            style={{
                              position: 'relative',
                            }}
                          >
                            {rewardAmount > 0 ? (
                              <img
                                style={{
                                  width: '8vw',
                                }}
                                alt=''
                                src={src}
                              />
                            ) : (
                              <img
                                style={{
                                  width: '20vw',
                                }}
                                alt=''
                                src={src}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          height: 1,
                          backgroundColor: '#EDEDED',
                          fontSize: 18,
                          marginTop: 16,
                        }}
                      />
                    </>
                  )
                } else {
                  return (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          flex: 1,
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginTop: 8,
                        }}
                      >
                        <div
                          style={{
                            marginLeft: 18,
                          }}
                        >
                          <div
                            style={{
                              color: '#5F2D0C',
                              zIndex: 1999,
                              fontFamily: 'maplestory',
                              fontSize: 16,
                              marginTop: 8,
                              marginRight: 16,
                            }}
                          >
                            {item?.description}
                          </div>
                          <div
                            style={{
                              color: '#855C36',
                              fontFamily: 'maplestory',
                              fontSize: 12,
                              marginTop: 10,
                            }}
                          >
                            {moment(item?.loggedAt).format(`MM월 DD일 HH:mm`)}
                          </div>
                        </div>
                        <div
                          style={{
                            color: 'black',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            marginRight: 32,
                            display: 'flex',
                            flex: 1,
                            fontSize: 24,
                            fontFamily: 'maplestory',
                          }}
                        >
                          {`${suffix}`}
                          <div
                            style={{
                              position: 'relative',
                            }}
                          >
                            <img
                              style={{
                                width: '13vw',
                              }}
                              alt=''
                              src={src}
                            />
                            <div
                              style={{
                                backgroundColor: '#A2C96D',
                                right: -10,
                                textAlign: 'center',
                                borderRadius: '15px',
                                padding: '3px 12px',
                                position: 'absolute',
                                fontFamily: 'maplestory',
                                borderRightColor: 'white',
                                bottom: -5,
                                fontSize: 14,
                              }}
                            >
                              x {rewardAmount}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          height: 1,
                          backgroundColor: '#EDEDED',
                          fontSize: 18,
                          marginTop: 16,
                        }}
                      />
                    </>
                  )
                }
              })}
          </div>
        </div>
      </div>

      <button
        style={{
          width: '8%',
          top: '40vw',
          right: '10vw',
          zIndex: 1001,
          position: 'absolute',
        }}
        onClick={() => {
          ModalStore.setIsModalOpen('basic')
        }}
      >
        <img src='/icon/modalCloseButton.png' alt='' />
      </button>

      {emptyModal && (
        <>
          <div
            style={{
              color: 'black',
              fontFamily: 'maplestory',
              fontSize: 18,
              marginTop: '20vw',
              textAlign: 'center',
            }}
          >
            물 기록이 없어요
            <br />
            물을 받거나 사용해보세요!
          </div>
        </>
      )}
    </>
  )
})

export default MinigameHistoryModal
