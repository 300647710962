import ModalButton from '../ModalButton'
import ModalHeader from '../ModalHeader'
import ModalContent from '../ModalContent'
import ModalLayout from '../ModalLayout'
import Dimmed from 'comps/dimmed/Dimmed'

import OnlyOnePerDayImage from '/modal/onlyOnePerDay.png'

import styles from './GifticonModal.module.css'

const OnlyOneGifticonPerDayModal = ({ setModal, isDimmed = true }) => {
  return (
    <>
      {isDimmed && <Dimmed />}
      <div
        style={{
          width: '80vw',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1101,
          position: 'absolute',
        }}
      >
        <ModalLayout>
          <ModalHeader>중복 수확 불가능</ModalHeader>
          <ModalContent>
            <div className='modal-content'>
              <span className={styles.subTextStyle}>
                작물은 하루에 한개만 수확할 수 있어요
                <br /> *24시간 내 중복 수확 불가
              </span>
              <div className={styles.imageContainer}>
                <img
                  src={OnlyOnePerDayImage}
                  alt='go to coupon box'
                  className={styles.image}
                />
              </div>
            </div>
            <ModalButton
              handleClick={() => {
                setModal(false)
              }}
            >
              확인
            </ModalButton>
          </ModalContent>
        </ModalLayout>
      </div>
    </>
  )
}

export default OnlyOneGifticonPerDayModal
