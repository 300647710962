import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import StyleAsset from '../molecules/styleAsset'
import ModalStore from 'store/ModalStore'

const PokeFriendHeartIndicatorModal = observer(
  ({ userIdTurn, pokeCount, pokeFriend, pokeModalInfo, setPokeModalInfo }) => {
    const [isButtonPressed, setIsButtonPressed] = useState(false)

    const CloseButton = () => {
      return (
        <button
          className='w-[10vw] absolute top-[18vw] right-0 z-[1]'
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
            setPokeModalInfo((prevState) => ({
              ...prevState,
              gganbuId: '',
              show: false,
            }))
          }}
        >
          <img src='/icon/modalCloseButton.png' alt='' />
        </button>
      )
    }

    const PokeButton = () => {
      if (userIdTurn && pokeCount < 4) {
        return (
          <button
            className='w-[70%] h-[14vw] left-[15vw] bottom-[-18vw] z-3 text-black font-maplestory text-[5vw] rounded-[40vw]'
            style={{
              background:
                'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
              boxShadow: isButtonPressed
                ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                : '',
            }}
            onPointerDown={() => {
              setIsButtonPressed(true)
            }}
            onPointerCancel={() => {
              setIsButtonPressed(false)
            }}
            onPointerUp={() => {
              setIsButtonPressed(false)
              ModalStore.setIsModalOpen('basic')
              setPokeModalInfo((prevState) => ({
                ...prevState,
                gganbuId: '',
                show: false,
              }))

              pokeFriend()
            }}
          >
            콕! 찌르기
          </button>
        )
      }
      if (!userIdTurn) {
        return (
          <button
            className='w-[70%] h-[14vw] left-[15vw] bottom-[-18vw] z-3 font-maplestory text-[5vw] rounded-[40vw]'
            style={{
              background: '#D3D7DD',
              color: '#707070',
            }}
            onClick={() => {
              ModalStore.setIsModalOpen('basic')
              setPokeModalInfo((prevState) => ({
                ...prevState,
                gganbuId: '',
                show: false,
              }))
              pokeFriend()
            }}
          >
            {pokeCount < 4
              ? '친구가 찌를 차례예요'
              : '오늘 콕! 찌르기를 완료했어요'}
          </button>
        )
      }
    }

    const HeadDescription = () => {
      return (
        <div className='w-full mt-[38vw] text-center text-[4.6vw]'>
          <div className='text-black mb-[2vw]'>
            '콕!찌르기'를 2번씩 주고 받으면
          </div>
          <div className='flex flex-row justify-center mb-[2vw]'>
            <div className='text-black'>친구도 나도 랜덤</div>
            <img className='w-[6vw] h-[6vw]' src={'/icon/water.png'} alt='' />
            <div className='text-black'>물을 받아요</div>
          </div>
        </div>
      )
    }

    const PokeStatus = ({ pokeCount }) => {
      return (
        <div className='flex flex-row justify-center items-center mt-[6vw] mb-[14vw]'>
          {/* 총 하트 개수는 4갠데, pokeCount만큼 filled 되도록 설정. */}
          {Array.from({ length: 4 }, (_, i) => {
            return (
              <div key={i} className='relative mx-[1vw]'>
                <img
                  key={i}
                  className='w-[10vw]'
                  src={
                    i < pokeCount
                      ? '/icon/gganbuFarm/icn_pokeModal_filledHeart.png'
                      : '/icon/gganbuFarm/icn_pokeModal_emptyHeart.png'
                  }
                  alt=''
                />
                {/* 하트 위에 '나'와 '친구' 라벨을 번갈아가면서 붙일건데, 아직 시작을 안 했거나 내가 먼저 시작을 했다면 '나' 라벨이 처음에 오고 그게 아니라면 '친구' 라벨이 처음에 와야함.  */}
                {i === 0 && (
                  <div className='w-[10vw] absolute bottom-[-6vw] text-center text-[4vw] text-black'>
                    {(userIdTurn && (pokeCount === 0 || pokeCount === 2)) ||
                    (!userIdTurn && (pokeCount === 1 || pokeCount === 3))
                      ? '나'
                      : '친구'}
                  </div>
                )}
                {/* 마찬가지로 i가 1~3일때도 위에 라벨링을 해줄건데 번갈아가면서 하게끔 */}
                {i > 0 && (
                  <div className='w-[10vw] absolute bottom-[-6vw] text-center text-[4vw] text-black'>
                    {(userIdTurn && (pokeCount === 0 || pokeCount === 2)) ||
                    (!userIdTurn && (pokeCount === 1 || pokeCount === 3))
                      ? i % 2 === 1
                        ? '친구'
                        : '나'
                      : i % 2 === 1
                      ? '나'
                      : '친구'}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )
    }

    return (
      <>
        <div style={StyleAsset.modalBackground}>
          <div
            style={{
              ...StyleAsset.modalImageCentered,
              backgroundImage: 'url(../icon/pokeFriendModal.png)',
              width: '80vw',
              height: '150vw',
            }}
          >
            <CloseButton />

            <div
              className='flex flex-col items-center'
              style={{
                ...StyleAsset.centered,
              }}
            >
              <HeadDescription />
              <PokeStatus pokeCount={pokeCount} />
              <PokeButton />
            </div>
          </div>
        </div>
      </>
    )
  },
)
export default PokeFriendHeartIndicatorModal
