import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from 'store/ModalStore'

const PhoneBookModal = observer(({ farmData, token, setFarmData }) => {
  const [isButtonPressed, setIsButtonPressed] = useState(false)
  return (
    <div
      style={{
        flex: 1,
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 5004,
        width: '100%',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.8)',
      }}
    >
      <div
        style={{
          width: '90%',
          height: '90vw',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          position: 'absolute',
          backgroundImage: 'url(../phoneBookGganbu/modal.png)',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <div
          style={{
            color: 'black',
            fontFamily: 'maplestory',
            textAlign: 'center',
            marginTop: '25%',
            lineHeight: '170%',
            fontSize: '4vw',
          }}
        >
          맞팜 친구 한명이 늘 때 마다 <br />
          수확기간이 평균 2일씩 빨라져요 <br />
          맞팜할 수 있는 친구를 찾고 보상도 받아보세요
        </div>
        <div
          style={{
            color: 'black',
            fontFamily: 'maplestory',
            textAlign: 'center',
            marginTop: '6vw',
            lineHeight: '170%',
            fontSize: '3.5vw',
          }}
        >
          올웨이즈에서 올팜 친구를 찾기 위해서는<br></br> 연락처 접근과 수집
          동의가 필요해요
        </div>
        <button
          style={{
            width: '70%',
            left: '15%',
            fontFamily: 'maplestory',
            color: 'black',
            fontSize: '4.5vw',
            borderRadius: '30px',
            padding: 16,
            zIndex: 2000,
            position: 'absolute',
            bottom: '10vw',
            background: isButtonPressed
              ? 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)'
              : 'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
            boxShadow: isButtonPressed
              ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
              : '',
          }}
          onClick={() => {
            window.location.href = `#alfarmAcceptContacts`
          }}
          onPointerDown={() => {
            setIsButtonPressed(true)
          }}
          onPointerCancel={() => {
            setIsButtonPressed(false)
          }}
          onPointerUp={() => {
            setIsButtonPressed(false)
          }}
        >
          동의하고 맞팜 친구 찾기
        </button>
        <button
          style={{
            width: '10%',
            top: '-10%',
            right: '0%',
            transform: 'translate(0%, -50%)',
            position: 'absolute',
          }}
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img
            style={{
              zIndex: 101,
            }}
            src='/icon/modalCloseButton.png'
            alt=''
          />
        </button>
      </div>
    </div>
  )
})
export default PhoneBookModal
