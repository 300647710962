import { useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react-lite'

import backendApis from '../../utils/backendApis'

import AuthStore from '../../store/AuthStore'
import UserStore from 'store/UserStore'
import ModalStore from 'store/ModalStore'

import {
  farmFinish,
  harvestComplete,
  ONLY_ONE_PER_DAY_ERROR,
} from '../../domain/harvest'

import Dimmed from 'comps/dimmed/Dimmed'
import ModalButton from 'comps/modal/ModalButton'

import useCheckCodePushVersion from 'utils/customHooks/useConvertCodePushVersion'
import ModalLayout from 'comps/modal/ModalLayout'
import ModalContent from 'comps/modal/ModalContent'
import ModalHeader from 'comps/modal/ModalHeader'

import goToUpdate from '/icon/harverst/goToUpdate.svg'
import gifticonInPhone from '/icon/harverst/gifticonInPhone.svg'
import goToCouponBox from '/icon/harverst/goToCouponBox.svg'

import './styles/harvestModal.css'
import ABStore from 'store/ABStore'
import OnlyOneGifticonPerDayModal from 'comps/modal/composite/OnlyOneGifticonPerDayModal'

const REDIRECT_COUPON_BOX_URL = '#redirect-couponBox'

const gifticonItemList = [
  'coffee_starbucks',
  'coffee_mega',
  'coffee_compose',
  'milk',
]

const isGifticonItem = (itemType) => {
  // itemType이 문자열인지 확인한 후 includes() 호출
  return typeof itemType === 'string' && gifticonItemList?.includes(itemType)
}

const HarvestModal = observer(
  ({
    farmData,
    token,
    itemType,
    setHarvestModalVisible,
    setFinishedModalVisible,
    setInviteResultStatus,
    setAlertModal,
    setEnteringRoute,
    userCodePushVersion,
  }) => {
    const codePushVersionAvailable = useCheckCodePushVersion()

    const harvestData = async (link) => {
      let res = await backendApis.finishedFarm(token, 'PUT', {})
      if (res?.status === 200) {
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({
            type: 'harvest',
            data: {
              itemPrice: farmData?.itemPrice,
            },
          }),
        )

        window.location.href = `${link}&key=${AuthStore.key}`

        setHarvestModalVisible(false)
        setFinishedModalVisible(true)
      } else {
        setInviteResultStatus('errorAlert')
      }
    }

    const modalClose = async () => {
      // 배 작물 수확 막기

      if (farmData?.itemType === 'pear') {
        ModalStore?.setIsModalOpen('itemEndModal')
        return
      }
      // 수확 전 최신 버전 체크
      if (farmData?.itemPrice) {
        if (
          !codePushVersionAvailable(
            UserStore?.codePushVersionFromAlwayzApp,
            '4.6.36',
          )
        ) {
          setEnteringRoute('newVersionAppForHarvest')
          setAlertModal(true)
          return
        }
      }

      if (!codePushVersionAvailable(userCodePushVersion, '4.0.0')) {
        setEnteringRoute('newVersionAppForHarvest')
        setAlertModal(true)
        return
      }
      // order Data 까서 최근 order 기록으로 분기 치기 위함
      const result = await backendApis.getHarvestOrderData()

      if (result?.status !== 200) {
        // 잠시 후 다시 시도해주세요
        setEnteringRoute('serverError')
        setAlertModal(true)
        return
      }

      if (result?.data?.data >= 1) {
        // 하루 수확량을 초과했어요. 내일 다시 시도 ㄱㄱ
        setEnteringRoute('alreadyHarvested')
        setAlertModal(true)
        return
      }
      if (result?.status === 200) {
        if (itemType === 'coffee_starbucks') {
          harvestData('#openAlfarmCoffeeStarbucks')
          return
        }
        if (itemType === 'coffee_mega') {
          harvestData('#openAlfarmCoffeeMega')
          return
        }
        if (itemType === 'coffee_compose') {
          harvestData('#openAlfarmCoffeeCompose')
          return
        }

        const refinedItemType =
          itemType.charAt(0).toUpperCase() + itemType.slice(1)
        harvestData(`#openAlfarm${refinedItemType}`)
      }
    }

    const HarvestModals = () => {
      // if (itemType === 'apple') {
      //   return (
      //     <>
      //       <div
      //         style={{
      //           flex: 1,
      //           position: 'fixed',
      //           left: 0,
      //           top: 0,
      //           zIndex: 1100,
      //           width: '100%',
      //           height: '100vh',
      //           background: 'rgba(0,0,0,0.8)',
      //         }}
      //       />
      //       <div
      //         style={{
      //           width: '80vw',
      //           top: '50%',
      //           left: '50%',
      //           transform: 'translate(-50%, -50%)',
      //           zIndex: 1101,
      //           position: 'absolute',
      //         }}
      //       >
      //         <img src='/icon/harvestModalApple.png' alt='' />
      //         <button
      //           style={{
      //             flex: 1,
      //             position: 'absolute',
      //             left: '10%',
      //             textAlign: 'center',
      //             zIndex: 12,
      //             borderRadius: '100vw',
      //             width: '35%',
      //             height: 50,
      //             bottom: '7vw',
      //             border: '#FFE99A 1vw solid',
      //           }}
      //           onClick={() => {
      //             modalClose({ appleType: 'red' })
      //           }}
      //         >
      //           <div
      //             style={{
      //               color: '#402C24',
      //               fontFamily: 'maplestory',
      //               fontSize: '4vw',
      //             }}
      //           >
      //             부사 사과
      //           </div>
      //         </button>
      //         <button
      //           style={{
      //             flex: 1,
      //             position: 'absolute',
      //             right: '10%',
      //             textAlign: 'center',
      //             zIndex: 12,
      //             borderRadius: '100vw',
      //             width: '35%',
      //             height: 50,
      //             bottom: '7vw',
      //             background:
      //               'linear-gradient(180deg, #FFE99A 0%, #F7D046 31.77%, #F6CB45 64.58%, #F09236 100%)',
      //           }}
      //           onClick={() .=> {
      //             modalClose({ appleType: 'aori' })
      //           }}
      //         >
      //           <div
      //             style={{
      //               color: '#402C24',
      //               fontFamily: 'maplestory',
      //               fontSize: '4vw',
      //             }}
      //           >
      //             아오리 사과
      //           </div>
      //         </button>
      //       </div>
      //     </>
      //   )
      // } else {
      return (
        <>
          <Dimmed />
          <div
            style={{
              width: '80vw',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1101,
              position: 'absolute',
            }}
          >
            {isGifticonItem(itemType) ? (
              !codePushVersionAvailable(userCodePushVersion, '6.8.0') ? (
                <HarvestConfirmModal />
              ) : (
                <GoToCouponBoxModal
                  setHarvestModalVisible={setHarvestModalVisible}
                  setFinishedModalVisible={setFinishedModalVisible}
                  setEnteringRoute={setEnteringRoute}
                  token={token}
                  itemType={itemType}
                />
              )
            ) : (
              <>
                <img src={`/icon/harvestModal.png`} alt='모달 이미지' />
                <button
                  style={{
                    flex: 1,
                    position: 'absolute',
                    left: '10%',
                    fontSize: 18,
                    textAlign: 'center',
                    zIndex: 12,
                    borderRadius: 30,
                    width: '80%',
                    height: 50,
                    bottom: '7vw',
                    background: '#FF943C',
                  }}
                  onClick={() => {
                    modalClose()
                  }}
                >
                  <div
                    style={{
                      color: '#FFF',
                      fontFamily: 'maplestory OTF Bold',
                      fontSize: 18,
                    }}
                  >
                    배송지 입력하기
                  </div>
                </button>
              </>
            )}
          </div>
        </>
      )
    }
    return (
      <>
        <HarvestModals />
      </>
    )
  },
)
export default HarvestModal

// Composite-Component
const HarvestConfirmModal = () => {
  const modalClose = () => {
    window.location.href = '#alfarmNewyearGift'
  }

  return (
    <ModalLayout>
      <ModalHeader>키워주셔서 감사해요!</ModalHeader>
      <ModalContent>
        <div className='modal-content'>
          <span className='modal-content-text'>
            수확하신 농작물을 받으려면 <br />앱 업데이트가 필요합니다. <br />
            업데이트 후 다시 접속해주세요!
          </span>
          <div className='modal-content-image'>
            <img src={goToUpdate} alt='Gifticon In Phone' />
          </div>
        </div>
        <ModalButton
          handleClick={() => {
            modalClose()
          }}
        >
          업데이트 바로가기
        </ModalButton>
      </ModalContent>
    </ModalLayout>
  )
}

const GoToCouponBoxModal = ({
  setHarvestModalVisible,
  setFinishedModalVisible,
  setEnteringRoute,
  token,
  itemType,
}) => {
  const [alertModal, setAlertModal] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isOnlyOnePerDayError, setIsOnlyOnePerDayError] = useState(false)
  const [isLoadingState, setIsLoadingState] = useState(false)
  const isLoading = useRef(false)

  const redirectCouponBox = async () => {
    const requestId = UserStore.farmData._id
    try {
      if (isLoading.current) return

      isLoading.current = true
      setIsLoadingState(isLoading.current)
      await farmFinish(token)
      await harvestComplete({ token, itemType, requestId: requestId })

      setHarvestModalVisible(false)
      setFinishedModalVisible(true)

      window.location.href = `${REDIRECT_COUPON_BOX_URL}&key=${AuthStore.key}`
    } catch (error) {
      console.log(error.message)
      setEnteringRoute('errorAlert')
      setAlertModal(true)

      if (error.message === ONLY_ONE_PER_DAY_ERROR) {
        setIsOnlyOnePerDayError(true)
      } else {
        setIsError(true)
      }
    }
  }

  const webOut = () => {
    window.location.href = '#closeGame'
  }

  if (isError) {
    return (
      <ModalLayout>
        <ModalHeader>에러가 발생했어요!</ModalHeader>
        <ModalContent>
          <div className='modal-content'>
            <span className='modal-content-text'>
              수확하신 농작물이 이미
              <br />내 정보의 쿠폰함에 있어요!
            </span>
            <div className='modal-content-image size-primary'>
              <img src={goToCouponBox} alt='go to coupon box' />
            </div>
          </div>
          <ModalButton handleClick={webOut}>확인</ModalButton>
        </ModalContent>
      </ModalLayout>
    )
  }

  if (isOnlyOnePerDayError) {
    return (
      <OnlyOneGifticonPerDayModal
        setModal={setIsOnlyOnePerDayError}
        isDimmed={false}
      />
    )
  }

  return (
    <ModalLayout>
      <ModalHeader>키워주셔서 감사해요!</ModalHeader>
      <ModalContent>
        <div className='modal-content'>
          <span className='modal-content-text'>
            수확하신 농작물은
            <br />내 정보의 쿠폰함에서 확인 가능해요!
          </span>
          <div className='modal-content-image size-primary'>
            <img src={goToCouponBox} alt='go to coupon box' />
          </div>
        </div>
        <ModalButton
          handleClick={redirectCouponBox}
          disabled={isLoading.current}
        >
          {isLoadingState ? '처리 중...' : '쿠폰함가기'}
        </ModalButton>
      </ModalContent>
    </ModalLayout>
  )
}
