import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import BottomSheetHeader from 'comps/molecules/BottomSheetHeader'
import client from 'utils/alranchClient'
import { useEffect, useState } from 'react'
import UserStore from 'store/UserStore'

const Divider = () => (
  <div
    style={{
      width: '93%',
      height: 1,
      backgroundColor: '#EDEDED',
      fontSize: 18,
    }}
  />
)

// userData, cowData, friendsData, missionsData 를 props로 받아서 사용

const AlranchMissions = observer(
  ({
    farmData,
    setFarmData,
    cowData,
    missionsData,
    setMissionsData,
    refetchFunc,
  }) => {
    const [type, setType] = useState('') // reward type
    const [amount, setAmount] = useState(0)
    const [getReward, setGetReward] = useState(false)

    useEffect(() => {
      window.addEventListener('focus', refetchFunc)
      return () => {
        window.removeEventListener('focus', refetchFunc)
      }
    }, [])

    return (
      <>
        <div className='max-h-[70vh] overflow-y-scroll'>
          <BottomSheetHeader
            historyModalType={'minigameHistory'}
            title='올목장 퀘스트'
          />
          {/* 배너 */}
          <div className='relative flex justify-center items-center px-[4vw]'>
            <img src='/alranch/banner.png' alt='' className='w-full' />
            <div
              className='absolute bottom-[2vw] right-[6vw] px-[4vw] py-[1.5vw] bg-[#FF8C2E] rounded-full font-bold'
              onClick={() => {
                window.location.href = `#generalNavigator.${JSON.stringify({
                  screen: 'AlranchScreen',
                  params: {},
                })}`
              }}
            >{`목장 놀러가기 >`}</div>
          </div>
          {/* 미션아이템섹션 */}
          <div className='flex flex-col items-center mb-[4vw]'>
            {/* <StartAlranchMission
              cowData={cowData}
              missionsData={missionsData}
              setMissionsData={setMissionsData}
              setGetReward={setGetReward}
              setAmount={setAmount}
              farmData={farmData}
              setFarmData={setFarmData}
            />
            <Divider /> */}
            <GiveFeedMission
              cowData={cowData}
              missionsData={missionsData}
              setMissionsData={setMissionsData}
              setGetReward={setGetReward}
              setType={setType}
              setAmount={setAmount}
              farmData={farmData}
              setFarmData={setFarmData}
            />
            <Divider />
            <GiveVitaminMission
              cowData={cowData}
              missionsData={missionsData}
              setMissionsData={setMissionsData}
              setGetReward={setGetReward}
              setType={setType}
              setAmount={setAmount}
              farmData={farmData}
              setFarmData={setFarmData}
            />
            <Divider />
            <Give10000FeedMission
              cowData={cowData}
              missionsData={missionsData}
              setMissionsData={setMissionsData}
              setGetReward={setGetReward}
              setType={setType}
              setAmount={setAmount}
              farmData={farmData}
              setFarmData={setFarmData}
            />
          </div>
        </div>
        {getReward && (
          <>
            <div
              style={{
                flex: 1,
                position: 'fixed',
                left: '50%',
                top: '50%',
                zIndex: 22222,
              }}
              onClick={() => {
                setGetReward(false)
              }}
            >
              <div
                style={{
                  backgroundColor: 'black',
                  position: 'absolute',
                  overlay: {
                    background: 'rgba(0,0,0,0)',
                    zIndex: 100,
                  },
                  width: '35vw',
                  height: '35vw',
                  borderRadius: '15px',
                  opacity: '0.8',
                  transform: 'translate(-50%, -50%)',
                }}
              />
              <img
                src={
                  type === 'fertilizerSet'
                    ? '/icon/fertilizerSet.png'
                    : '/icon/getWaterButtonNew.png'
                }
                alt=''
                style={{
                  width: '20vw',
                  top: 0,
                  height: 'auto',
                  transform: 'translate(-50%, -70%)',
                }}
                className='get-water-popup-move'
              />

              <div
                style={{
                  position: 'relative',
                  transform: 'translate(-50%, -220%)',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontFamily: 'maplestory',
                }}
              >
                {type === 'fertilizerSet'
                  ? '고급비료 1개 획득!'
                  : `${amount}g 획득!`}
              </div>
            </div>
          </>
        )}
      </>
    )
  },
)

function GiveFeedMission({
  cowData,
  missionsData,
  setMissionsData,
  setGetReward,
  setType,
  setAmount,
  farmData,
  setFarmData,
}) {
  const [isLoading, setIsLoading] = useState()
  let status = 'progress' // "progress" |  "receivable" | "seeYouTomorrow"
  let currentCount = 0
  if (
    cowData?.updatedAt &&
    new Date(cowData.updatedAt).toDateString() === new Date().toDateString() &&
    cowData?.feedToday >= 20
  ) {
    currentCount = cowData?.feedToday * 100
    if (
      missionsData?.alframGiveFeed?.receivedAt &&
      new Date(missionsData.alframGiveFeed.receivedAt).toDateString() ===
        new Date().toDateString()
    ) {
      status = 'seeYouTomorrow'
    } else {
      status = 'receivable'
    }
  } else {
    status = 'progress'
    if (
      cowData?.updatedAt &&
      new Date(cowData.updatedAt).toDateString() === new Date().toDateString()
    ) {
      currentCount = cowData?.feedToday * 100
    }
  }

  async function receiveWater() {
    if (isLoading) return
    try {
      setIsLoading(true)
      const result = await backendApis.alranchMission('giveFeed')
      if (result?.data?.success) {
        let temp = { ...farmData }
        let tempAmount = result?.data?.amount
        if (UserStore.waterExceeded) {
          temp.water += Math.round(result?.data?.amount * 0.5)
          tempAmount = Math.round(result?.data?.amount * 0.5)
        } else {
          temp.water += result?.data?.amount
        }
        setFarmData(temp)
        setType('water')
        setAmount(tempAmount)
        setGetReward(true)
        setTimeout(() => {
          setGetReward(false)
          setAmount(0)
        }, 2500)
      }
      const result2 = await client.put('/v2/missions/log', {
        missionType: 'alframGiveFeed',
        loggingType: 'receiveReward',
      })
      if (result2.data) {
        setMissionsData(result2.data)
      }
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='w-full p-[16px] flex items-center gap-[3vw]  text-black'>
      <div className='relative w-[14vw] h-[14vw]'>
        <img src='/alranch/giveFeed.png' alt='' />
      </div>
      <div className='flex-1 flex flex-col gap-[0vw]'>
        <div className='text-black text-[18px] font-semibold'>
          젖소 사료 2,000g 주기
        </div>
        <div className='flex items-center text-[#6D7680] text-[14px] gap-[1vw]'>
          <img src='/alranch/water.png' className='w-[4vw]' alt='' />
          50g, ({Math.min(2000, currentCount).toLocaleString()}
          /2,000)
        </div>
      </div>
      {(() => {
        switch (status) {
          case 'progress':
            return (
              <div
                className='w-[20vw] bg-[#8BD300] flex items-center justify-center py-[1.8vw] text-white rounded-full'
                onClick={() => {
                  window.location.href = `#generalNavigator.${JSON.stringify({
                    screen: 'AlranchScreen',
                    params: {},
                  })}`
                }}
              >
                사료주기
              </div>
            )
          case 'receivable':
            return (
              <div
                className='w-[20vw] bg-[#FFC703] flex items-center justify-center py-[1.8vw] text-white rounded-full'
                onClick={() => receiveWater()}
              >
                받기
              </div>
            )
          case 'seeYouTomorrow':
            return (
              <div className='w-[20vw] bg-[#959595] flex items-center justify-center py-[1.8vw] text-white rounded-full'>
                내일가능
              </div>
            )
          default:
            return null
        }
      })()}
    </div>
  )
}

function Give10000FeedMission({
  cowData,
  missionsData,
  setMissionsData,
  setGetReward,
  setType,
  setAmount,
  farmData,
  setFarmData,
}) {
  const [isLoading, setIsLoading] = useState()
  let status = 'progress' // "progress" |  "receivable" | "seeYouTomorrow"
  let currentCount = 0

  if (
    cowData?.updatedAt &&
    new Date(cowData.updatedAt).toDateString() === new Date().toDateString() &&
    cowData?.feedToday >= 100
  ) {
    currentCount = cowData?.feedToday * 100
    if (
      missionsData?.alframGive10000Feed?.receivedAt &&
      new Date(missionsData.alframGive10000Feed.receivedAt).toDateString() ===
        new Date().toDateString()
    ) {
      status = 'seeYouTomorrow'
    } else {
      status = 'receivable'
    }
  } else {
    status = 'progress'
    if (
      cowData?.updatedAt &&
      new Date(cowData.updatedAt).toDateString() === new Date().toDateString()
    ) {
      currentCount = cowData?.feedToday * 100
    }
  }

  async function receiveReward() {
    if (isLoading) return
    try {
      setIsLoading(true)
      const result = await backendApis.alranchMission('give10000Feed')
      if (result?.data?.success) {
        let temp = { ...farmData }
        temp.fertilizerSet += 1
        setFarmData(temp)
        setType('fertilizerSet')
        setAmount(1)
        setGetReward(true)
        setTimeout(() => {
          setGetReward(false)
          setType('fertilizerSet')
          setAmount(0)
        }, 2500)
      }
      const result2 = await client.put('/v2/missions/log', {
        missionType: 'alframGive10000Feed',
        loggingType: 'receiveReward',
      })
      if (result2.data) {
        setMissionsData(result2.data)
      }
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='w-full p-[16px] flex items-center gap-[3vw]  text-black'>
      <div className='relative w-[14vw] h-[14vw]'>
        <img src='/alranch/giveFeed.png' alt='' />
      </div>
      <div className='flex-1 flex flex-col gap-[0vw]'>
        <div className='text-black text-[18px] font-semibold'>
          젖소 사료 10,000g 주기
        </div>
        <div className='flex items-center text-[#6D7680] text-[14px] gap-[1vw]'>
          {/* <img src='/icon/fertilizerSet.png' className='w-[6vw]' alt='' /> */}
          고급비료 1개, ({Math.min(10000, currentCount).toLocaleString()}
          /10,000)
        </div>
      </div>
      {(() => {
        switch (status) {
          case 'progress':
            return (
              <div
                className='w-[20vw] bg-[#8BD300] flex items-center justify-center py-[1.8vw] text-white rounded-full'
                onClick={() => {
                  window.location.href = `#generalNavigator.${JSON.stringify({
                    screen: 'AlranchScreen',
                    params: {},
                  })}`
                }}
              >
                사료주기
              </div>
            )
          case 'receivable':
            return (
              <div
                className='w-[20vw] bg-[#FFC703] flex items-center justify-center py-[1.8vw] text-white rounded-full'
                onClick={() => receiveReward()}
              >
                받기
              </div>
            )
          case 'seeYouTomorrow':
            return (
              <div className='w-[20vw] bg-[#959595] flex items-center justify-center py-[1.8vw] text-white rounded-full'>
                내일가능
              </div>
            )
          default:
            return null
        }
      })()}
    </div>
  )
}

function GiveVitaminMission({
  cowData,
  missionsData,
  setMissionsData,
  setGetReward,
  setType,
  setAmount,
  farmData,
  setFarmData,
}) {
  const [isLoading, setIsLoading] = useState()
  let status = 'progress' // "progress" |  "receivable" | "seeYouTomorrow"
  let currentCount = 0
  if (
    cowData?.updatedAt &&
    new Date(cowData.updatedAt).toDateString() === new Date().toDateString() &&
    cowData?.vitaminToday >= 3
  ) {
    currentCount = cowData?.vitaminToday
    if (
      missionsData?.alframGiveVitamin?.receivedAt &&
      new Date(missionsData.alframGiveVitamin.receivedAt).toDateString() ===
        new Date().toDateString()
    ) {
      status = 'seeYouTomorrow'
    } else {
      status = 'receivable'
    }
  } else {
    status = 'progress'
    if (
      cowData?.updatedAt &&
      new Date(cowData.updatedAt).toDateString() === new Date().toDateString()
    ) {
      currentCount = cowData?.vitaminToday
    }
  }

  async function receiveWater() {
    if (isLoading) return
    try {
      setIsLoading(true)
      const result = await backendApis.alranchMission('giveVitamin')
      if (result?.data?.success) {
        let temp = { ...farmData }
        let tempAmount = result?.data?.amount
        if (UserStore.waterExceeded) {
          temp.water += Math.round(result?.data?.amount * 0.5)
          tempAmount = Math.round(result?.data?.amount * 0.5)
        } else {
          temp.water += result?.data?.amount
        }
        temp.initTimeCheckerResult.drinkWaterDone = true
        if (!farmData?.drinkWaterQuest) {
          temp.drinkWaterQuest = [new Date()]
        }
        if (farmData?.drinkWaterQuest) {
          temp.drinkWaterQuest?.push(new Date())
        }
        setFarmData(temp)
        setType('water')
        setAmount(tempAmount)
        setGetReward(true)
        setTimeout(() => {
          setGetReward(false)
          setAmount(0)
        }, 2500)
      }
      const result2 = await client.put('/v2/missions/log', {
        missionType: 'alframGiveVitamin',
        loggingType: 'receiveReward',
      })
      if (result2.data) {
        setMissionsData(result2.data)
      }
    } catch {
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='w-full p-[16px] flex items-center gap-[3vw]  text-black'>
      <div className='relative w-[14vw] h-[14vw]'>
        <img src='/alranch/giveVitamin.png' alt='' />
      </div>
      <div className='flex-1 flex flex-col gap-[0vw]'>
        <div className='text-black text-[18px] font-semibold'>
          젖소 영양제 3개 주기
        </div>
        <div className='flex items-center text-[#6D7680] text-[14px] gap-[1vw]'>
          <img src='/alranch/water.png' className='w-[4vw]' alt='' />
          50g, ({Math.min(3, currentCount)}/3)
        </div>
      </div>
      {(() => {
        switch (status) {
          case 'progress':
            return (
              <div
                className='w-[20vw] bg-[#8BD300] flex items-center justify-center py-[1.8vw] text-white rounded-full'
                onClick={() => {
                  window.location.href = `#generalNavigator.${JSON.stringify({
                    screen: 'AlranchScreen',
                    params: {},
                  })}`
                }}
              >
                영양제주기
              </div>
            )
          case 'receivable':
            return (
              <div
                className='w-[20vw] bg-[#FFC703] flex items-center justify-center py-[1.8vw] text-white rounded-full'
                onClick={() => receiveWater()}
              >
                받기
              </div>
            )
          case 'seeYouTomorrow':
            return (
              <div className='w-[20vw] bg-[#959595] flex items-center justify-center py-[1.8vw] text-white rounded-full'>
                내일가능
              </div>
            )
          default:
            return null
        }
      })()}
    </div>
  )
}

export default AlranchMissions
