import { useState, useEffect } from 'react'

export const AlwayzTopUpButton = () => {
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      // 스크롤 컨테이너 찾기 - 클래스 선택자 수정
      const scrollContainer = document.querySelector('.h-screen')
      if (scrollContainer) {
        setIsVisible(scrollContainer.scrollTop > 300)
      }
    }

    const scrollContainer = document.querySelector('.h-screen')
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll)
      return () => scrollContainer.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleClick = () => {
    // 부모 스크롤 컨테이너를 찾아서 스크롤
    const scrollContainer = document.querySelector('.h-screen')
    if (scrollContainer) {
      scrollContainer.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  return (
    <button
      type='button'
      className='fixed p-4 bg-white rounded-full shadow-md bottom-6 right-4 opacity-90 z-[9999]'
      onClick={handleClick}
      aria-label='Scroll to top'
    >
      <svg 
        className='w-4 h-4' 
        viewBox='0 0 24 24' 
        fill='none' 
        stroke='#666666'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M18 15l-6-6-6 6' />
      </svg>
    </button>
  )
}
