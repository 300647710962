import './Modal.css'

const ModalButton = ({ children, handleClick, isOpaque, disabled }) => {
  return (
    <button
      className={`modal-button ${isOpaque ? 'modal-button_opaque-background' : ''}`}
      onClick={handleClick}
      disabled={disabled}
    >
      <span className='modal-button-text'>{children}</span>
    </button>
  )
}

export default ModalButton
