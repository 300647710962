import { useState, useEffect, useRef } from 'react'

export const SortButton = ({ sortOption, setSortOption, sortOptions }) => {
  const [showSortTooltip, setShowSortTooltip] = useState(false)
  const sortButtonRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sortButtonRef.current && !sortButtonRef.current.contains(event.target)) {
        setShowSortTooltip(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className="relative" ref={sortButtonRef}>
      <button
        type="button"
        className="flex items-center gap-1 px-3 py-[6px] text-sm font-medium border border-[#eeeef3] border-solid text-[#212121] rounded-lg"
        onClick={() => setShowSortTooltip(!showSortTooltip)}
      >
        {Object.values(sortOptions).find(option => option.value === sortOption)?.label}
        <svg 
          className={`w-4 h-4 transition-transform duration-200 ${showSortTooltip ? 'rotate-180' : ''}`}
          fill="none" 
          stroke="#212121"
          viewBox="0 0 24 24"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      {showSortTooltip && (
        <div className="absolute right-0 top-full mt-1 z-50 w-40 bg-white rounded-lg shadow-lg border border-gray-200">
          {Object.values(sortOptions).map(option => (
            <button
              key={option.value}
              className={`w-full px-4 py-2 text-left text-sm hover:bg-[#f5f5f5] ${
                option.value === sortOption ? 'text-[#57B921]' : 'text-[#212121]'
              }`}
              onClick={() => {
                setSortOption(option.value)
                setShowSortTooltip(false)
              }}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}
