import { observer } from 'mobx-react-lite'
import React from 'react'
import ResourceToastStore from 'store/ResourceToastStore'

const ResourceToast = observer(({ type, count }) => {
  if (!ResourceToastStore?.resourceToast?.open) return null
  if (!ResourceToastStore?.resourceToast?.type) return null
  if (!ResourceToastStore?.resourceToast?.count) return null

  let message
  if (ResourceToastStore?.resourceToast?.type === 'water') {
    message = `${ResourceToastStore?.resourceToast?.count}g 획득!`
  } else if (ResourceToastStore?.resourceToast?.type === 'waterFertilizerSet') {
    message = `${ResourceToastStore?.resourceToast?.count} 획득!`
  } else {
    message = `${ResourceToastStore?.resourceToast?.count}개 획득!`
  }

  const imgSrcMap = {
    water: '/icon/getWaterButtonNew.png',
    fertilizer: '/icon/fertilizer.png',
    fertilizerSet: '/icon/fertilizerSet.png',
    nutriment: '/icon/nutriment.png',
    specialPurchase: '/icon/specialPurchase.png',
    luckyBag: '/newYear2024/luckyBag.png',
    waterFertilizerSet: '/icon/waterFertilizerSet.png',
    fertilizerSetSpecial: '/icon/fertilizerSet.png',
    jangbogiCoin: '/dealEvent/jangbogi/coin.png',
    familyDealCoin: '/dealEvent/familyDealEvent/familyDealCoin.png',
    fashionWeekCoin: '/dealEvent/sinsunMarket/coin.png',
  }

  return (
    <div
      style={{
        flex: 1,
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 5004,
        width: '100%',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0)',
      }}
      onClick={() => {
        ResourceToastStore?.setResourceToast(null)
      }}
    >
      <div
        style={{
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          left: '50%',
          top: '55%',
        }}
      >
        <div
          style={{
            position: 'absolute',
            zIndex: 1,
            textAlign: 'center',
            fontSize: '5.5vw',
            width: '100%',
          }}
        >
          <img
            className='w-[50%] mt-[4vw] mx-auto mb-[1vw]'
            src={imgSrcMap[ResourceToastStore?.resourceToast?.type]}
            alt=''
          />
          <div
            style={{
              color: 'white',
              marginTop: '2vw',
            }}
          >
            {message}
          </div>
        </div>
        <div
          style={{
            color: 'black',
            fontSize: '4.5vw',
            textAlign: 'center',
            background: 'black',
            opacity: '0.8',
            borderRadius: '6vw',
            padding: '2vw 8vw 2vw 8vw',
            width: '37.5vw',
            height: '38vw',
          }}
        />
      </div>
    </div>
  )
})

export default ResourceToast
