import { NavLink } from 'react-router-dom'
import AuthStore from 'store/AuthStore'
import ModalStore from 'store/ModalStore'
import UserStore from 'store/UserStore'

const RewardRecordButton = ({
  modalType = '', // 보상기록 버튼 클릭시 오픈할 모달 타입
}) => {
  return (
    <header
      style={{
        width: '100%',
        marginTop: '13vw',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 999,
        paddingLeft: '4vw',
        paddingRight: '4vw',
      }}
    >
      <NavLink
        to={`/farmPage?token=${AuthStore?.token}&itemType=${
          UserStore?.itemType
        }&randomNumber=${Math.random().toString().slice(0, 8)}`}
      >
        <button
          style={{
            width: '8vw',
            zIndex: 999,
          }}
          action='goBack'
        >
          <img src='/backButton/black.png' alt='back button' />
        </button>
      </NavLink>
      {/* 보상 기록 버튼 MVP 스펙 제외 */}
      {/* <div
        onClick={() => {
          console.log('modalType', modalType)
          ModalStore.setIsModalOpen(modalType)
        }}
      >
        <img
          src='/newYear2024/history.png'
          alt='seasonal event header'
          style={{
            width: '8vw',
            marginRight: '3vw',
            zIndex: 999,
          }}
        />
      </div> */}
    </header>
  )
}

export default RewardRecordButton
