import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import StyleAsset from '../molecules/styleAsset'
import ModalStore from 'store/ModalStore'
import Layout from './layout'
import LoadingIndicator from 'comps/loadingIndicator'
import { useSearchParam } from 'react-use'

const ResurrectNudgeStampModal = observer(({ gganbuData, finishStamp }) => {
  const token = useSearchParam('token')
  const [isLoading, setIsLoading] = useState(false)
  const [isButtonPressed, setIsButtonPressed] = useState(false)
  const [clickedTutorial, setClickedTutorial] = useState(false)
  const stampInfo = gganbuData?.stampInfo

  const CloseButton = () => {
    return (
      <button
        className='w-[10vw] absolute top-[18vw] right-0 z-[1]'
        onClick={() => {
          ModalStore.setIsModalOpen('basic')
        }}
      >
        <img src='/icon/modalCloseButton.png' alt='' />
      </button>
    )
  }

  const HeadDescription = () => {
    if (!stampInfo && !clickedTutorial) {
      return (
        <div className='flex flex-col items-center w-full mt-[20vw] mb-[2vw] text-[4vw]'>
          <div className='text-black'>{gganbuData?.userName}님이</div>
          <div className='text-black'>작물을 소홀히 하고 있어요</div>
          <img
            className='w-[40vw] h-[40vw] my-[4vw]'
            src='/icon/gganbuFarm/icn_gganbuFarmList_resurrect_tutorial.png'
            alt=''
          />
          <div className='text-black'>
            {gganbuData?.userName}님의 작물을 함께 돌봐주시면
          </div>
          <div className='text-black'>보상을 드릴게요!</div>
        </div>
      )
    }
    return (
      <div className='w-full mt-[40vw] flex justify-center text-[4vw]'>
        <img
          className='w-[30vw] h-[30vw] my-[4vw]'
          src='/icon/icn_gganbuFarm_stamp.png'
          alt=''
        />
      </div>
    )
  }

  const CloseNotice = () => {
    if (!stampInfo && !clickedTutorial) {
      return
    }
    return (
      <div className='flex flex-col items-center w-full mt-[0vw] mb-[2vw] text-center text-[5vw]'>
        <div className='text-black'>
          맞팜 도장판 이벤트가 <br />
          11월 19일 00시에 종료돼요. <br /> <br />
          이미 도장판을 생성하신 <br />
          모든 농부님께 최종 보상을 드릴게요!
        </div>
        <div className='text-gray-400 text-[3vw] mt-[2vw]'>
          * 19일부터는 보상을 받을 수 없어요.
        </div>
      </div>
    )
  }

  const CTAButton = () => {
    if (!stampInfo && !clickedTutorial) {
      return (
        <button
          className='w-[45vw] h-[14vw] z-3 text-[6vw] '
          style={{
            backgroundImage:
              'url(/icon/gganbuFarm/icn_gganbuFarmList_greenCTAButton.png)',
            backgroundSize: '100% 100%', // 이미지 크기를 버튼에 맞게 조정
            backgroundPosition: 'center', // 이미지를 중앙에 맞춤
          }}
          onClick={() => {
            setClickedTutorial(true)
          }}
        >
          <div className='top-[3vw] left-[17vw] text-[5vw] font-bold'>
            네, 좋아요!
          </div>
        </button>
      )
    }
    return (
      <div className='flex justify-center w-[80vw]'>
        <button
          className='w-[50vw] h-[16vw] z-3 text-[7vw]'
          style={{
            backgroundImage:
              'url(/icon/gganbuFarm/icn_gganbuFarmList_greenCTAButton.png)',
            backgroundSize: '100% 100%', // 이미지 크기를 버튼에 맞게 조정
            backgroundPosition: 'center', // 이미지를 중앙에 맞춤
          }}
          onClick={async () => {
            setIsLoading(true)
            setIsButtonPressed(false)
            // 해당 stampInfo에 deletedAt이랑 closeRewardGivenAt 추가
            ModalStore.setIsModalOpen('basic')
            finishStamp()

            setIsLoading(false)
          }}
        >
          <div className='text-[4.6vw] top-[2vw] left-[16vw] font-bold'>
            보상 받기
          </div>
        </button>
      </div>
    )
  }

  if (isLoading) {
    return (
      <Layout isLoading={isLoading}>
        <LoadingIndicator isLoading={isLoading} />
      </Layout>
    )
  }

  return (
    <>
      <div style={StyleAsset.modalBackground}>
        <div
          style={
            !stampInfo && !clickedTutorial
              ? {
                  ...StyleAsset.modalImageCentered,
                  backgroundImage: 'url(../icon/resurrectNudgeStampModal.png)',
                  width: '80vw',
                  height: '150vw',
                }
              : {
                  ...StyleAsset.modalImageCentered,
                  backgroundImage: 'url(../icon/resurrectNudgeStampModal.png)',
                  width: '96vw',
                  height: '170vw',
                }
          }
        >
          <CloseButton />

          <div
            className={
              !stampInfo && !clickedTutorial
                ? 'flex flex-col items-center justify-between h-[100vw]'
                : 'flex flex-col items-center justify-between h-[146vw]'
            }
            style={{
              ...StyleAsset.centered,
            }}
          >
            <HeadDescription />
            <CloseNotice />
            <CTAButton />
          </div>
        </div>
      </div>
    </>
  )
})
export default ResurrectNudgeStampModal
