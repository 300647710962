class AppBridgeSender {
  goExternalBrowser(url) {
    if (url) {
      window?.ReactNativeWebView?.postMessage?.(
        JSON.stringify({
          type: 'externalBrowser',
          data: { url },
        }),
      )
    }
  }

}

export const appBridgeSender = new AppBridgeSender()