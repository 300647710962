import translateOfferwallDescription from '../_utils/translateOfferwallDescription'
import { OfferwallContentItemButton } from './OfferwallContentItemButton'

const OfferwallContentItem = ({
  itemInfo,
  empty = false,
  icon = false,
  onClick = () => {},
}) => {
  // skeleton
  if (empty) {
    return (
      <div className='flex flex-col items-center w-full p-6 animate-pulse'>
        {/* image */}
        <div
          className='w-full h-full bg-gray-300 rounded-lg'
          style={{ aspectRatio: 2 }}
        />

        <div className='flex flex-row items-center justify-between w-full px-2 mt-4'>
          <div className='flex flex-row items-center mr-2 whitespace-normal'>
            {/* icon */}
            {icon && (
              <div className='w-12 h-12 mr-3 bg-gray-300 border rounded-xl' />
            )}

            {/* title, description */}
            <div className='flex flex-col'>
              <div className='w-40 h-5 mb-2 bg-gray-300 rounded' />
              <div className='w-20 h-5 bg-gray-300 rounded' />
            </div>
          </div>

          {/* CTA button */}
          <div className='px-2.5 py-2 w-16 h-8 rounded-lg bg-[#e0e0e0]' />
        </div>
      </div>
    )
  }

  return (
    <div className='flex w-full px-6 pb-4'>
      <div
        className='flex flex-col items-center w-full 
        active:brightness-95'
        onClick={onClick}
      >
        <img
          alt='offerwall_item_image'
          src={itemInfo?.creative?.image_url}
          className='object-cover  border border-[#eeeeee] rounded-lg'
          style={{ aspectRatio: 2 }}
        />

        <div className='flex flex-row items-center justify-between w-full px-2 mt-4'>
          <div className='flex flex-row items-center mr-2 whitespace-normal'>
            {icon && (
              <img
                className='w-12 h-12 mr-3 border rounded-2xl'
                alt='icon_image'
                src={itemInfo?.creative?.icon_url}
              />
            )}

            <div className='flex flex-col text-left'>
              <div className='text-[#616161] mb-1'>
                {itemInfo?.creative?.title?.length > 18
                  ? `${itemInfo?.creative?.title.substring(0, 18)}..`
                  : itemInfo?.creative?.title}
              </div>
              <div className='font-bold text-[#212121]'>
                {translateOfferwallDescription(itemInfo?.type)}고
              </div>
            </div>
          </div>

          <OfferwallContentItemButton itemInfo={itemInfo} />
        </div>
      </div>
    </div>
  )
}

export default OfferwallContentItem
