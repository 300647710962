import { useNavigate } from 'react-router-dom'
import { Chevron } from '../../../../public/offerwall/svg/Chevron'

export const OfferwallHeader = (props) => {
  const { onClick, bold } = props
  const navigate = useNavigate()

  const handleClickBack = () => {
    if (onClick) {
      return onClick()
    }

    return navigate(-1)
  }

  return (
    <>
      <div
        className={`fixed top-0 z-20 pt-16 flex items-center justify-center w-full h-24 bg-white ${
          bold ? 'font-bold' : ''
        } text-lg`}
      >
        {props?.back && (
          <button
            type='button'
            className='absolute left-0 p-3 m-1 duration-200 rounded-lg active:bg-grey20 active:scale-95'
            onClick={handleClickBack}
          >
            <Chevron
              className='w-4 h-4'
              pathClassName='stroke-[#616161]'
              direction='left'
            />
          </button>
        )}
        <span className='text-black'>{props?.title}</span>
        {props?.onRightButtonText && (
          <button
            type='button'
            className='absolute text-black right-0 p-3 m-1 duration-200 rounded-lg active:bg-grey20 active:scale-95'
            onClick={props?.onRightClick}
          >
            {props?.onRightButtonText}
          </button>
        )}
        <div />
      </div>
      <div className='h-12' />
    </>
  )
}
