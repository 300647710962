const SquareCenterModal = ({content}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 pointer-events-none">
      <div className="px-[26px] py-[16px] rounded-[20px] bg-black bg-opacity-60 pointer-events-auto">
        {content}
      </div>
    </div>
  )
}

export default SquareCenterModal