import React, { useState, useEffect, useRef } from 'react'
import backendApis from '../utils/backendApis'
import { useSearchParam } from 'react-use'
import PlantBasicInfo from '../comps/molecules/PlantBasicInfo'
import MovingComponentsScreen from 'comps/molecules/MovingComponentsScreen'
import ABStore from 'store/ABStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'

const testPlantList = [
  'coffee_mega',
  'tomato',
  'egg',
  'coffee_compose',
  'sweetpotato',
  'mool',
  'coffee_starbucks',
  'potato',
  'apple',
  'onion',
  // 'pineapple',
  'rice',
  'avocado',
  'banana',
  'carrot',
  'greenOnion',
  'orange',
  'lemon',
  'grape',
  'galic',
  'dishdetergent',
  // 'grapefruit',
  'toothset',
  'toothpaste',
  'milk',
]

const SelectFarm = () => {
  const token = useSearchParam('token')
  const shareType = useSearchParam('shareType')
  const alwayzInvitorId = useSearchParam('alwayzInvitorId')
  const phoneNumber = useSearchParam('phoneNumber')
  const indexNumber = useSearchParam('indexNumber')
  const [selectItem, setSelectItem] = useState(null)
  const [noPhoneNumber, setNoPhoneNumber] = useState(true)
  const [myItemName, setMyItemName] = useState('')
  const [plantList, setPlantList] = useState(testPlantList)
  const [userId, setUserId] = useState()
  const [clearedUserList, setClearedUserList] = useState([])
  const [scrollIndicatorOn, setScrollIndicatorOn] = useState(true)
  const [scrollIndicatorOff, setScrollIndicatorOff] = useState(true)

  const throttleRef = useRef({})

  useEffect(() => {
    if (scrollIndicatorOn) return
    const timer = setTimeout(() => setScrollIndicatorOff(true), 6000)
    // 컴포넌트가 언마운트될 때 타이머 정리
    return () => clearTimeout(timer)
  }, [scrollIndicatorOn])

  const getUserId = async () => {
    if (!userId) {
      const result = await backendApis.getUserId(token)
      if (result.status === 200 && result.data) {
        setUserId(result.data)
      }
    }
  }

  const getUserBasicInfo = async () => {
    const result = await backendApis.getUserBasicInfo(token)
    if (result?.phoneNumber !== '') {
      setNoPhoneNumber(false)
    }
  }

  const fetchClearedUsers = async () => {
    const result = await backendApis.fetchClearedUsers()
    setClearedUserList(result?.data)
  }

  useEffect(() => {
    getUserId()
    getUserBasicInfo()
    fetchClearedUsers()
  }, [])

  // 기본 선택 ab
  useEffect(() => {
    if (userId) {
      ABStore.initialize({ userId: userId })
    }
    if (!ABStore?.selectFarmTestDefault) return

    if (!userId) return
    setSelectItem(0)
  }, [userId])

  useEffect(() => {
    if (!userId) return
    AlfarmEventLogger({
      throttleRef,
      locationType: 'page',
      locationName: 'selectFarm',
      eventName: 'pageview',
      eventType: 'pageview',
      data: { testName: 'selectFarmTest1015' },
      collection: 'UserAlfarmPageviewLog',
    })
  }, [userId])

  useEffect(() => {
    if (userId) {
      ABStore.initialize({ userId: userId })
    }
    if (ABStore?.gifticonTester) {
      setPlantList([...testPlantList])
    }
  }, [userId, ABStore?.gifticonTester])

  const CTA = () => {
    return (
      <div className='mt-[1vw] border-none w-full'>
        {noPhoneNumber ? (
          <button className='w-full flex justify-center items-center'>
            <div
              className={`flex justify-center items-center text-black bg-gradient-to-r from-[#FFF0BB] to-[#FF7A00] rounded-[99vw] w-[80%] p-[4vw] text-[4.5vw] font-maplestory`}
              style={{
                opacity: selectItem === null ? 0.5 : 1.0,
              }}
              onClick={() => {
                window.location.href = '#closeGame'
              }}
            >
              회원가입 후 이용 할 수 있어요
            </div>
          </button>
        ) : (
          <div className='w-full justify-center flex items-center flex-col'>
            <button
              className='flex justify-center items-center bg-gradient-to-b from-[#B5EC7A] to-[#70CB0C] rounded-[99vw] w-[80%] p-[4vw] font-maplestory relative text-[white]'
              style={{
                opacity: selectItem === null ? 0.5 : 1.0,
                color: 'var(--black-white-white, #FFF)',
                // WebkitTextStrokeWidth: '0.1px',
                WebkitTextStrokeColor: '#4E8A07',
                fontSize:
                  PlantBasicInfo?.PlantNameAndAmount(plantList[selectItem])
                    ?.koreanName?.length > 5
                    ? '5vw'
                    : '5.5vw',
                fontWeight: 'bold',
                border: '#468500 1vw solid',
                height: '16vw',
              }}
              onClick={(e) => {
                if (selectItem === null) return
                e.preventDefault()
                e.stopPropagation()
                AlfarmEventLogger({
                  throttleRef,
                  locationType: 'page',
                  locationName: 'selectFarm',
                  eventName: 'click',
                  eventType: 'click',
                  data: { testName: 'selectFarmTest1015' },
                  collection: 'UserAlfarmClickLog',
                })
                window.location.href = `/farmPage?token=${token}&itemType=${
                  plantList[selectItem]
                }&shareType=${shareType}&alwayzInvitorId=${alwayzInvitorId}&myItemName=${myItemName}${
                  phoneNumber ? `&phoneNumber=${phoneNumber}` : ''
                }${indexNumber ? `&indexNumber=${indexNumber}` : ''}`
              }}
            >
              {selectItem !== null ? (
                <>
                  {
                    PlantBasicInfo.PlantNameAndAmount(plantList[selectItem])
                      .koreanName
                  }{' '}
                  {
                    PlantBasicInfo.PlantNameAndAmount(plantList[selectItem])
                      .amount
                  }{' '}
                  고르기
                  {/* <img
                    className='absolute slide-tl w-[15vw] z-[222] right-[0vw] top-[6vw]'
                    src='/icon/finger.png'
                    alt=''
                  /> */}
                </>
              ) : (
                <>작물을 골라보세요</>
              )}
            </button>
            <div
              style={{
                marginTop: '1vw',
              }}
            >
              지금{' '}
              {
                PlantBasicInfo?.PlantNameAndAmount(plantList[selectItem])
                  ?.ongoingGameCount
              }{' '}
              키우고 있어요!
            </div>
          </div>
        )}
      </div>
    )
  }

  const Plant = ({ selectedItem = 0, itemType = 'onion' }) => {
    return (
      <div
        style={{ ...styles.gridItemStyle, ...styles.scrollableContainer }}
        onClick={() => {
          setSelectItem(selectedItem)
        }}
      >
        <div>
          {itemType === 'coffee_starbucks' && (
            <img
              style={{
                position: 'absolute',
                width: '9vw',
                right: '6vw',
                top: '2vw',
              }}
              src={`/icon/starbucks.png`}
              alt=''
            />
          )}
          {itemType === 'coffee_compose' && (
            <img
              style={{
                position: 'absolute',
                width: '9vw',
                right: '6vw',
                top: '2vw',
                borderRadius: 99,
              }}
              src={`/icon/compose.png`}
              alt=''
            />
          )}
          {itemType === 'coffee_mega' && (
            <img
              style={{
                position: 'absolute',
                width: '9vw',
                right: '6vw',
                top: '2vw',
              }}
              src={`/icon/mega.png`}
              alt=''
            />
          )}

          {[
            'coffee_mega',
            'coffee_compose',
            'ramen',
            'pokachip',
            'mychew',
          ]?.includes(itemType) && (
            <img
              style={{
                position: 'absolute',
                width: '18vw',
                left: '10vw',
                top: '0vw',
              }}
              src={`/icon/easyTag.png`}
              alt=''
            />
          )}

          <div
            style={{
              position: 'relative',
            }}
          >
            <img
              style={{
                width: '30vw',
                marginBottom: '2vw',
                zIndex: 2,
              }}
              src={`/icon/farm/${itemType}.png`}
              alt=''
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {selectedItem === selectItem ? (
            <div
              style={{
                width: 20,
                height: 20,
                border: '2px solid #69E299',
                borderRadius: 99,
                backgroundImage: `url(/icon/selectFarmCheck.png)`,
              }}
            ></div>
          ) : (
            <div
              style={{
                width: 20,
                height: 20,
                border: '2px solid #69E299',
                borderRadius: 99,
              }}
            ></div>
          )}

          <div style={{ marginLeft: 8 }}>
            {PlantBasicInfo.PlantNameAndAmount(itemType).koreanName}
          </div>
        </div>
      </div>
    )
  }

  if (!userId) {
    return <></>
  } else
    return (
      <>
        {scrollIndicatorOn && (
          <div
            className='flex flex-col justify-center items-center bg-black bg-opacity-75 h-[100vh] w-[100vw] absolute z-[2222]'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()

              setScrollIndicatorOn(false)
              setScrollIndicatorOff(false)
            }}
          >
            <div className='w-full h-full absolute flex justify-center items-center flex-col text-[6vw] text-center mt-[2vw]'>
              <div className='p-[10vw] rounded-[4vw] bg-[#f5f5f5] text-[#2F443D] w-[70vw]'>
                무료로 받을 작물을 <br />
                골라보세요
              </div>
            </div>
          </div>
        )}
        {!scrollIndicatorOff && (
          <img
            className='scroll-indicator w-[15vw] z-[222] right-[40%] top-[40%] absolute '
            src='/icon/finger.png'
            alt=''
          />
        )}
        <div className='flex flex-col justify-center items-center bg-black bg-opacity-75 overflow-x-hidden pt-[12vw] h-[100vh]'>
          {/* 타이틀 */}

          <div className='text-[6vw] text-[#fff] flex justify-center items-end mb-[1vw] text-center'>
            무료로 받을 작물을 골라보세요! <br />
            {/* 22종의 작물 중 하나를 선택해 보세요 */}
          </div>

          {/* 선택 */}
          <div className='w-full flex h-[50vh]'>
            <div className='w-full h-[50vh] flex flex-col items-center justify-center p-[2vw] pt-[12vw]'>
              {/* select section */}
              <div className='flex-1 relative flex flex-wrap overflow-y-scroll scrollbar-hide mt-[-8vw]'>
                {plantList?.map((item, index) => (
                  <Plant selectedItem={index} itemType={item} key={index} />
                ))}
              </div>
            </div>
          </div>

          {/* CTA */}
          <CTA />

          {/* 보증 */}
          {/* <div className='flex text-[3.5vw]'>
          <div
            className='w-[5vw] mr-[4px]'
            style={{
              backgroundImage: `url(/icon/selectFarmShield.png)`,
            }}
          />
          <div className='text-[#D3526A] font-bold'>올웨이즈</div>가 보장하는
          무료 수확
        </div> */}

          {/* user section */}

          <div className='flex justify-end items-center flex-col mt-[4vw]'>
            {clearedUserList?.length > 0 && (
              <>
                <MovingComponentsScreen
                  userList={clearedUserList?.slice(0, 10) ?? []}
                />
                <div
                  style={{
                    marginTop: '2vw',
                  }}
                />
                <MovingComponentsScreen
                  userList={clearedUserList?.slice(11, 20) ?? []}
                />
                <div
                  style={{
                    marginTop: '2vw',
                  }}
                />
                <MovingComponentsScreen
                  userList={clearedUserList?.slice(21, 30) ?? []}
                />
              </>
            )}
          </div>
        </div>
      </>
    )
}

const styles = {
  gridContainerStyle: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    overflowY: 'auto',
  },
  gridItemStyle: {
    position: 'relative',
    flex: '0 0 calc(50% - 10px)',
    margin: '1vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  scrollableContainer: {
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: '#888 #eee',
  },
  scrollableContent: {
    paddingRight: '16px', // Adjust as needed to account for scrollbar width
    paddingBottom: '16px', // Adjust as needed to account for scrollbar height
  },
}

export default SelectFarm
