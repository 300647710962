import { makeAutoObservable } from 'mobx'
import backendApis from 'utils/backendApis'
import UserStore from './UserStore'

class TrafficAdStore {
  constructor() {
    this.product = 'ALFARM'
    this.adList = [] // 전체 광고 리스트
    this.isLoading = false
    this.error = null

    makeAutoObservable(this)
  }

  // 광고 리스트 설정
  setAdList(ads) {
    this.adList = ads
  }

  // 로딩 상태 설정
  setLoading(status) {
    this.isLoading = status
  }

  // 에러 설정
  setError(error) {
    this.error = error
  }

  // placement별 광고 필터링
  getAdsByPlacement(placement, count = 1) {
    const filteredAds = this.adList.filter((ad) => {
      return !ad.placement || ad.placement === placement
    })

    // 광고 리스트를 랜덤하게 섞기
    const shuffledAds = [...filteredAds].sort(() => Math.random() - 0.5)

    return shuffledAds.slice(0, count)
  }

  // 광고 데이터 가져오기
  async fetchTrafficAds() {
    if (this.adList.length > 0) {
      return
    }

    try {
      this.setLoading(true)
      this.setError(null)

      const query = {
        product: this.product,
        platform: UserStore.offerwallInfo.platform,
        ifa: UserStore.offerwallInfo.ifa,
        ifv: UserStore.offerwallInfo.ifv,
        enableExternalAds: true,
        adCount: 10,
      }

      const result = await backendApis.getTrafficAds(query)

      console.log(result)
      if (result?.status === 200 && result?.data?.data) {
        // 기존 광고 리스트에 새로운 광고 추가 (중복 제거)
        const newAds = result.data.data

        this.setAdList(newAds)
      }
    } catch (error) {
      this.setError(error)
      console.error('Traffic Ads 조회 실패:', error)
    } finally {
      this.setLoading(false)
    }
  }
}

export default new TrafficAdStore()
