import SquareCenterModal from 'comps/atoms/Modals/SquareCenterModal'
import { useEffect, useState } from 'react'
import backendApis from 'utils/backendApis'

const DailyCheckInSection = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [rewardInfo, setRewardInfo] = useState(null)
  const [dailyOfferwallData, setDailyOfferwallData] = useState(null)
  const fetchDailyOfferwallData = async () => {
    const res = await backendApis.getDailyEventQuestData({event: 'offerwall'})
    setDailyOfferwallData(res?.data)
  }

  useEffect(() => {
    fetchDailyOfferwallData()
  }, [])

  useEffect(() => {
    if (isModalOpen) {
      const timer = setTimeout(() => {
        setIsModalOpen(false)
      }, 2000)

      return () => clearTimeout(timer) 
    }
  }, [isModalOpen])

  const isButtonEnabled = () => {
    const questInfo = dailyOfferwallData?.questInfo?.dailyCheckIn
    const hasNotReceivedReward = !questInfo || questInfo.length === 0
    return hasNotReceivedReward
  }

  const handleCheckIn = async () => {
    if (isLoading) return
    
    try {
      setIsLoading(true)
      const result = await backendApis.clearDailyEventQuest({event:'offerwall', questType:'dailyCheckIn'})
      const rewardInfo = result?.data?.rewardInfo
      if( rewardInfo ) {
        setIsModalOpen(true)
        setRewardInfo(rewardInfo)
        fetchDailyOfferwallData()
      }
    } catch (error) {
      console.error('출석체크 실패:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
    <div className="flex items-center py-[12px] px-[16px] bg-white rounded-[12px] border border-solid border-[#EEEFF3]">
      <div className="flex-1">
        <div className="flex items-center gap-2">
          <img 
            src='/ads/badahamMission/checkinIcon.png'
            alt="출석체크" 
            className="w-[48px] h-[48px]"
          />
          <div>
            <h3 className="font-bold text-[#191D28] text-[18px] tracking-[-0.2px] leading-[140%]">출석체크하기</h3>
            <div className="flex items-center gap-[4px]">
              <img src='/icon/water.png' alt='지급' className='w-[16px] h-[16px]' />
              <span className="text-[#71737C] text-[14px] tracking-[-0.2px]">최대 15g</span>
            </div>
          </div>
        </div>
      </div>
      
      <button
        onClick={handleCheckIn}
        disabled={!isButtonEnabled() || isLoading}
        className={`
          rounded-lg px-4 py-2 font-bold text-center min-w-[82px]
          ${!isButtonEnabled() || isLoading
            ? 'bg-[#EEEFF3] text-[#5D5F68]' 
            : 'bg-[#FFEDEE] text-[#F8323E] active:brightness-95 active:scale-95'
          }
          transition-all duration-200
        `}
      >
        {isLoading ? '처리중...' : !isButtonEnabled() ? '출석완료' : '출석하기'}
      </button>
    </div>
      {isModalOpen && (
        <SquareCenterModal
          content={
            <div className="flex flex-col items-center gap-[6px] w-[110px] h-[125px]">
              <span className="text-white font-bold text-[20px]">출석완료</span>
              <img src='/icon/water.png' alt='지급' className='w-[60px] h-[60px]' />
              <span className="text-white font-light text-[18px]">물{rewardInfo?.water}g 지급</span>
            </div>
          } 
        />
      )}
    </>
  )
}

export default DailyCheckInSection