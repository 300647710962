import AlwayzUserStore from 'store/AlwayzUserStore'
import AB from 'utils/ab_v3'
import { computed } from 'mobx'

/**
 * 오퍼월 물 보상 범위에 대한 AB 테스트
 * @description 'a'군은 물 10-1000g으로 노출, 'b'군은 10-3000g 노출
 */
export const offerwallWaterRangeAB = computed(() =>
  AB(AlwayzUserStore.alwayzUserData._id, 'offerwallWaterRange1017', [100]),
)

/**
 * 오퍼월 물 보상 대한 AB 테스트
 * @description 'a'군은 보이지 않음, 'b'군은 오퍼월 버튼 노출
 */
export const offerwallWaterAB = computed(() =>
  AB(AlwayzUserStore.alwayzUserData._id, 'offerwallWater1022', [99]),
)
