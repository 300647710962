export const Chevron = ({
  className,
  pathClassName,
  direction,
}) => {
  const getTransform = () => {
    return {
      up: 'rotate(270deg)',
      left: 'rotate(180deg)',
      right: 'rotate(0deg)',
      down: 'rotate(90deg)',
    }[direction]
  }

  return (
    <div
      style={{
        transform: getTransform(),
      }}
    >
      <svg
        width='6'
        height='12'
        viewBox='0 0 8 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={className}
      >
        <path
          d='M1.07812 0.5L7.07812 6.5L1.07812 12.5'
          stroke='white'
          strokeLinecap='round'
          strokeLinejoin='round'
          className={pathClassName}
        />
      </svg>
    </div>
  )
}
