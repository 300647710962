import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import ModalStore from '../../store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import UserStore from 'store/UserStore'

const NewUserValueIndicateModal = observer(({ token, setFarmData }) => {
  const throttleRef = useRef({})
  const [background, setBackground] = useState('harvestCountNotice')
  const [reviewData, setReviewData] = useState([])
  const [rewardInfo, setRewardInfo] = useState({
    type: 'fertilizer',
    amount: 2,
  })

  useEffect(() => {
    alfarmReview()
    pickModal()
  }, [])

  const alfarmReview = async () => {
    const result = await backendApis.getAlfarmReview(token)
    setReviewData(result)
  }

  const pickModal = async () => {
    switch (UserStore?.farmData?.newUserValueIndicateInfo?.step) {
      case 1:
        setBackground('harvestCountNotice')
        setRewardInfo({ type: 'fertilizer', amount: 2 })
        break
      case 2:
        setBackground('nutrimentNotice')
        setRewardInfo({ type: 'fertilizerSet', amount: 1 })
        break
      case 3:
        if (
          [
            'coffee_mega',
            'coffee_compose',
            'ramen',
            'pokachip',
            'mychew',
          ]?.includes(UserStore?.farmData?.itemType)
        ) {
          setBackground('gifticonLeadtimeNotice')
        } else {
          setBackground('leadtimeNotice')
        }
        setRewardInfo({ type: 'fertilizer', amount: 2 })
        break
      case 4:
        setBackground('gganbuNotice')
        setRewardInfo(null)
        break
      default:
        setBackground('harvestCountNotice')
        setRewardInfo({ type: 'fertilizer', amount: 2 })
        break
    }
  }

  const buttonClick = async () => {
    if (!UserStore?.farmData?.isNewUser) {
      ModalStore?.setIsModalOpen('basic')
      return
    }
    if (UserStore?.farmData?.newUserValueIndicateInfo?.step >= 4) {
      setFarmData((prev) => {
        return {
          ...prev,
          [`${rewardInfo?.type}`]:
            prev?.[`${rewardInfo?.type}`] + rewardInfo?.amount,
          newUserValueIndicateInfo: {
            step:
              (UserStore?.farmData?.newUserValueIndicateInfo?.step ?? 1) + 1,
          },
        }
      })
      ModalStore?.setIsModalOpen('basic')
      ModalStore?.setIsModalOpen('gganbuInvite')
      return
    }
    backendApis.giveReward(token, 'PUT', {
      loggingRewardName: 'newUserValueIndicate',
      rewardType: rewardInfo?.type,
      valueAmount: rewardInfo?.amount,
    })
    backendApis.updateSpecificField({
      fieldName: 'newUserValueIndicateInfo.step',
      value: (UserStore?.farmData?.newUserValueIndicateInfo?.step ?? 1) + 1,
    })

    ModalStore.setRewardPopUpOn({
      bool: true,
      type: rewardInfo?.type,
      amount: rewardInfo?.amount,
    })
    setFarmData((prev) => {
      return {
        ...prev,
        [`${rewardInfo?.type}`]:
          prev?.[`${rewardInfo?.type}`] + rewardInfo?.amount,
        newUserValueIndicateInfo: {
          step: (UserStore?.farmData?.newUserValueIndicateInfo?.step ?? 1) + 1,
        },
      }
    })
    ModalStore?.setIsModalOpen('basic')
  }

  const ReviewPhoto = ({ review }) => {
    return (
      <div>
        <img
          className='w-[14vw] h-[14vw] object-cover rounded-md'
          src={review?.reviewImageUris?.[0]}
          alt='review'
        />
        <div className='flex flex-row mt-[2vw] justify-start items-center'>
          <img
            className='w-[5vw] h-[5vw] rounded-full mr-2'
            src={review?.userImageUri}
            alt='abc'
          />
          <div className='text-[3vw]'>
            {review?.userName?.length > 3
              ? review?.userName?.slice(0, 3) + '..'
              : review?.userName}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='flex flex-1 fixed left-0 top-0 z-[1900] w-full h-full bg-black bg-opacity-80'>
      <div
        className={`relative w-[80vw] h-[74.25vw] 
          top-[45%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-around items-center flex-col`}
        style={{
          backgroundImage: `url(../modal/${background}.png)`,
        }}
      >
        <button
          className='absolute w-[8vw] right-0 top-[-12vw] z-[1999]'
          onClick={() => {
            buttonClick()
          }}
        >
          <img className='z-[1101]' src='/icon/modalCloseButton.png' alt='' />
        </button>
        <div className='w-full mt-[120vw] z-[1101] absolute'>
          <div className='mb-[2vw]'>실제 무료로 작물을 받은 후기 사진</div>
          <div className='flex w-full flex-row gap-x-4 overflow-x-scroll'>
            {reviewData?.map((review, index) => {
              return <ReviewPhoto review={review} />
            })}
          </div>
        </div>
        <div
          className='w-[70vw] h-[12vw] absolute bottom-[7vw]'
          onClick={() => {
            buttonClick()
          }}
        />
      </div>
    </div>
  )
})
export default NewUserValueIndicateModal
