import React, { useState, useEffect } from 'react'
import moment from 'moment'
import backendApis from 'utils/backendApis'
import PlantBasicInfo from '../PlantBasicInfo'
import LottieGif from 'comps/atoms/LottieGif'
import { farmingList } from '../../../config.jsx'
import giveWaterJson from '../../../json/gifJson/water/giveWater.json'
import GganbuInteractionButton from './GganbuInteractionButton'

const GganbuFarmComponent = ({
  token,
  gganbuData,
  setGganbuData,
  waterGivingGganbuId,
  myFarmData,
  waterGganbuFarm,
  getGoodsFromGganbu,
  pokeModalInfo,
  setPokeModalInfo,
}) => {
  const [level, setLevel] = useState(0)
  const [clearedCount, setClearedCount] = useState(1)
  const [gganbuFarmResetInfo, setGganbuFarmResetInfo] = useState()
  const [pokeCount, setPokeCount] = useState()

  const gganbuRewardInfo = async () => {
    const result = await backendApis.getBonusRewardInfo(token, 'GET', {
      gganbuId: gganbuData.userId,
    })

    setGganbuFarmResetInfo(result?.gganbuFarmResetInfo)
  }

  useEffect(() => {
    const getLevelInfo = async ({ exp }) => {
      const level1 = gganbuData?.farmLevel?.level1
      const level2 = gganbuData?.farmLevel?.level2
      const level3 = gganbuData?.farmLevel?.level3
      const level4 = gganbuData?.farmLevel?.level4
      const level5 = gganbuData?.farmLevel?.level5
      const level6 = gganbuData?.farmLevel?.level6

      const levelData = [level1, level2, level3, level4, level5, level6]

      let level = 1
      let remainExp = 0
      let expToLevelUp = 0
      let prev = 0
      for (let i = 0; i < levelData.length; i++) {
        const each = levelData[i]
        if (exp > each) level++
        else {
          expToLevelUp = each - exp
          remainExp = exp - prev
          break
        }

        prev = each
      }

      if (level > 6) {
        setLevel(6)
      } else {
        setLevel(level)
      }

      return { level, remainExp, expToLevelUp }
    }

    const getPokeData = async () => {
      const result = await backendApis.getPokeData(token, 'GET', {
        gganbuId: gganbuData.userId,
      })

      if (result?.status === 200) {
        setPokeCount(result?.data?.pokeCount)
        setPokeModalInfo((prevState) => ({
          ...prevState, // 기존의 pokeModalInfo를 유지
          [gganbuData.userId]: {
            // `gganbuData.userId`를 키로 설정
            gganbuData: gganbuData,
            pokeCount: result?.data?.pokeCount,
            userIdTurn: result?.data?.userIdTurn,
          },
        }))
      }
    }
    const getClearedGameInfo = async () => {
      const result = await backendApis.clearedItemGganbu(token, 'GET', {
        gganbuId: gganbuData.userId,
      })
      let count = 1

      if (result?.data?.count) {
        for (const [k, v] of Object.entries(result?.data?.count)) {
          count += v
        }

        setClearedCount(count)
      }
    }

    if (level > 0) {
      return
    }

    getLevelInfo({ exp: gganbuData.exp })
    gganbuRewardInfo()
    getPokeData()
    getClearedGameInfo()
  }, [token, gganbuData.userId, waterGivingGganbuId])

  return (
    <div className='relative flex justify-between w-[92vw] h-[36vw] border-2 border-white bg-[#FFFFFF80] rounded-2xl text-black mb-[4vw]'>
      <div className='relative'>
        {/* 맞팜 작물 */}
        <div className='relative h-[26vw] w-[26vw] ml-[4vw]'>
          <img
            className='absolute -top-[12vw]'
            alt=''
            src={`/farm/${
              farmingList[PlantBasicInfo.IdFromPlantName(gganbuData.itemType)]
            }/${level}.gif`}
          />
          {waterGivingGganbuId === gganbuData.userId?.toString() && (
            <LottieGif
              animationData={giveWaterJson}
              className='z-[100001] w-[40vw] h-auto mt-[10vw] absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 flex justify-center items-center'
              loop
            />
          )}
        </div>

        {/* 맞팜 프로필이미지와 이름 */}
        <div className='relative flex items-center ml-[4vw]'>
          <div
            className={`absolute -top-[4vw] -right-[0vw] z-[3] px-[1vw] py-[0.5vw] items-center justify-center text-[2vw] text-white font-bold rounded-md bg-[#09090960] ${
              clearedCount > 9 ? 'bg-[#e8000060]' : 'bg-[#09090960]'
            }`}
          >
            {clearedCount}번째 수확 중
          </div>
          <div className='rounded-full border-[0.4vw] border-white z-[2]'>
            <img
              src={gganbuData.userImage}
              alt=''
              className='w-[6vw] h-[6vw] rounded-full'
            />
          </div>
          <div className='absolute left-[3vw] bg-[#09090960] rounded-md w-[23vw] py-[0.5vw] text-center'>
            <span className={gganbuData.userName.length > 5 ? 'ml-2' : ''} />
            {gganbuData.userName.length > 5
              ? gganbuData.userName.slice(0, 5) + '..'
              : gganbuData.userName}
          </div>
        </div>

        <div className='absolute top-[10vw] -right-[16vw]'>
          <GganbuInteractionButton
            // 물주기 버튼
            token={token}
            waterGganbuFarm={waterGganbuFarm}
            getGoodsFromGganbu={getGoodsFromGganbu}
            gganbuFarmResetInfo={gganbuFarmResetInfo}
            refreshGganbuFarmResetInfo={gganbuRewardInfo}
            type='giveWater'
            gganbuData={gganbuData}
            style={{
              width: '18vw',
              height: '18vw',
            }}
            disabled={
              myFarmData?.water < 10 ||
              !gganbuData.exp ||
              gganbuData?.farmLevel?.level6 <= gganbuData?.exp
            }
          />
        </div>
        {myFarmData?.water >= 50 && (
          <div className='absolute top-[4vw] -right-[15vw]'>
            <GganbuInteractionButton
              // 5번 물주기 버튼
              token={token}
              waterGganbuFarm={waterGganbuFarm}
              getGoodsFromGganbu={getGoodsFromGganbu}
              gganbuFarmResetInfo={gganbuFarmResetInfo}
              refreshGganbuFarmResetInfo={gganbuRewardInfo}
              type='giveWaterFive'
              gganbuData={gganbuData}
              style={{
                width: '16vw',
                height: 'auto',
              }}
              disabled={
                myFarmData?.water < 50 ||
                !gganbuData.exp ||
                gganbuData?.farmLevel?.level6 <= gganbuData?.exp
              }
            />
          </div>
        )}
      </div>

      {/* 상호작용 버튼 영역 */}
      <div className='grid grid-cols-2 mr-[4vw]'>
        <GganbuInteractionButton
          // 물받기 버튼
          token={token}
          waterGganbuFarm={waterGganbuFarm}
          getGoodsFromGganbu={getGoodsFromGganbu}
          type='getWater'
          gganbuData={gganbuData}
          setGganbuData={setGganbuData}
          gganbuFarmResetInfo={gganbuFarmResetInfo}
          refreshGganbuFarmResetInfo={gganbuRewardInfo}
          disabled={moment().isBefore(
            moment(gganbuFarmResetInfo?.lastGetWaterAt || 0).add(4, 'hours'),
          )}
          indicator={moment().isBefore(
            moment(gganbuFarmResetInfo?.lastGetWaterAt || 0).add(4, 'hours'),
          )}
        />
        <GganbuInteractionButton
          // 비료받기 버튼
          token={token}
          waterGganbuFarm={waterGganbuFarm}
          getGoodsFromGganbu={getGoodsFromGganbu}
          type='getFert'
          gganbuData={gganbuData}
          gganbuFarmResetInfo={gganbuFarmResetInfo}
          refreshGganbuFarmResetInfo={gganbuRewardInfo}
          disabled={
            gganbuFarmResetInfo?.didGiveWaterToGganbuToday &&
            !gganbuFarmResetInfo?.bonusBoxAvailable
          }
          indicator={
            gganbuFarmResetInfo?.didGiveWaterToGganbuToday &&
            moment().isSameOrAfter(
              moment(gganbuData?.lastGiveWaterAt).add(5, 'days'),
            ) &&
            !myFarmData?.resurrectBonus
          }
        />
        <GganbuInteractionButton
          // 콕찌르기 버튼
          token={token}
          waterGganbuFarm={waterGganbuFarm}
          getGoodsFromGganbu={getGoodsFromGganbu}
          setPokeModalInfo={setPokeModalInfo}
          type='poke'
          gganbuData={gganbuData}
          pokeCount={pokeCount}
          disabled={pokeCount >= 4}
        />
        <GganbuInteractionButton
          // 맞팜 농장가기 버튼
          token={token}
          waterGganbuFarm={waterGganbuFarm}
          getGoodsFromGganbu={getGoodsFromGganbu}
          type='visitFarm'
          gganbuData={gganbuData}
          disabled={
            !gganbuData.exp || gganbuData?.farmLevel?.level6 <= gganbuData?.exp
          }
        />
      </div>

      {(gganbuData?.farmLevel?.level6 <= gganbuData?.exp ||
        gganbuData?.exp === 0) && (
        <div className='absolute w-full h-auto z-[3]'>
          <img
            className='w-full h-auto'
            src='/icon/gganbuFarm/icn_gganbuFarmList_disabled_gganbu.png'
            alt=''
          />
        </div>
      )}
    </div>
  )
}

export default GganbuFarmComponent
