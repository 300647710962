import { observer } from 'mobx-react-lite'
import React, { useState, useEffect } from 'react'
import backendApis from 'utils/backendApis'
import ModalStore from 'store/ModalStore'
import timeChecker from 'utils/timeChecker'
import BottomSheetStore from 'store/BottomSheetStore'
import AlfarmAdStore from 'store/AlfarmAdStore'

const SellerAdPromotingModal = observer(
  ({ token, farmData, giveWaterBasket, setFarmData, showRewardToast }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [leavesAdAvailable, setLeavesAdAvailable] = useState(false)
    const [renderingReady, setRenderingReady] = useState(false)
    const [quizAdAvailable, setQuizAdAvailable] = useState(false)
    const [winnerDealAdAvailable, setWinnerDealAdAvailable] = useState(false)
    const [eggAdAvailable, setEggAdAvailable] = useState(false)
    const [yutnoriAdAvailable, setYutnoriAdAvailable] = useState(false)
    const [firstRewardAvailable, setFirstRewardAvailable] = useState(false)
    const [secondRewardAvailable, setSecondRewardAvailable] = useState(false)
    const [thirdRewardAvailable, setThirdRewardAvailable] = useState(false)
    const [doneFirstRewardToday, setDoneFirstRewardToday] = useState(true)
    const [doneSecondRewardToday, setDoneSecondRewardToday] = useState(true)
    const [doneThirdRewardToday, setDoneThirdRewardToday] = useState(true)
    const [quizSolveCheck, setQuizSolveCheck] = useState(false)

    let FIRST_REWARD_WATER
    let SECOND_REWARD_WATER
    let THIRD_REWARD_FERTILIZER

    // FIRST_REWARD_WATER: 20~30 범위, 평균 24
    const RANDOM_NUMBER_1 = Math.random()
    if (RANDOM_NUMBER_1 < 0.7) {
      // 70% 확률로 20 ~ 25 사이의 값
      FIRST_REWARD_WATER = Math.floor(RANDOM_NUMBER_1 * 6 + 20) // 20 ~ 25
    } else {
      // 30% 확률로 25 ~ 30 사이의 값
      FIRST_REWARD_WATER = Math.floor(RANDOM_NUMBER_1 * 6 + 25) // 25 ~ 30
    }

    // SECOND_REWARD_WATER: 40~100 범위, 평균 60
    const RANDOM_NUMBER_2 = Math.random()
    if (RANDOM_NUMBER_2 < 0.8333) {
      // 83.33% 확률로 40 ~ 70 사이의 값
      SECOND_REWARD_WATER = Math.floor(RANDOM_NUMBER_2 * 30 + 40) // 40 ~ 70
    } else {
      // 16.67% 확률로 70 ~ 100 사이의 값
      SECOND_REWARD_WATER = Math.floor((RANDOM_NUMBER_2 - 0.8333) * 30 + 70) // 70 ~ 100
    }

    // THIRD_REWARD_FERTILIZER: 1~3 범위, 평균 1.8
    const RANDOM_NUMBER_3 = Math.random()
    if (RANDOM_NUMBER_3 < 0.6667) {
      // 66.67% 확률로 1 ~ 2 사이의 값
      THIRD_REWARD_FERTILIZER = Math.floor(RANDOM_NUMBER_3 * 2 + 1) // 1 ~ 2
    } else {
      // 33.33% 확률로 2 ~ 3 사이의 값
      THIRD_REWARD_FERTILIZER = Math.floor(RANDOM_NUMBER_3 * 2 + 2) // 2 ~ 3
    }

    // 보상 수령 허들 정의
    const FIRST_HURDLE = 1
    const SECOND_HURDLE = 3
    const THIRD_HURDLE = 5
    let todayClearedCount = 0 // 오늘 깬 광고 미션 횟수

    useEffect(() => {
      const checkProgressedStatus = async () => {
        await checkAdAvailable()
        await checkRewardAvailable()
        setRenderingReady(true)
      }
      checkProgressedStatus()
    }, [doneFirstRewardToday, doneSecondRewardToday, doneThirdRewardToday])
    // 각 광고들의 현재 볼 수 있는지 상태 검사 (다했는지 등등) -> 각 광고에서 가져옴
    // 광고 잔여수량 & 메인 아이디 있는지 & 미션 클리어 여부
    // 특정 광고가 특정 기준으로 열린다면 그에 맞는 버전 최소 단위 & 물주기(레벨) 최소 단위 등을 맞춰야 함

    const checkAdAvailable = async () => {
      if (isLoading) return
      setIsLoading(true)

      try {
        const result = await backendApis.getWaterBottomSheetInfo(token)

        // API 응답 유효성 검사
        if (!result?.waterBottomSheetInfoTimeChecker) {
          throw new Error('광고 정보를 가져오는데 실패했습니다')
        }

        const {
          winnerDealCount = null,
          leavesCount = null,
          solvedAndRewardedQuizCount = null,
          brokenAndRewardedEggCount = null,
          yutnoriCount = null,
          solvedQuizCount = [],
        } = result.waterBottomSheetInfoTimeChecker

        // 각 광고의 유효성과 가용성 체크
        const isValidCount = (count) => count !== null && count === 0

        // 위너딜 광고
        if (isValidCount(winnerDealCount)) {
          setWinnerDealAdAvailable(true)
        } else {
          todayClearedCount++
        }

        // 잎새 광고
        if (isValidCount(leavesCount)) {
          setLeavesAdAvailable(true)
        } else {
          todayClearedCount++
        }

        // 퀴즈 광고
        if (isValidCount(solvedAndRewardedQuizCount)) {
          setQuizAdAvailable(true)
        } else {
          todayClearedCount++
        }

        // 알 깨기 광고
        if (isValidCount(brokenAndRewardedEggCount)) {
          setEggAdAvailable(true)
        } else {
          todayClearedCount++
        }

        // 윷놀이 광고
        if (isValidCount(yutnoriCount)) {
          setYutnoriAdAvailable(true)
        } else {
          todayClearedCount++
        }

        // 퀴즈 경로 체크
        if (Array.isArray(solvedQuizCount) && solvedQuizCount.length > 0) {
          setQuizSolveCheck(true)
        }
      } catch (error) {
        console.error('광고 상태 확인 중 오류 발생:', error)
        // showErrorToast('광고 정보를 불러오는데 실패했습니다')
      } finally {
        setIsLoading(false)
      }
    }

    // 미션들의 현재까지의 진행 상태 검사
    const checkRewardAvailable = async () => {
      if (isLoading) return
      setIsLoading(true)

      try {
        const result = await backendApis.getRewardLogForSellerAdPromoting(token)

        if (!result || result.status !== 200) {
          throw new Error('리워드 정보를 가져오는데 실패했습니다')
        }

        const rewardLogData = result.data?.rewardLogForSellerAdPromoting || []

        // 각 리워드 단계별 수령 여부 체크
        const checkRewardStatus = (rewardType) => {
          return (
            rewardLogData.filter((log) => {
              // rewardType에 따라 해당하는 리워드 시간을 체크
              const rewardTime = log?.[rewardType]
              return !timeChecker(rewardTime)
            }).length === 0
          )
        }

        // 첫 번째 리워드 체크
        if (checkRewardStatus('getFirstRewardAt')) {
          setDoneFirstRewardToday(false)
        }

        // 두 번째 리워드 체크
        if (checkRewardStatus('getSecondRewardAt')) {
          setDoneSecondRewardToday(false)
        }

        // 세 번째 리워드 체크
        if (checkRewardStatus('getThirdRewardAt')) {
          setDoneThirdRewardToday(false)
        }

        // 리워드 가용성 체크
        const checkHurdleAndSetReward = [
          {
            hurdle: FIRST_HURDLE,
            isDone: doneFirstRewardToday,
            setAvailable: setFirstRewardAvailable,
          },
          {
            hurdle: SECOND_HURDLE,
            isDone: doneSecondRewardToday,
            setAvailable: setSecondRewardAvailable,
          },
          {
            hurdle: THIRD_HURDLE,
            isDone: doneThirdRewardToday,
            setAvailable: setThirdRewardAvailable,
          },
        ]

        checkHurdleAndSetReward.forEach(({ hurdle, isDone, setAvailable }) => {
          if (todayClearedCount >= hurdle && !isDone) {
            setAvailable(true)
          }
        })
      } catch (error) {
        console.error('리워드 상태 확인 중 오류 발생:', error)
        // showErrorToast(error.message)
      } finally {
        setIsLoading(false)
      }
    }

    const MissionLandingButton = ({ missionType }) => {
      if (!renderingReady) return
      const missionTypeToModalMap = {
        yutnori: () => {
          ModalStore.setIsModalOpen('yutnori')
        },
        egg: () => ModalStore.setIsModalOpen('eggBreakersV2'),
        leaves: () => ModalStore.setIsModalOpen('nutrimentLeavesV2'),
        quiz: () => {
          if (!quizSolveCheck) {
            ModalStore.setIsModalOpen('alfarmQuiz')
          } else {
            ModalStore.setIsModalOpen('quizPurchase')
          }
        },
        winnerDeal: () => {
          BottomSheetStore.setIsbottomSheetOpen('water')
          ModalStore.setIsModalOpen('basic')
        },
      }
      const pageLanding = missionTypeToModalMap[missionType]

      const missionTypeToAvailabilityMap = {
        yutnori: yutnoriAdAvailable,
        egg: eggAdAvailable,
        leaves: leavesAdAvailable,
        quiz: quizAdAvailable,
        winnerDeal: winnerDealAdAvailable,
      }
      const buttonOn = missionTypeToAvailabilityMap[missionType]

      let text = '바로가기'
      let backgroundColor = '#85C836'
      if (!buttonOn) {
        text = '미션 완료'
        backgroundColor = '#94938F'
      }

      const missionTypeToMarginTop = {
        yutnori: '-23.1%',
        egg: '9.7%',
        leaves: '41.7%',
        quiz: '74.2%',
        winnerDeal: '105.9%',
      }
      const marginTop = missionTypeToMarginTop[missionType]

      return (
        <button
          style={{
            position: 'absolute',
            flex: 1,
            width: '20%',
            height: '8vw',
            marginTop: marginTop,
            right: '12%',
            zIndex: 1000,
            color: 'white',
            backgroundColor: backgroundColor,
            borderRadius: '5px',
            fontWeight: 'bold',
            fontSize: '3.5vw',
          }}
          onClick={() => {
            if (isLoading || !buttonOn) return
            setIsLoading(true)
            pageLanding()
            setIsLoading(false)
          }}
        >
          {text}
        </button>
      )
    }

    const GetRewardButton = ({ rewardType }) => {
      if (!renderingReady) return

      let buttonStatus = 'before'
      if (rewardType === 'first') {
        if (firstRewardAvailable) {
          buttonStatus = 'rewardReady'
        } else if (doneFirstRewardToday) {
          buttonStatus = 'rewardDone'
        }
      } else if (rewardType === 'second') {
        if (secondRewardAvailable) {
          buttonStatus = 'rewardReady'
        } else if (doneSecondRewardToday) {
          buttonStatus = 'rewardDone'
        }
      } else if (rewardType === 'third') {
        if (thirdRewardAvailable) {
          buttonStatus = 'rewardReady'
        } else if (doneThirdRewardToday) {
          buttonStatus = 'rewardDone'
        }
      }

      let text = ''
      let color = 'black'
      if (rewardType === 'first') {
        text = '1개 완료 시'
      } else if (rewardType === 'second') {
        text = '3개 완료 시'
      } else if (rewardType === 'third') {
        text = '5개 완료 시'
      }
      let backgroundColor = '#'
      if (buttonStatus === 'rewardReady') {
        text = '받기'
        backgroundColor = '#85C836'
        color = 'white'
      } else if (buttonStatus === 'rewardDone') {
        text = '완료'
        backgroundColor = '#94938F'
        color = 'white'
      }

      const marginLeft = {
        first: '-60%',
        second: '0%',
        third: '61%',
      }

      return (
        <button
          style={{
            position: 'absolute',
            flex: 1,
            width: '17vw',
            height: '7vw',
            marginTop: '24%',
            marginLeft: marginLeft[rewardType],
            zIndex: 3000,
            color: color,
            backgroundColor: backgroundColor,
            borderRadius: '5px',
            fontWeight: 'bold',
            fontSize: '3vw',
          }}
          onClick={async () => {
            if (isLoading || buttonStatus !== 'rewardReady') return
            setIsLoading(true)
            if (rewardType === 'first') {
              await backendApis.giveRewardForSanSinEvent(token, 'PUT', {
                loggingRewardName: 'SanSinFirstReward',
                rewardType: 'water',
                valueAmount: FIRST_REWARD_WATER,
              })
              await backendApis.recordSellerAdPromotingRewardLog(token, 'PUT', {
                rewardType: 'first',
              })
              showRewardToast(FIRST_REWARD_WATER, 'water')
              setFirstRewardAvailable(false)
              setDoneFirstRewardToday(true)
              AlfarmAdStore.setGetRendering(!AlfarmAdStore.getRendering)
            } else if (rewardType === 'second') {
              await backendApis.giveRewardForSanSinEvent(token, 'PUT', {
                loggingRewardName: 'SanSinSecondReward',
                rewardType: 'water',
                valueAmount: SECOND_REWARD_WATER,
              })
              await backendApis.recordSellerAdPromotingRewardLog(token, 'PUT', {
                rewardType: 'second',
              })
              showRewardToast(SECOND_REWARD_WATER, 'water')
              setSecondRewardAvailable(false)
              setDoneSecondRewardToday(true)
              AlfarmAdStore.setGetRendering(!AlfarmAdStore.getRendering)
            } else if (rewardType === 'third') {
              await backendApis.giveRewardForSanSinEvent(token, 'PUT', {
                loggingRewardName: 'SanSinThirdReward',
                rewardType: 'fertilizer',
                valueAmount: THIRD_REWARD_FERTILIZER,
              })
              await backendApis.recordSellerAdPromotingRewardLog(token, 'PUT', {
                rewardType: 'third',
              })
              showRewardToast(THIRD_REWARD_FERTILIZER, 'fertilizer')
              setThirdRewardAvailable(false)
              setDoneThirdRewardToday(true)
              AlfarmAdStore.setShowPingForSanSinEvent(null)
              AlfarmAdStore.setGetRendering(!AlfarmAdStore.getRendering)
            }
            setIsLoading(false)
          }}
        >
          {text}
        </button>
      )
    }

    const ModalCloseButton = () => {
      return (
        <button
          style={{
            width: '10%',
            top: '8%',
            right: '0%',
            position: 'absolute',
            zIndex: 1000,
          }}
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img src='/icon/modalCloseButton.png' alt='' />
        </button>
      )
    }

    const ModalUpperBody = () => {
      return (
        <>
          <img
            style={{}}
            src='/farm/SanSinEvent/sansinEventUpperModalNew.png'
            alt=''
          />
        </>
      )
    }

    const ModalLowerBody = () => {
      return (
        <>
          <img
            style={{}}
            src='/farm/SanSinEvent/sansinEventLowerModalNew.png'
            alt=''
          />
        </>
      )
    }

    // 각 광고 available의 true/false에 따라 버튼 on
    // 각 reward available의 true/false에 따라 버튼 on
    // todayClearedCount에 따라 진행상황 표시
    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 140,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.8)',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              width: '90vw',
            }}
          >
            <ModalCloseButton />
            <div className='w-[90vw] flex justify-around items-center flex-col'>
              <ModalUpperBody />
              <MissionLandingButton missionType='yutnori' />
              <MissionLandingButton missionType='egg' />
              <MissionLandingButton missionType='leaves' />
              <MissionLandingButton missionType='quiz' />
              <MissionLandingButton missionType='winnerDeal' />
            </div>
            <div className='mt-[2vw] w-[90vw] flex justify-around items-center '>
              <ModalLowerBody />
              <GetRewardButton rewardType='first' />
              <GetRewardButton rewardType='second' />
              <GetRewardButton rewardType='third' />
            </div>
          </div>
          <div
            className='reward-buttons'
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              width: '90vw',
              marginTop: '5vh',
            }}
          ></div>
          <div
            className='reward-icons'
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              width: '90vw',
              marginTop: '2vh',
            }}
          ></div>
        </div>
      </>
    )
  },
)

export default SellerAdPromotingModal
