import { observer } from 'mobx-react-lite'
import React, { useState, useEffect } from 'react'
import ModalFrame from '../ModalFrame'
import backendApis from 'utils/backendApis'
import moment from 'moment'
import ModalStore from 'store/ModalStore'
import TimeStore from 'store/TimeStore'
import dayjs from 'dayjs'
import BottomSheetStore from 'store/BottomSheetStore'
import { purchaseCountWithinPeriod4Hour } from 'utils/utils'
import UserStore from 'store/UserStore'
import TrafficAdStore from 'store/TrafficAdStore'
import AlfarmAdStore from 'store/AlfarmAdStore'
import MissionButton from './components/MissionButton'
import { appBridgeSender } from 'utils/appBridgeSender'

const BANNER_ASPECT_RATIO = 358 / 80
const BANNER_AD_PLACEMENT = 'DAILY_MISSION'

const DailyMissionModalV2 = observer(
  ({ token, farmData, showRewardToast, giveWaterBasket, setFarmData }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [clearedQuestCount, setClearedQuestCount] = useState(0)
    const [dailyMissionInfo, setDailyMissionInfo] = useState(null)
    const [todayGiveWaterCount, setTodayGiveWaterCount] = useState(0)
    const [fertilizerBottomSheetInfo, setFertilizerBottomSheetInfo] =
      useState(null)
    const [solvedQuizCount, setSolvedQuizCount] = useState(0)
    const [inviteCount, setInviteCount] = useState(0)
    const [canSolveQuiz, setCanSolveQuiz] = useState(false)
    const [timeDealQuestCount, setTimeDealQuestCount] = useState(0)
    const [lastTimeDealChecked, setLastTimeDealChecked] = useState(null)
    const [isWaterBombPlayedToday, setIsWaterBombPlayedToday] = useState(false)
    const [isLotteryDrawPlayedToday, setIsLotteryDrawPlayedToday] =
      useState(false)
    const [isYutnoriPlayedToday, setIsYutnoriPlayedToday] = useState(false)
    const [isLuckyDrawPlayedToday, setIsLuckyDrawPlayedToday] = useState(false)
    const [clearedArcadeCount, setClearedArcadeCount] = useState(0)
    const [todayPurchasedCount, setTodayPurchasedCount] = useState(0)
    const [canClick, setCanClick] = useState(true)
    const [trafficAd, setTrafficAd] = useState(() =>
      TrafficAdStore.getAdsByPlacement(BANNER_AD_PLACEMENT, 1),
    )

    let hidden, visibilityChange
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden'
      visibilityChange = 'visibilitychange'
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden'
      visibilityChange = 'msvisibilitychange'
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden'
      visibilityChange = 'webkitvisibilitychange'
    }

    useEffect(() => {
      Promise.all([
        getDailyMissionInfo(),
        getFertilizerBottomSheetInfo(),
        getWaterBottomSheetInfo(),
        getMiniGameInfo(),
      ])
        .then(() => {
          // 모든 함수가 완료되었을 때 여기에서 로딩 상태를 해제하거나 설정할 수 있습니다.
          // 예를 들어, 컴포넌트의 상태에 로딩 상태를 설정하려면:
          setIsLoading(false)
        })
        .catch((error) => {
          // 함수 실행 중에 발생할 수 있는 오류를 처리합니다.
          console.error('오류:', error)
        })
    }, [])

    useEffect(() => {
      const fetchData = async () => {
        setIsLoading(true)
        try {
          await getDailyMissionInfo()
          await getFertilizerBottomSheetInfo()
          await getWaterBottomSheetInfo()
          await getMiniGameInfo()
          setIsLoading(false)
        } catch (error) {
          // 오류 처리
          setIsLoading(false) // 오류 발생 시에도 로딩 상태를 해제합니다.
        }
      }

      // 페이지의 가시성 변경 이벤트 핸들러
      const handleVisibilityChange = () => {
        if (!document[hidden]) {
          // 페이지가 다시 가시 상태로 돌아올 때 API를 다시 호출합니다.
          fetchData()
        }
      }

      // VisibilityChange 이벤트 리스너 추가
      document.addEventListener(visibilityChange, handleVisibilityChange)

      // 컴포넌트 언마운트 시에 이벤트 리스너 제거
      return () => {
        document.removeEventListener(visibilityChange, handleVisibilityChange)
      }

      // useEffect를 isLoading에 종속시킴으로써 가시성 변경에만 반응하도록 합니다.
    }, [isLoading])

    const clearQuest = async (questType) => {
      if (!canClick) return
      setCanClick(false)
      const result = await backendApis.giveDailyMissionv2Reward(token, 'PUT', {
        missionType: questType,
      })

      if (result?.data?.success) {
        showRewardToast(result?.data?.rewardAmount, result?.data?.rewardType)
        if (
          questType !== 'clear3' &&
          questType !== 'clear5' &&
          questType !== 'clear7'
        ) {
          setClearedQuestCount(clearedQuestCount + 1)
        }
        setDailyMissionInfo({
          ...dailyMissionInfo?.missionStatus,
          missionStatus: {
            ...dailyMissionInfo?.missionStatus,
            [questType]: new Date(),
          },
        })
        let temp = { ...farmData }
        temp[result?.data?.rewardType] += result?.data?.rewardAmount

        setFarmData(temp)
        setCanClick(true)

        UserStore?.set('pingManager.dailyMission', false)
      }
    }

    const getDailyMissionInfo = async () => {
      const result = await backendApis.getDailyMissionInfov2()
      setDailyMissionInfo(result?.data)
      setClearedQuestCount(result?.data?.clearedCount || 0)
    }

    const getFertilizerBottomSheetInfo = async () => {
      const result = await backendApis.getFertilizerBottomSheetInfo(
        token,
        'GET',
        {
          isFrom: 'dailyMission',
        },
      )
      setFertilizerBottomSheetInfo(result?.fertilizerBottomSheetInfoChecker)
    }

    useEffect(() => {
      setInviteCount(UserStore?.farmData?.inviteList?.length || 0)
    }, [UserStore?.farmData, farmData])

    const getWaterBottomSheetInfo = async () => {
      const result = await backendApis.getWaterBottomSheetInfo(token, 'GET', {
        isFrom: 'dailyMission',
      })
      setSolvedQuizCount(
        result?.waterBottomSheetInfoTimeChecker?.solvedQuizCount?.length,
      )
      getTimeDealQuestCount(
        result,
        result?.waterBottomSheetInfoTimeChecker?.standardHour,
      )

      let logInLogsByTimeForLastTimeDealChecked
      if (!result?.questLogFroTimeDealCheckedAt) {
        logInLogsByTimeForLastTimeDealChecked = dayjs(
          '1970-01-01T06:01:39.853+00:00',
        )
        setLastTimeDealChecked(logInLogsByTimeForLastTimeDealChecked)
      } else {
        logInLogsByTimeForLastTimeDealChecked = dayjs(
          result?.questLogFroTimeDealCheckedAt?.[
            result?.questLogFroTimeDealCheckedAt?.length - 1
          ],
        )
        setLastTimeDealChecked(logInLogsByTimeForLastTimeDealChecked)
      }
    }

    const getMiniGameInfo = async () => {
      let result = await backendApis.getMinigameInfo(token)
      let tempDrawCount = await backendApis.getDrawCount(token)

      setIsWaterBombPlayedToday(
        !result?.data?.minigameAvailabilityInfo?.waterBombPlayAvailable,
      )
      setIsLotteryDrawPlayedToday(
        !result?.data?.minigameAvailabilityInfo?.lotteryDrawPlayAvailable,
      )
      setIsYutnoriPlayedToday(
        !result?.data?.minigameAvailabilityInfo?.yutnoriCompleteRewardAvailable,
      )

      if (tempDrawCount?.drawCount >= 3) {
        setIsLuckyDrawPlayedToday(true)
      }
    }

    useEffect(() => {
      let trueCount = 0

      if (isWaterBombPlayedToday) {
        trueCount++
      }

      if (isLotteryDrawPlayedToday) {
        trueCount++
      }

      if (isLuckyDrawPlayedToday) {
        trueCount++
      }
      if (isYutnoriPlayedToday) {
        trueCount++
      }

      setClearedArcadeCount(trueCount)
    }, [
      isWaterBombPlayedToday,
      isLotteryDrawPlayedToday,
      isLuckyDrawPlayedToday,
      isYutnoriPlayedToday,
    ])

    const getTimeDealQuestCount = (data, standardHour) => {
      let logInLogsByTime
      if (!data?.questLogFroTimeDealCheckedAt) {
        logInLogsByTime = []
      } else {
        logInLogsByTime = data?.questLogFroTimeDealCheckedAt?.filter((x) => {
          let availableTime
          const currentTime = dayjs().startOf('h')
          const currentHour = Number(currentTime.format('HH'))

          if (currentHour < standardHour) {
            availableTime = dayjs()
              .startOf('d')
              .subtract(1, 'd')
              .add(standardHour, 'h')
          } else {
            availableTime = dayjs().startOf('d').add(standardHour, 'h')
          }
          let isAvailable = false
          if (dayjs(x)?.diff(availableTime, 'ms') > 0) {
            isAvailable = true
          }
          return isAvailable
        })
      }
      const logInLogsByTimeSet = new Set(logInLogsByTime).size
      setTimeDealQuestCount(logInLogsByTimeSet)
    }

    useEffect(() => {
      let newDateTime = moment().add(-4, 'hours')
      let newDateFormatted = newDateTime.format('YYYY-MM-DD')
      let storedData = localStorage.getItem('alfarm_giveWaterCount')
      let count = 0

      if (storedData) {
        let parsedData = JSON.parse(storedData)

        if (parsedData.date === newDateFormatted) {
          count = parsedData.count
        }
      }
      setTodayGiveWaterCount(count)

      // 매일 물 준 횟수 카운트 for daily mission v2 end
    }, [])

    useEffect(() => {
      if (farmData) {
        setTodayPurchasedCount(purchaseCountWithinPeriod4Hour())
      }
    }, [farmData])

    useEffect(() => {
      if (!farmData?.solvedQuizHistory) {
        setCanSolveQuiz(true)
      }
      if (farmData?.solvedQuizHistory) {
        if (
          TimeStore.currentDayJS -
            dayjs(
              farmData?.solvedQuizHistory[
                farmData?.solvedQuizHistory?.length - 1
              ]?.solvedAt,
            ) >
          1000 * 60 * 60 * AlfarmAdStore.quizAdTimeIntervalBetweenExposure
        ) {
          setCanSolveQuiz(true)
        }
      }
    }, [])

    const BonusRewardButton3 = () => {
      if (clearedQuestCount < 3) {
        return
      } else if (
        clearedQuestCount >= 3 &&
        dailyMissionInfo &&
        !dailyMissionInfo?.missionStatus['clear3']
      ) {
        return (
          <div
            style={{
              position: 'absolute',
              bottom: '-32.5vw',
              width: '13vw',
              left: '23.5vw',
            }}
            onClick={() => {
              clearQuest('clear3')
            }}
          >
            <img
              src='/dailyMissionV2/bonusReward/bonusButtonReady.png'
              alt=''
            />
          </div>
        )
      } else {
        return (
          <div
            style={{
              position: 'absolute',
              bottom: '-32.5vw',
              width: '13vw',
              left: '23.5vw',
            }}
          >
            <img src='/dailyMissionV2/bonusReward/bonusButtonDone.png' alt='' />
          </div>
        )
      }
    }
    const BonusRewardButton5 = () => {
      if (clearedQuestCount < 5) {
        return
      } else if (
        clearedQuestCount >= 5 &&
        dailyMissionInfo &&
        !dailyMissionInfo?.missionStatus['clear5']
      ) {
        return (
          <div
            style={{
              position: 'absolute',
              bottom: '-32.5vw',
              width: '13vw',
              left: '46vw',
            }}
            onClick={() => {
              clearQuest('clear5')
            }}
          >
            <img
              src='/dailyMissionV2/bonusReward/bonusButtonReady.png'
              alt=''
            />
          </div>
        )
      } else {
        return (
          <div
            style={{
              position: 'absolute',
              bottom: '-32.5vw',
              width: '13vw',
              left: '46vw',
            }}
          >
            <img src='/dailyMissionV2/bonusReward/bonusButtonDone.png' alt='' />
          </div>
        )
      }
    }
    const BonusRewardButton7 = () => {
      if (clearedQuestCount < 7) {
        return
      } else if (
        clearedQuestCount >= 7 &&
        dailyMissionInfo &&
        !dailyMissionInfo?.missionStatus['clear7']
      ) {
        return (
          <div
            style={{
              position: 'absolute',
              bottom: '-32.5vw',
              width: '13vw',
              left: '68.5vw',
            }}
            onClick={() => {
              clearQuest('clear7')
            }}
          >
            <img
              src='/dailyMissionV2/bonusReward/bonusButtonReady.png'
              alt=''
            />
          </div>
        )
      } else {
        return (
          <div
            style={{
              position: 'absolute',
              bottom: '-32.5vw',
              width: '13vw',
              left: '68.5vw',
            }}
          >
            <img src='/dailyMissionV2/bonusReward/bonusButtonDone.png' alt='' />
          </div>
        )
      }
    }

    console.log(trafficAd)
    const questList = [
      {
        type: 'checkin',
        image: '/dailyMissionV2/dailyCheckin.png',
        title: '하루 1번 출석하기',
        activeButton: '/dailyMissionV2/getWater10.png',
        moveButton: '/dailyMissionV2/moveWater10.png',
        clearButton: '/dailyMissionV2/clearButton.png',
        rewardType: 'water',
        rewardAmount: 10,
        clearCount: 1,
        nowCount: dailyMissionInfo?.missionStatus['checkin'] ? 1 : 0,
        onclick: () => {
          clearQuest('checkin')
        },
        canClear: true,
        index: dailyMissionInfo?.missionStatus['checkin'] ? 100 : 0,
      },
      {
        type: 'giveWater',
        image: '/dailyMissionV2/giveWater.png',
        title: '작물에 물 20번 주기',
        activeButton: '/dailyMissionV2/getWater10.png',
        moveButton: '/dailyMissionV2/moveWater10.png',
        clearButton: '/dailyMissionV2/clearButton.png',
        rewardType: 'water',
        rewardAmount: 10,
        clearCount: 20,
        nowCount: todayGiveWaterCount,
        onclick: () => {
          clearQuest('giveWater')
        },
        actionClick: () => {
          ModalStore?.setIsModalOpen('basic')
          giveWaterBasket()
        },
        canClear: todayGiveWaterCount >= 20,
        index: dailyMissionInfo?.missionStatus['giveWater'] ? 101 : 1,
      },
      {
        type: 'browsing60',
        image: '/dailyMissionV2/browsingItem.png',
        title: '상품 60초 구경하기',
        activeButton: '/dailyMissionV2/getWater20.png',
        moveButton: '/dailyMissionV2/moveWater20.png',
        clearButton: '/dailyMissionV2/clearButton.png',
        rewardType: 'water',
        rewardAmount: 20,
        clearCount: 1,
        nowCount: !fertilizerBottomSheetInfo?.recommendQuestAvailable ? 1 : 0,
        onclick: () => {
          clearQuest('browsing60')
        },
        actionClick: () => {
          const questRecommend = {
            screen: 'AlFarmQuestLinkRecommendItem',
          }
          window.location.href = `#questRecommend.${JSON.stringify(
            questRecommend,
          )}`
        },
        canClear: !fertilizerBottomSheetInfo?.recommendQuestAvailable,
        index: dailyMissionInfo?.missionStatus['browsing60'] ? 102 : 2,
      },
      {
        type: 'timeDealBrowsing',
        image: '/dailyMissionV2/timeDeal.png',
        title: '타임특가 2번 구경하기',
        activeButton: '/dailyMissionV2/getFert1.png',
        moveButton: '/dailyMissionV2/moveFert1.png',
        clearButton: '/dailyMissionV2/clearButton.png',
        rewardType: 'ferterlizer',
        rewardAmount: 1,
        clearCount: 2,
        nowCount: timeDealQuestCount,
        onclick: () => {
          clearQuest('timeDealBrowsing')
        },
        actionClick: () => {
          const questTimeDeal = {
            screen: 'AlFarmQuestLinkTimeDeal',
            timeStamp: new Date(new Date().getTime() + 1000 * 30),
          }

          window.location.href = `#questTimeDeal.${JSON.stringify(
            questTimeDeal,
          )}`
        },
        cooltime:
          farmData?.questLogFroTimeDealCheckedAt &&
          timeDealQuestCount < 2 &&
          TimeStore.currentDayJS - lastTimeDealChecked < 1000 * 60 * 60
            ? new Date(
                new Date(
                  farmData?.questLogFroTimeDealCheckedAt[
                    farmData?.questLogFroTimeDealCheckedAt?.length - 1
                  ],
                ).getTime() +
                  1000 * 60 * 60,
              )
            : false,
        canClear: timeDealQuestCount >= 2,
        index: dailyMissionInfo?.missionStatus['timeDealBrowsing'] ? 103 : 3,
      },
      {
        type: 'arcade',
        image: '/dailyMissionV2/arcade.png',
        title: '아케이드 3회 플레이 하기',
        activeButton: '/dailyMissionV2/getFert1.png',
        moveButton: '/dailyMissionV2/moveFert1.png',
        clearButton: '/dailyMissionV2/clearButton.png',
        rewardType: 'fertilizer',
        rewardAmount: 1,
        clearCount: 3,
        nowCount: clearedArcadeCount,
        onclick: () => {
          clearQuest('arcade')
        },
        actionClick: () => {
          ModalStore.setIsModalOpen('basic')
          BottomSheetStore.setIsbottomSheetOpen('miniGame')
        },
        canClear: clearedArcadeCount >= 3,
        index: dailyMissionInfo?.missionStatus['arcade'] ? 104 : 4,
      },
      {
        type: 'quiz',
        image: '/dailyMissionV2/invite.png',
        title: '매일 초대 1번 하기',
        activeButton: '/dailyMissionV2/getWater20.png',
        moveButton: '/dailyMissionV2/moveWater20.png',
        clearButton: '/dailyMissionV2/clearButton.png',
        rewardType: 'water',
        rewardAmount: 20,
        clearCount: 1,
        nowCount: inviteCount,
        onclick: () => {
          clearQuest('quiz')
        },
        actionClick: () => {
          if (inviteCount < 1) {
            ModalStore.setIsModalOpen('dailyInvite')
          }
        },
        canClear: inviteCount >= 1,
        index: dailyMissionInfo?.missionStatus['quiz'] ? 105 : 5,
      },
      {
        type: 'purchase',
        image: '/dailyMissionV2/purchase.png',
        title: '올팜에서 상품 1개 구매하기',
        activeButton: '/dailyMissionV2/getFertSet2.png',
        moveButton: '/dailyMissionV2/moveFertSet2.png',
        clearButton: '/dailyMissionV2/clearButton.png',
        rewardType: 'fertilizerSet',
        rewardAmount: 1,
        clearCount: 1,
        nowCount: todayPurchasedCount,
        onclick: () => {
          clearQuest('purchase')
        },
        actionClick: () => {
          const purchaseRewardFert = {
            screen: 'AlfarmPurchaseRewardScreen',
            rewardType: 'fert',
          }
          window.location.href = `#purchaseRewardFert.${JSON.stringify(
            purchaseRewardFert,
          )}`
        },
        canClear: todayPurchasedCount >= 1,
        index: dailyMissionInfo?.missionStatus['purchase'] ? 106 : 6,
      },
    ]

    const sortedQuestList = questList.sort((a, b) => a.index - b.index)

    return (
      <div className='overflow-y-hidden h-[100vh]'>
        <ModalFrame
          closeButtonStyle={styles.closeButton}
          modalContentStyle={styles.modalContent}
        >
          {!isLoading && (
            <div className='mt-[5vw] h-[85vw] overflow-y-scroll'>
              {sortedQuestList.map((quest, index) => {
                return (
                  <div
                    className='flex flex-row items-center w-[95%] h-[16vw] mb-[2vw] bg-[#F7E7C8] rounded-[5vw] ml-[2.5vw]'
                    key={index}
                  >
                    <img
                      className='w-[12vw] ml-[3vw] rounded-[12px]'
                      src={quest?.image}
                      alt=''
                    />
                    <div className='ml-[3vw] w-[42vw] flex flex-col justify-between h-[65%] mr-[3vw]'>
                      <div
                        className='truncate'
                        style={{
                          color: '#803F00',
                          fontSize:
                            quest?.title?.length >= 15 ? '3.8vw' : '4vw',
                        }}
                      >
                        {quest?.title}
                      </div>
                      <div
                        style={{
                          background: '#FFF5D7',
                          boxShadow:
                            '0vw 0.5vw 0vw 0vw #FFFBEF inset, 0vw 0.5vw 0vw 0vw #E8D1A3',
                          borderRadius: '100vw',
                          width: '100%',
                          color: '#C1650A',
                          textAlign: 'center',
                          height: '4vw',
                        }}
                      >
                        <div
                          style={{
                            fontSize: '3.5vw',
                            background: '#FFB600',
                            boxShadow:
                              '0vw 0.3vw 0vw 0vw #FFD15F inset, 0vw 0.3vw 0vw 0vw #FFA800',
                            borderRadius: '100vw',
                            width: `${Math.min(
                              (quest?.nowCount / quest?.clearCount) * 100,
                              100,
                            )}%`,
                            height: '4vw',
                          }}
                        >
                          <div
                            style={{
                              width: '40vw',
                              color: '#C1650A',
                            }}
                          >
                            {quest?.nowCount}/{quest?.clearCount}
                          </div>
                        </div>
                      </div>
                    </div>
                    <MissionButton
                      setLastTimeDealChecked={setLastTimeDealChecked}
                      dailyMissionInfo={dailyMissionInfo}
                      quest={quest}
                    />
                  </div>
                )
              })}
            </div>
          )}

          <div className='flex flex-col absolute top-[-29vw] left-[11vw] justify-center items-center'>
            <img
              src='/dailyMissionV2/headerImage.png'
              alt=''
              style={{
                position: 'relative',
                top: '3vw',
                width: '15vw',
              }}
            />
            <img
              src='/dailyMissionV2/header.png'
              alt=''
              style={{
                width: '65vw',
              }}
            />
          </div>
          <img
            src='/dailyMissionV2/bonusReward/banner.png'
            alt=''
            style={{
              position: 'absolute',
              bottom: '-35vw',
              width: '100vw',
              left: '0%',
            }}
          />
          <img
            src={`/dailyMissionV2/bonusReward/gauge${clearedQuestCount}.png`}
            alt=''
            style={{
              position: 'absolute',
              bottom: '-31vw',
              width: '73vw',
              left: '5vw',
            }}
          />
          <div
            style={{
              position: 'absolute',
              bottom: '-14vw',
              width: '75vw',
              left: '5vw',
              color: '#803F00',
              fontSize: '4vw',
            }}
          >
            오늘 완료: {clearedQuestCount}개
          </div>
          <BonusRewardButton3 />
          <BonusRewardButton5 />
          <BonusRewardButton7 />
          {!isLoading && trafficAd.length > 0 && (
            <div className='w-[100vw] relative top-[40vw] left-[-7vw] flex justify-center items-center px-[11vw]'>
              <Banner bannerAd={trafficAd[0]} showAd />
            </div>
          )}
        </ModalFrame>
      </div>
    )
  },
)

const Banner = ({ bannerAd, showAd = false }) => {
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    backendApis.postLogBannerAdEvent({
      eventType: 'impression',
      adId: bannerAd.adId,
      adType: bannerAd.adType,
      placement: bannerAd.placement || BANNER_AD_PLACEMENT,
      product: bannerAd.product || TrafficAdStore.product,
      adSource: bannerAd.adSource,
      reward: 0,
    })
    if (
      bannerAd.adSource === 'buzzvil' &&
      bannerAd.adType === 'cpm' &&
      bannerAd.impressionUrls
    ) {
      bannerAd.impressionUrls.forEach((impressionUrl) => {
        fetch(impressionUrl).catch((e) => console.error(e))
      })
    }
  }, [bannerAd])

  const handleClickBanner = () => {
    if (bannerAd.actionLink) {
      appBridgeSender.goExternalBrowser(bannerAd.actionLink)
      backendApis.postLogBannerAdEvent({
        eventType: 'click',
        adId: bannerAd.adId,
        adType: bannerAd.adType,
        placement: bannerAd.placement || BANNER_AD_PLACEMENT,
        product: bannerAd.product || TrafficAdStore.product,
        adSource: bannerAd.adSource,
        reward: 0,
      })
    }
  }

  const handleImageLoad = () => {
    setImageLoaded(true)
  }

  return (
    <div
      className='relative w-full bg-grey10 rounded-[12px] overflow-hidden bg-white'
      style={{ paddingBottom: `${(1 / BANNER_ASPECT_RATIO) * 100}%` }}
      onClick={handleClickBanner}
    >
      {bannerAd.adSource !== 'internal' && (
        <div className='flex flex-col flex-1 justify-center h-full absolute pl-[21px] truncate'>
          <span className='text-[#71737C] text-[13px] font-medium truncate max-w-[40vw]'>
            {bannerAd.subTitle}
          </span>
          {/* <AlwayzSpace height={5} /> */}
          <div className='text-[#464953] text-[16px] font-bold truncate max-w-[40vw]'>
            {bannerAd.title}
          </div>
        </div>
      )}

      <img
        src={bannerAd.bannerImg}
        alt='Banner'
        className='absolute top-0 right-0 h-full w-auto object-cover'
        onLoad={handleImageLoad}
        style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.3s' }}
      />
      {showAd && (
        <div className='absolute bottom-[11px] right-[15px] w-6 h-6 bg-[rgba(255, 255, 255, 0.30)] rounded-full flex items-center justify-center'>
          <span className='text-[#71737C] text-[10px]'>광고</span>
        </div>
      )}
    </div>
  )
}

const styles = {
  closeButton: {
    position: 'absolute',
    top: '-30vw',
    right: '0vw',
    width: '8vw',
  },
  modalContent: {
    width: '90%',
    height: '100vw',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -60%)',
    position: 'relative',
    background: '#F9F0DE',
    border: '2vw solid #9EBF00',
    borderRadius: '5vw',
  },
}
export default DailyMissionModalV2
