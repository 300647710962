import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../../utils/backendApis'
import commaNumber from 'comma-number'
import indexStore from '../../../store/indexStore'
import ToastStore from 'store/ToastStore'
import moment from 'moment'
import timeChecker from 'utils/timeChecker'

const TrendQuizModal = observer(
  ({ token, farmData, setFarmData, setTrendQuizOn, showRewardToast }) => {
    const { ModalStore, UserStore } = indexStore()
    const [isLoading, setIsLoading] = useState(true)
    const [quizData, setQuizData] = useState({})
    const [isFocused, setIsFocused] = useState(false)
    const [answer, setAnswer] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [resultData, setResultData] = useState({})

    const inputNameRef = React.useRef()

    useEffect(() => {
      if (!timeChecker(farmData?.lastTrendQuizSolvedAt)) {
        setResultData({
          title: '퀴즈는 하루에 한번만 가능해요',
          message: `다음에 다시 풀 수 있어요`,
          emoji: '😅',
          message2: `우주 최저가 상품을 구매해보세요`,
          message3: `물 1,000g, 고급비료 5개를 드려요`,
        })
        setIsSubmitted(true)
      }
    }, [])
    useEffect(() => {
      const fetch = async () => {
        let res = await backendApis.trendQuiz()
        if (res?.data?.msg === 'success') {
          setQuizData(res?.data?.res)
          setIsLoading(false)
        }
      }
      fetch()
    }, [])

    const submitAnswer = async () => {
      if (inputNameRef.current?.length < 1) {
        ToastStore.toastOn({
          type: 'emoji',
          message: '정답을 입력해주세요',
          emoji: '😅',
          duration: 3000,
        })
        return
      }
      setIsSubmitted(true)
      setIsFocused(false)

      let res = await backendApis.trendQuizSubmitAnswer(token, 'PUT', {
        answer: inputNameRef.current,
      })

      if (res?.data?.status === 200) {
        let tempWater = res?.data?.rewardAmount

        // ModalStore.setIsModalOpen("basic");
        setFarmData((prev) => {
          let temp = { ...prev }
          if (UserStore.waterExceeded) {
            temp.water += Math.round(res?.data?.rewardAmount * 0.5)
            tempWater = Math.round(res?.data?.rewardAmount * 0.5)
          } else {
            temp.water += res?.data?.rewardAmount
          }
          temp.lastTrendQuizSolvedAt = new Date()
          return temp
        })
        // setTrendQuizOn(false);
        setResultData({
          title: '정답이에요',
          emoji: '🎉',
          message: `물 ${commaNumber(tempWater)}g를 획득했어요`,
          message2: `우주 최저가 상품을 구매하면`,
          message3: `물 1,000g, 고급비료 5개를 드려요`,
        })
      } else {
        setResultData({
          title: '정답을 맞추지 못했어요',
          emoji: '😅',
          message: `다음에 다시 도전해보세요`,
          message2: `우주 최저가 상품을 구매하면`,
          message3: `물 1,000g, 고급비료 5개를 드려요`,
        })
      }
      setFarmData((prev) => {
        let temp = { ...prev }
        temp.lastTrendQuizSolvedAt = new Date()
        return temp
      })
    }

    const ModalBody = () => {
      return (
        <div
          style={{
            ...styles.modalBody,
          }}
        >
          <div
            style={{
              marginBottom: '-2vw',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                fontFamily: 'maplestory',
                fontSize: '4.5vw',
                color: 'black',
                paddingBottom: '1vw',
                textAlign: 'center',
                width: '100%',
              }}
            >
              문제를 맞추고 물을 받아보세요!
            </div>
          </div>
          <div>
            <div
              style={{
                borderRadius: '4vw',
                background: '#FFEED1',
                fontFamily: 'maplestory',
                fontSize: '4vw',
                color: '#402C24',
                wordBreak: 'keep-all',
                wordWrap: 'break-word',
                width: '100%',
                textAlign: 'center',
                marginBottom: '2vw',
                marginTop: '2vw',
                height: '32vw',
                overflowY: 'scroll',
                display: 'flex',
                paddingLeft: '2vw',
                textAlign: 'left',
                alignItems: 'center',
                justifyContent:
                  quizData?.question?.length < 70 ? 'flex-start' : 'center',
              }}
            >
              {`Q. ${quizData?.question}`}
            </div>
            <input
              style={{
                alignItems: 'center',
                width: '100%',
                height: '10vw',
                paddingRight: '4vw',
                paddingLeft: '4vw',
                borderRadius: '2vw',
                color: 'black',
                fontFamily: 'maplestory',
                fontSize: '3.5vw',
                zIndex: 5,
              }}
              type='text'
              placeholder={`정답을 입력해주세요`}
              // value={answer}
              autoFocus={isFocused}
              onFocus={() => {
                setIsFocused(true)
              }}
              onBlur={() => {
                setIsFocused(false)
              }}
              // onChange={handleInputChange}
              value={inputNameRef.current}
              onChange={(e) => {
                inputNameRef.current = e.target.value
              }}
            />
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',

              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <button
              style={{
                marginRight: 8,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => {
                const prop = {
                  screen: 'MainStackDItemScreen',
                  prop: {
                    itemId: quizData?.itemId,
                    isFrom: 'Alfarm_trendQuiz',
                    enteringComponent: 'Alfarm_trendQuiz',
                    initialComponent: 'Alfarm_trendQuiz',
                    imageSource:
                      'https://assets.ilevit.com/3908b722-8218-45e7-aacf-c454c7be7639.png',
                  },
                }
                window.location.href = `#navigate.${JSON.stringify(prop)}`
              }}
            >
              <img
                src='/trendQuiz/hint.png'
                alt='abc'
                style={{ width: '13vw' }}
              />
            </button>
            <button
              onClick={() => {
                submitAnswer()
              }}
              style={{
                flex: 1,
                alignContent: 'center',
                marginLeft: '0vw',
                width: '100%',
                height: '12vw',
                borderRadius: '6vw',
                color: 'black',
                backgroundColor: '#9FDA54',
                fontFamily: 'maplestory',
                fontSize: '5vw',
                zIndex: 2000,
                border: '2px solid #fff',
              }}
            >
              제출하기
            </button>
          </div>
        </div>
      )
    }

    const ModalFrame = () => {
      return (
        <>
          <div
            style={{
              ...styles.background,
            }}
          />
          <div
            style={{
              ...styles.modalFrame,
              backgroundImage: 'url(../trendQuiz/background.png)',
              top: isFocused ? '77%' : '50%',
            }}
          >
            <button
              style={{
                width: '8vw',
                top: '3vw',
                right: '10vw',
                zIndex: 102,
                position: 'absolute',
                cursor: 'pointer',
              }}
              onClick={() => {
                setIsFocused(false)
                ModalStore.setIsModalOpen('basic')
              }}
            >
              <img src='/icon/modalCloseButton.png' alt='' />
            </button>
            {isSubmitted ? (
              <div style={styles.modalBody}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      color: 'black',
                      marginBottom: 8,
                      textAlign: 'center',
                      fontSize: '4vw',
                    }}
                  >
                    {resultData?.title} <br /> {resultData?.message}
                  </div>
                  <div style={{ fontSize: '13vw', marginTop: '2vw' }}>
                    {resultData?.emoji}
                  </div>
                  <div
                    style={{
                      color: 'black',
                      textAlign: 'center',
                      width: '100%',
                      marginTop: '4vw',
                      fontSize: '4vw',
                    }}
                  >
                    {resultData?.message2} <br />
                    {resultData?.message3}
                  </div>
                </div>
                <div
                  style={{
                    background:
                      'linear-gradient(180deg, rgba(247,180,79,1) 0%, rgba(255,192,97,1) 50%, rgba(247,180,79,1) 100%)',
                    width: '60vw',
                    height: '14vw',
                    borderRadius: 99,
                    color: 'black',
                    fontFamily: 'Maplestory',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '5vw',
                    marginBottom: 16,
                  }}
                  onClick={() => {
                    const prop = {
                      screen: 'MainStackDItemScreen',
                      prop: {
                        itemId: quizData?.itemId,
                        isFrom: 'Alfarm_trendQuiz',
                        enteringComponent: 'Alfarm_trendQuiz',
                        initialComponent: 'Alfarm_trendQuiz',
                        imageSource:
                          'https://assets.ilevit.com/9bd53cfe-4ef9-47c8-88e8-bc228934133c.png',
                      },
                    }
                    window.location.href = `#navigate.${JSON.stringify(prop)}`
                    ModalStore.setIsModalOpen('basic')
                  }}
                >
                  우주 최저가 상품 보기
                </div>
              </div>
            ) : (
              <ModalBody />
            )}
          </div>
        </>
      )
    }

    return <ModalFrame />
  },
)

const styles = {
  background: {
    flex: 1,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 5000,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  modalFrame: {
    width: '92vw',
    height: '110vw',
    borderRadius: '4vw',
    left: '50%',
    transform: 'translate(-50%, -55%)',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 5001,
    padding: '4vw',
    paddingTop: '20vw',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  modalBody: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '20vw',
    alignItems: 'center',
    zIndex: 100,
    paddingLeft: '3vw',
    paddingRight: '3vw',
    width: '80%',
    height: '100%',
    justifyContent: 'space-between',
  },
}

export default TrendQuizModal
