import React, { useState, useEffect, useRef } from 'react'

import { observer } from 'mobx-react-lite'
import UserStore from 'store/UserStore'
import ModalStore from 'store/ModalStore'
import backendApis from 'utils/backendApis'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import BottomSheetStore from 'store/BottomSheetStore'
import TimeStore from 'store/TimeStore'
import moment from 'moment'

const PetBottomSheet = observer(({}) => {
  const [petInfo, setPetInfo] = useState([])
  const [styleSelect, setStyleSelect] = useState(true)

  const throttleRef = useRef({})

  useEffect(() => {
    const fetchPetData = async () => {
      const result = await backendApis.fetchPetInfoData()
      if (result?.status === 200) {
        setPetInfo(result?.data)
        if (!result?.petData) {
          setTimeout(() => {
            ModalStore?.setIsModalOpen('trialPet', {})
          }, 1000)
          UserStore?.set('farmData', {
            ...UserStore?.farmData,
            petData: {
              userId: UserStore?.farmData?.userId,
              inviteHistory: [],
              createdAt: TimeStore.currentMoment?.toDate(),
            },
          })
        }
      }
    }
    fetchPetData()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setStyleSelect(false)
    }, 650)

    const headerRef = document.querySelector('[data-rsbs-header="true"]')
    const headerStyle = {
      height: headerRef.style.height,
      marginTop: headerRef.style.marginTop,
      boxShadow: headerRef.style.boxShadow,
    }
    headerRef.style.height = '10vw'
    headerRef.style.marginTop = '-50vw'
    headerRef.style.boxShadow = 'none'

    const contentRef = document.querySelector('[data-rsbs-content="true"]')
    const contentStyle = {
      marginTop: contentRef.style.marginTop,
    }

    contentRef.style.marginTop = '50vw'

    return () => {
      headerRef.style.height = headerStyle.height
      headerRef.style.marginTop = headerStyle.marginTop
      headerRef.style.boxShadow = headerStyle.boxShadow
      contentRef.style.marginTop = contentStyle.marginTop
    }
  }, [styleSelect, BottomSheetStore?.isBottomSheetOpen])

  const closeBottomSheet = () => {
    BottomSheetStore.setIsbottomSheetOpen('basic')
  }

  const shareKakao = () => {
    const inviteData = {
      shareType: 'AlfarmPetInvite',
      title: '[올팜] 함께 올팜 작물을 키워봐요!',
      description: '내가 키운 작물을 무료로 받아보세요!',
      bannerImage:
        'https://assets.ilevit.com/34ed5826-d1bd-4822-940f-02274f0a9042.png',
    }
    window.location.href = `#openAlfarmRemovedDependencyInviteModal.${JSON.stringify(
      inviteData,
    )}`
    AlfarmEventLogger({
      throttleRef,
      locationType: 'petBottomsheet',
      locationName: 'petInviteButton',
      eventType: 'click',
      collection: 'UserAlfarmClickLog',
    })
  }

  const PetItem = observer(({ petIndex = 1, info = {} }) => {
    if (
      info?.requireInviteCount <=
      UserStore.farmData?.petData?.inviteHistory?.length
    ) {
      return (
        <div
          style={{
            width: '30%',
            height: '30%',
            padding: '8px',
            display: 'flex',
            flexDirection: 'column',
          }}
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            ModalStore.setIsModalOpen('petDetail', { ...info, petIndex })
          }}
        >
          <img alt='pet main' src={`/pet/bottomsheet/earned/${petIndex}.png`} />
          <div
            style={{
              marginTop: '4px',
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRadius: '8px',
              color: '#C0C3CA',
              backgroundColor: '#E6E8ED',
              fontSize: '4vw',
            }}
          >
            {/* <img
              style={{
                width: "3.5vw",
                marginRight: "4px",
              }}
              alt="paw"
              src="/pet/bottomsheet/paw.png"
            /> */}
            획득 완료
          </div>
        </div>
      )
    }

    return (
      <div
        style={{
          width: '30%',
          height: '30%',
          padding: '8px',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          ModalStore.setIsModalOpen('petDetail', { ...info, petIndex })
        }}
      >
        <img alt='pet main' src={`/pet/bottomsheet/yet/${petIndex}.png`} />
        <img
          style={{
            position: 'absolute',
            width: '4vw',
            right: '1vw',
            top: '1vw',
          }}
          alt='!'
          src='/icon/squareExclamationMark.png'
        />
        <div
          style={{
            marginTop: '4px',
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '4px',
            paddingBottom: '4px',
            borderRadius: '8px',
            color: '#979DAD',
            backgroundColor: '#E6E8ED',
          }}
        >
          <img
            style={{
              width: '3.5vw',
              marginRight: '4px',
            }}
            alt='paw'
            src='/pet/bottomsheet/paw.png'
          />
          {`${UserStore.farmData?.petData?.inviteHistory?.length ?? 0} / ${
            info?.requireInviteCount
          }`}
        </div>
      </div>
    )
  })

  return (
    <div
      className='max-h-[70vh]'
      style={{
        paddingTop: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        overflowY: 'scroll',
      }}
    >
      {/* 영구적용 알려주는 이미지 */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          zIndex: 3,
          position: 'absolute',
          top: '-30vw',
          width: '100%',
        }}
      >
        <img
          src='/pet/infoImage.png'
          alt=''
          style={{
            width: '70vw',
            position: 'absolute',
            top: '-16vw',
          }}
        />
      </div>
      <button
        style={{
          width: '8vw',
          top: '3vw',
          right: '8vw',
          position: 'absolute',
          zIndex: 11,
        }}
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          closeBottomSheet()
        }}
      >
        <img
          style={{
            position: 'absolute',
            zIndex: 1,
          }}
          src='/icon/close.png'
          alt=''
        />
      </button>
      <div
        style={{
          paddingLeft: '16px',
          paddingRight: '16px',
          paddingTop: '8px',
          paddingBottom: '8px',
          borderRadius: '99px',
          backgroundColor: '#E7F6D8',
          marginBottom: '8px',
        }}
      >
        <div
          style={{
            color: '#69B41D',
            fontWeight: 'bold',
          }}
        >{`초대한 새친구 수: ${
          UserStore?.farmData?.petData?.inviteHistory?.length ?? 0
        }명`}</div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexWrap: 'wrap',
          width: '100%',
          paddingLeft: '4vw',
          paddingRight: '4vw',
          marginBottom: '4vw',
        }}
      >
        {petInfo?.map((info, index) => (
          <PetItem key={Math.random()} petIndex={index} info={info} />
        ))}
      </div>
      {(UserStore?.farmData?.petData?.inviteHistory?.length ?? 0) < 15 && (
        <div
          style={{
            width: '100%',
            padding: '8px',
            paddingTop: '8px',
          }}
        >
          <div
            style={{
              color: '#818181',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '4px',
              fontSize: '3.6vw',
            }}
          >
            새친구를 초대하면 귀여운 햄스터 펫을 순서대로 받아요!
          </div>
          <div
            style={{
              color: '#818181',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '8px',
              fontSize: '3.6vw',
            }}
          >
            *무료체험 펫은 펫효과가 적용되지 않아요
          </div>
          <div
            style={{
              width: '100%',
              paddingLeft: '10%',
              paddingRight: '10%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '4vw',
            }}
          >
            <button
              // className="scaleUp"
              style={{
                width: '100%',
                paddingTop: '4vw',
                paddingBottom: '4vw',
                // height: "20vw",
                color: 'black',
                fontFamily: 'maplestory',
                fontWeight: 'bold',
                fontSize: '6vw',
                borderRadius: '99vw',
                backgroundColor: '#FFCA28',
              }}
              onClick={() => {
                shareKakao()
              }}
            >
              새친구 초대하기
            </button>
          </div>
        </div>
      )}
    </div>
  )
})
export default PetBottomSheet
