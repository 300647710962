import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import ModalStore from '../../../store/ModalStore'
import moment from 'moment'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import useCheckCodePushVersion from 'utils/customHooks/useConvertCodePushVersion'
import UserStore from 'store/UserStore'

const AlfarmBrandBannerDealModal = observer(
  ({ token, farmData, setFarmData }) => {
    const [modalImage, setModalImage] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const throttleRef = useRef({})
    const [isButtonPressed, setIsButtonPressed] = useState(false)
    const [showTextBox, setShowTextBox] = useState(false)
    const codePushVersionAvailable = useCheckCodePushVersion()

    useEffect(() => {
      if (!farmData?.browsingBannerAdAt) {
        setShowTextBox(true)
      }
      if (
        farmData?.browsingBannerAdAt &&
        moment(farmData?.browsingBannerAdAt).format('YYYY-MM-DD') !==
          moment().format('YYYY-MM-DD')
      ) {
        setShowTextBox(true)
      }
    }, [farmData?.browsingBannerAdAt])

    useEffect(() => {
      setModalImage(
        '/icon/alfarmBrandBannerDeal/BrandBannerDealEntryModalBackground.png',
      )
    }, [])
    useEffect(() => {
      if (modalImage) {
        setIsLoading(false)
      }
    }, [modalImage])
    return (
      <>
        {!isLoading && (
          <div
            style={{
              flex: 1,
              position: 'fixed',
              left: 0,
              top: 0,
              zIndex: 5004,
              width: '100%',
              height: '100vh',
              backgroundColor: 'rgba(0,0,0,0.8)',
            }}
          >
            <div
              style={{
                width: '100%',
                transform: 'translate(-50%, -50%)',
                left: '50%',
                top: '50%',
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {/* 30초 보면 물 버튼 */}
              {codePushVersionAvailable(
                UserStore?.codePushVersionFromAlwayzApp,
                '4.8.57',
              ) &&
                showTextBox && (
                  <img
                    style={{
                      zIndex: 101,
                      position: 'absolute',
                      width: '40%',
                      bottom: '19vw',
                      marginLeft: '35%',
                    }}
                    src='/personalizedShop/textBubble.png'
                    alt=''
                  />
                )}
              <button
                style={{
                  width: '10%',
                  top: '4%',
                  right: '10%',
                  position: 'absolute',
                  zIndex: 1113,
                }}
                onClick={() => {
                  ModalStore.setIsModalOpen('basic')
                }}
              >
                <img
                  style={{
                    position: 'absolute',
                    zIndex: 101,
                  }}
                  src='/icon/modalCloseButton.png'
                  alt=''
                />
              </button>
              <img src={modalImage} alt='abc' style={{ width: '80vw' }} />
              <div
                style={{
                  background: isButtonPressed ? '#F7C82A' : '#F7C82A',
                  boxShadow: isButtonPressed
                    ? 'inset 3px 3px 4px rgba(79, 19, 0, 0.15)'
                    : '',
                  width: '60vw',
                  height: '14vw',
                  borderRadius: 99,
                  color: 'black',
                  fontFamily: 'maplestory',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '4.5vw',
                  position: 'absolute',
                  bottom: '8vw',
                  left: '20vw',
                }}
                // 오늘의특가 진입 분기
                onClick={() => {
                  AlfarmEventLogger({
                    throttleRef,
                    locationType: 'modal',
                    locationName: ModalStore.isModalOpen,
                    eventType: 'click',
                    eventName: `cta`,
                    data: {},
                    collection: 'UserAlfarmClickLog',
                  })

                  const checkCodePushVersion = codePushVersionAvailable(
                    UserStore?.codePushVersionFromAlwayzApp,
                    '6.8.29',
                  )

                  if (checkCodePushVersion) {
                    const data = {
                      screen: 'WebviewScreen',
                      params: {
                        enteringComponent: 'Alfarm_brandAd',
                        timer: 0.5,
                        isCleared: !showTextBox,
                        reward: '고급비료 5개, 물 1000g',
                        title: '올팜 브랜드 초특가',
                        missionText: '초 구경하면 물 20g을 받아요',
                        webviewPrefixType: 'shop',
                        url: '/deals/main-banner'
                      }
                    }
  
                    window.location.href = `#generalNavigator.${encodeURIComponent(
                      JSON.stringify(data),
                    )}`
                    ModalStore.setIsModalOpen('basic')
                    return;
                  }

                  const data = {
                    enteringComponent: 'Alfarm_brandAd',
                    timer: 0.5,
                    isCleared: !showTextBox,
                    reward: '물 20g',
                    missionText: '초 구경하면 물 20g을 받아요',
                  }
                  window.location.href = `#AlfarmBrandDealBannerListScreen.${encodeURIComponent(
                    JSON.stringify(data),
                  )}`
                  ModalStore.setIsModalOpen('basic')
                }}
                onPointerDown={() => {
                  setIsButtonPressed(true)
                }}
                onPointerCancel={() => {
                  setIsButtonPressed(false)
                }}
                onPointerUp={() => {
                  setIsButtonPressed(false)
                }}
              >
                상품 구매하고 혜택 받기
              </div>
            </div>
          </div>
        )}
      </>
    )
  },
)

export default AlfarmBrandBannerDealModal
