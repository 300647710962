import ModalStore from '../store/ModalStore'

// 유효한 광고 세트 배열 가져오기
const sendToCPMPurchaseScreen = async (cpmItems, props) => {
  window.location.href = `#generalNavigator.${JSON.stringify({
    screen: 'AlfarmCPMPurchaseScreen',
    params: {
      itemInfoList: cpmItems,
      ...props,
    },
  })}`
  ModalStore.setIsModalOpen('basic')
}

export default sendToCPMPurchaseScreen
