import { observable } from 'mobx'
import { toast } from 'react-hot-toast'

const ToastStore = observable({
  toastBlock: false,
  type: 'error',
  duration: 2500,
  message: '다시 시도해주세요',
  timeOutRef: null,
  backgroundOn: false,

  toastOn({ type, message, duration = 2000, emoji }) {
    toast.remove()

    this.backgroundOn = true

    if (type === 'error') {
      toast.error(message, { duration })
    } else if (type === 'success') {
      toast.success(message, { duration })
    } else if (type === 'emoji') {
      toast(message, { icon: emoji, duration })
    }
    setTimeout(() => {
      this.backgroundOn = false
    }, duration)
  },
})

export default ToastStore
