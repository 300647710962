const members = {
  '61d57a10b06b55089bca08b6_fste0829': 'a', // development
  '62271be8b3ef552fb2dd424d_fste0829': 'b', // production
  '621e30c8d729ee3fe71f8ff3_spbb1214': 'a', // development
  '6284a17afadd635b552885d8_spbb1214': 'a', // production
  '627d10bfd430af8ffc940c7a_spbb1214': 'a', // production
  '626ec2b1a66592f5c1603f5f_spbb1214': 'a', // production
  '6243835a9d22e940cecde8a5_spbb1214': 'a', // production
  '61a5a1313a1787613ebc7f2f_spbb1214': 'a', // production
  '61b9c6058b53250941198e86_spbb1214': 'a', // production
  '6284a17afadd635b552885d8_nbtb1213': 'b', // production
  '61a5a1313a1787613ebc7f2f_name1212': 'a', //production
  '6243835a9d22e940cecde8a5_side0125': 'a', //production
  '63e3610808dc1ca77727b0c9_side0125': 'a', //dev
  '61a5a1313a1787613ebc7f2f_side0125': 'a', //production
  '627d10bfd430af8ffc940c7a_side0125': 'a', //production
  '61f3125a1a34722dcb920a52_side0125': 'a', //production
  '626ec2b1a66592f5c1603f5f_side0125': 'a', //production
  '634a0462f5ad9c7a1dd078b3_side0125': 'a', //production
  '61b9c6058b53250941198e86_side0125': 'a', //production
  '61b9c46250e8ea095c083263_side0125': 'a', //production
  '61c30e983d58c56b3f70f9b9_side0125': 'a', //production
  '614b747d698b43323956cf2c_side0125': 'a', //production
  '61eced77d070bd674e99a1be_side0125': 'a', //production
  '62271be8b3ef552fb2dd424d_side0125': 'a', //production
  // "627e2914c0a44f244574114e_seletFarmReview0203": "a", //production
  // "627d10bfd430af8ffc940c7a_0203seletFarmReview": "a", //production
  '627e2914c0a44f244574114e_0205defa': 'a', //production
  '627d10bfd430af8ffc940c7a_0205defa': 'a', //production
  '63e3610808dc1ca77727b0c9_wlcm0206': 'a',
  '63e3610808dc1ca77727b0c9_spls0206': 'b',
  '63e3610808dc1ca77727b0c9_skip0208': 'b',
  // "627e2914c0a44f244574114e_nbtb0215": "a",
  '63e3610808dc1ca77727b0c9_tmcp0219': 'b',
  '61a5a1313a1787613ebc7f2f_0219ksit': 'a',
  '6284a17afadd635b552885d8_0219ksit': 'b',
  '627e2914c0a44f244574114e_five0220': 'a',
  '63e3610808dc1ca77727b0c9_tmdl0303': 'b',
  '6243835a9d22e940cecde8a5_tmdl0303': 'b',
  '63e3610808dc1ca77727b0c9_bingo319': 'b',
  '6243835a9d22e940cecde8a5_bingo319': 'b',
  '627e2914c0a44f244574114e_bingo319': 'b',
  '61a5a1313a1787613ebc7f2f_bingo319': 'a',
  '6284a17afadd635b552885d8_bingo319': 'b',
  '627d10bfd430af8ffc940c7a_adab0324': 'a',
  '6243835a9d22e940cecde8a5_bugs0330': 'a',
  '627d10bfd430af8ffc940c7a_bugs0330': 'a',
  '627d10bfd430af8ffc940c7a_farm0409': 'b',
  '627e2914c0a44f244574114e_farm0409': 'b',
  '6243835a9d22e940cecde8a5_farm0409': 'b',
  '626aa6a6c4d18f4110ecd6cb_browsingAd0420': 'b',
  '627d10bfd430af8ffc940c7a_browsingAd0420': 'b',
  '627d10bfd430af8ffc940c7a_time0528': 'b',
  '6243835a9d22e940cecde8a5_time0528': 'b',
  '6273409489df7134fe1d3674_waterUpgrade0927': 'a', // prod
  '60f1393ffc798a6746ccb33e_waterUpgrade0927': 'a', // prod
  '64d8a693675a64d4c3e20a31_waterUpgrade0927': 'a', // dev
  '62271be8b3ef552fb2dd424d_tenMin1110': 'a', // 윤수
  '626ec2b1a66592f5c1603f5f_tenMin1110': 'a', // 지헌
  '627d10bfd430af8ffc940c7a_tenMin1110': 'a', // 지성
  '614b747d698b43323956cf2c_tenMin1110': 'a', // 시창
  '64983246bc423e260005dc6e_tenMinDeal1023': 'a', // 정준
  '60f1393ffc798a6746ccb33e_tenMin1110': 'a', // 상우
  '60efd40341aab45cde63806b_tenMin1110': 'a', // 재윤
  '61a5a1313a1787613ebc7f2f_tenMin1110': 'a', // 소현
  '6243835a9d22e940cecde8a5_tenMin1110': 'a', // 유성
  '652562335d83f89762754622_tenMin1110': 'a', // 욱
  '6478a9eac07416db37f2819b_tenMin1110': 'a', // 우성
  '6273409489df7134fe1d3674_dailyMission1011': 'a', // 범래
  '627d10bfd430af8ffc940c7a_dailyMission1011': 'a', // 지성
  '60f1393ffc798a6746ccb33e_dailyMission1011': 'b', // 상우
  '60efd40341aab45cde63806b_dailyMission1011': 'a', // 재윤
  '61a5a1313a1787613ebc7f2f_dailyMission1011': 'a', // 소현
  '6478a9eac07416db37f2819b_dailyMission1011': 'b', // 우성
  '6246b1c63433802aafeb591f_dailyMission1011': 'a', // CS
  '62fd89b8b7f03b3d9be87ea4_dailyMission1011': 'a', // CS
  '61b86c4cc74c1e32428f4f0b_dailyMission1011': 'b', // CS
  '6405a1fa769813c109941fd5_dailyMission1011': 'b', // CS
  '64983246bc423e260005dc6e_winnerDeal1011': 'a', // 정준
  '6280857fa7b6c50ef93b1915_winnerDeal1011': 'c', // 영환
  '64d8a693675a64d4c3e20a31_dailyMission1011': 'a', // 범래 dev
  '614b747d698b43323956cf2c_winnerDeal1016': 'b', // 시창
  '6280857fa7b6c50ef93b1915_winnerDeal1016': 'c', // 영환
  '62494250ec5c9b755afbba10_winnerDeal1016': 'd', // 진희
  '60edc82a02e0338c61886477_winnerDeal1016': 'e', // 현직
  '64983246bc423e260005dc6e_winnerDeal1016': 'f', // 정준
  '64983246bc423e260005dc6e_winnerDeal1020': 'f', // 정준
  '64983246bc423e260005dc6e_winnerDeal1023': 'e', // 정준
  '614b747d698b43323956cf2c_winnerDeal1023': 'e', // 시창
  '641034ec9f31e770891e13fc_1023walk': 'b', // 지성
  '6486ad07e7745aa2fd26566e_tenMinInflowModal1116': 'a', // 우진 dev
  '620cce8196d049583db642e3_tenMinInflowModal1116': 'a', // 우진 prod
  '6273409489df7134fe1d3674_tenMinInflowModal1116': 'a', // 범래 prod
  '651105228137d2b41b5d2e93_tenMinInflowModal1116': 'a', // 민웅 prod
  '620cce8196d049583db642e3_tenMin1110': 'a', // 우진 prod
  '622f092fa58f717049ba2fc9_tenMin1110': 'a', // 우진 prod2
  '6486ad07e7745aa2fd26566e_tenMinInflowModal1127': 'a', // 우진 dev
  '6486ad07e7745aa2fd26566e_tenMinDealBrowsing1230': 'a', // 우진 dev
  '620cce8196d049583db642e3_tenMinDealBrowsing1230': 'a', // 우진 prod
  '620cce8196d049583db642e3_winnerDealInflow1103': 'b', // 우진 prod
  '60edc82a02e0338c61886477_winnerDealInflow1103': 'b', // 현직 prod
  '651105228137d2b41b5d2e93_tenMinDealBrowsing1230': 'a', // 민웅 prod
  '64983246bc423e260005dc6e_tenMinDealBrowsing1230': 'a', // 정준 prod
  '651105228137d2b41b5d2e93_tenMin1110': 'a', // 민웅 prod
  '64983246bc423e260005dc6e_winnerDealInflow1025': 'c', // 정준
  '60edc82a02e0338c61886477_winnerDealInflow1025': 'c', // 현직
  '62494250ec5c9b755afbba10_winnerDealInflow1025': 'a', // 진희
  '64983246bc423e260005dc6e_winnerDealBrowsing1027': 'b', // 정준
  '64983246bc423e260005dc6e_winnerDealInflow1103': 'b', // 정준
  '651105228137d2b41b5d2e93_basketDiscountDeal1211': 'b', // 민웅 prod
  '64983246bc423e260005dc6e_basketDiscountDeal1211': 'a', // 정준 prod
  '60edc82a02e0338c61886477_basketDiscountDeal1211': 'c', // 현직
  '620cce8196d049583db642e3_basketDiscountDeal1211': 'b', // 우진 prod
  '614b747d698b43323956cf2c_basketDiscountDeal1211': 'c', // 시창
  '647a96418ab0fe0da205d06e_basketDiscountDeal1211': 'a', // 종완
  '62494250ec5c9b755afbba10_basketDiscountDeal1211': 'a', // 진희
  '626ec2b1a66592f5c1603f5f_basketDiscountDeal1211': 'b', // 지헌
  '62271be8b3ef552fb2dd424d_basketDiscountDeal1211': 'c', // 윤수
  '6505464c8060a4a4b8b81853_basketDiscountDeal1211': 'a', // 서윤
  '63b56c8ceb55a2a2dae918c8_basketDiscountDeal1211': 'b', // 혜림
  '6301c88204228e2fd03fb3b6_basketDiscountDeal1211': 'c', // 세진
  '652dc352cc8af8976dc9cde1_basketDiscountDeal1211': 'a', // 기수
  '6273409489df7134fe1d3674_basketDiscountDeal1211': 'b', // 범래 prod
  '6405a1fa769813c109941fd5_basketDiscountDeal1211': 'a', // CS
  '61b86c4cc74c1e32428f4f0b_basketDiscountDeal1211': 'c', // CS
  '6246b1c63433802aafeb591f_basketDiscountDeal1211': 'b', // CS
  '64e7cad226232f188ba057d4_basketDiscountDeal1211': 'a', // CS
  '64c32b0bf0180462a319db65_basketDiscountDeal1211': 'b', // CS
  '640e74ca6e93a623a55a5113_basketDiscountDeal1211': 'c', // CS
  '6372d7f0a46f404188904124_basketDiscountDeal1211': 'a', // CS

  '62271be8b3ef552fb2dd424d_toon0919': 'b', // 윤수
  '6243835a9d22e940cecde8a5_toon0919': 'b', // 유성
  '627d10bfd430af8ffc940c7a_toon0919': 'b', // 지성
  '6273409489df7134fe1d3674_toon0919': 'b', // 범래 prod
  '6273409489df7134fe1d3674_tWaterUp1107': 'a', // 범래 prod
  '60f1393ffc798a6746ccb33e_tWaterUp1107': 'a', // 상우 prod
  '64983246bc423e260005dc6e_tWaterUp1107': 'a', // 정준 prod
  '624a566271d05d3e4c35a7ef_tWaterUp1107': 'a', // 승진님
  '652dc352cc8af8976dc9cde1_naverWinnerDealLogic': 'b', // 기수
  '64983246bc423e260005dc6e_naverWinnerDealLogic': 'b', // 정준
  '6273409489df7134fe1d3674_tWaterUpgradeButton1122': 'a', // 범래 prod
  '6273409489df7134fe1d3674_sellerFarm1130': 'b', // 범래 prod
  '64d8a693675a64d4c3e20a31_sellerFarm1130': 'b', // 범래 dev
  '6273409489df7134fe1d3674_tWaterUp1215': 'b', // 범래 prod
  '6273409489df7134fe1d3674_gganbuFarmDeal1220': 'b', // 범래 prod
  '627d10bfd430af8ffc940c7a_waterTank1222': 'b', // production
  '627d10bfd430af8ffc940c7a_purchaseQuest1226': 'b', // production
  '60f7444cd28d5e1c65152c21_purchaseQuest1226': 'b', // production"
  '62271be8b3ef552fb2dd424d_purchaseQuest1226': 'b', // production"
  '627d10bfd430af8ffc940c7a_purchaseQuest0103': 'b', // production
  '627d10bfd430af8ffc940c7a_giveWater0108': 'b', // production
  '6273409489df7134fe1d3674_giveWater0108': 'b', // 범래 prod
  '626aa6a6c4d18f4110ecd6cb_alfarmVideoShare0115': 'b', // 욱 브랜딩영상 카카오톡 공유
  '627d10bfd430af8ffc940c7a_alfarmVideoShare0115': 'b', // 지성
  '6273409489df7134fe1d3674_alfarmVideoShare0115': 'b', // 범래

  '652e877c5b42725b35fccbfe_showDramaFertMission0913': 'a', // 범순 올팜 비료받기 & 물받기 드라마 미션
  '61a5a1313a1787613ebc7f2f_showDramaFertMission0913': 'b', // 소현
  '6676526427b3189f5d5ffe13_showDramaFertMission0913': 'b', // 현경
  '6655a627a6f8326bda1d0abe_showDramaFertMission0913': 'b', // 승호
  '66a8dff88ab45248cf333dfc_showDramaFertMission0913': 'b', // 윤진
  '66ab3c3c355e277da7a0e670_showDramaFertMission0913': 'b', // 규희

  // tmcp0219
}

const map = 'abcdefghij'
const AB = (id = '', key = 'test0000', g = [50]) => {
  if (!id) return 'a'
  if (`${id}_${key}` in members) {
    return members[`${id}_${key}`]
  }
  const a = parseInt(id.toString().slice(-8), 36)
  const b = parseInt(key.slice(-8), 36)
  const code = (((a % 2147483647) * b) % 2147483647) % 100
  let r = ''
  g.forEach((each, i) => {
    if (code < each && !r) r = map[i]
  })

  if (!r) r = map[g.length]

  return r
}

export default AB
