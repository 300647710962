import BottomSheetStore from '../store/BottomSheetStore'
import { isIOS, isIOS13 } from 'react-device-detect'
import ModalStore from '../store/ModalStore'
import UserStore from '../store/UserStore'

const randomString = Math.random().toString(36).slice(2, 8)
const routes = [
  'gganbuFarm',
  'alwayzFarm',
  'luckyPiggy',
  'flappyBird',
  'afiiliation',
  'coffeeGift',
  'chocoGift',
  'sidePickGame',
  'manbogi',
  'sellerFarm',
  'friendGarden',
]

const OnMessageHandler = (event, token, itemType) => {
  try {
    if (event?.data) {
      const message = JSON.parse(event?.data)

      if (message?.type === 'backPress') {
        // 배 작물 임시. 2월 20일 이후 지워도 됨
        if (UserStore?.ModalStore?.isModalOpen === 'itemEndModal') {
          return
        }
        // 배 작물 임시. 2월 20일 이후 지워도 됨
        else if (
          UserStore.giveWaterCount <= 9 ||
          ModalStore.isAutoAppearModal
        ) {
          return
        } else if (ModalStore.isEngagementWaterModal) {
          ModalStore.setIsEngagementWaterModal(false)
          return
        } else if (ModalStore.isLayeredModalOpen) {
          ModalStore.setIsLayeredModalOpen(false)
        } else if (
          ModalStore.isModalOpen !== 'basic' &&
          BottomSheetStore.isBottomSheetOpen !== 'basic'
        ) {
          ModalStore.setIsModalOpen('basic')
          return
        } else if (
          ModalStore.isModalOpen !== 'basic' &&
          BottomSheetStore.isBottomSheetOpen === 'basic'
        ) {
          ModalStore.setIsModalOpen('basic')
          return
        } else if (
          ModalStore.isModalOpen === 'basic' &&
          BottomSheetStore.isBottomSheetOpen !== 'basic'
        ) {
          BottomSheetStore.setIsbottomSheetOpen('basic')
        } else if (window.location.href?.includes('newYearEvent')) {
          window.location.href = `/farmPage?token=${token}&itemType=${itemType}&randomNumber=${randomString}`
          return
        } else if (
          ModalStore.isModalOpen === 'basic' &&
          BottomSheetStore.isBottomSheetOpen === 'basic'
        ) {
          let page = window.location.href.split('/')[3].split('?')[0]

          if (routes.includes(page)) {
            window.location.href = `/farmPage?token=${token}&itemType=${itemType}&randomNumber=${randomString}`
            return
          }
          window?.ReactNativeWebView?.postMessage('goBack')
          window.ReactNativeWebView?.postMessage(
            JSON.stringify({
              type: 'goBack',
            }),
          )
        } else {
          window?.ReactNativeWebView?.postMessage('goBack')
          window.ReactNativeWebView?.postMessage(
            JSON.stringify({
              type: 'goBack',
            }),
          )
        }
      } else if (message?.type === 'orderList') {
        const orderCount = message?.orderList
        UserStore?.setOrderCount(orderCount)
      } else if (message?.type === 'appLovinRewardAdReady') {
        UserStore.setIsRewardAdReady(message?.isRewardedAdReady)
      } else if (message?.type === 'rewardAdMobAdReady') {
        UserStore.setIsRewardAdReady(message?.isRewardedAdReady)
      } else if (message?.type === 'notifyAdReward') {
        UserStore.setIsWatchingAd(true)
      }
    }
  } catch (error) {
    // console.log("json parse error catch", error);
  }
}

const appBridge = (token, itemType) => {
  const OnMessageHandlerWrapper = (event) => {
    OnMessageHandler(event, token, itemType)
  }

  window.ReactNativeWebView?.postMessage(
    JSON.stringify({
      type: 'ready',
    }),
  )

  if (isIOS || isIOS13) {
    window.addEventListener('message', OnMessageHandlerWrapper)
    return () => {
      window.removeEventListener('message', OnMessageHandlerWrapper)
    }
  } else {
    document.addEventListener('message', OnMessageHandlerWrapper)
    return () => {
      document.removeEventListener('message', OnMessageHandlerWrapper)
    }
  }
}

export default appBridge
