const Dimmed = () => {
  return (
    <div
      style={{
        flex: 1,
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 1100,
        width: '100%',
        height: '100vh',
        background: 'rgba(0,0,0,0.8)',
      }}
    />
  )
}

export default Dimmed
