export const OfferwallButton = ({
  text,
  className,
  style,
  clickable = false,
  onClick = () => {},
  subText,
}) => {
  return (
    <div className='flex items-center justify-center w-full '>
      <button
        className={`flex flex-col text-center font-bold p-4 rounded-xl shadow-md justify-center items-center whitespace-nowrap
          w-full mx-6 ${clickable ? 'bg-[#FF5951] text-white active:brightness-90 duration-200 active:scale-95' : 'text-grey40 font-normal shadow-none'}
          ${subText ? 'py-3 ' : ''} ${className} `}
        style={style}
        disabled={!clickable}
        type='button'
        onClick={onClick}
      >
        {text}
        {subText && <div className='text-xs pt-0.5 opacity-65'>{subText}</div>}
      </button>
    </div>
  )
}
