import React, { useState, useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import AuthStore from 'store/AuthStore'
import ModalStore from 'store/ModalStore'
import UserStore from 'store/UserStore'

const EventPageHeader = () => {
  return (
    <header
      style={{
        width: '100%',
        marginTop: '10vw',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 999,
        paddingLeft: '4vw',
        paddingRight: '4vw',
        // position: 'fixed',
      }}
    >
      <NavLink
        to={`/farmPage?token=${AuthStore?.token}&itemType=${
          UserStore?.itemType
        }&randomNumber=${Math.random().toString().slice(0, 8)}`}
      >
        <button
          style={{
            width: '8vw',
            zIndex: 999,
          }}
          action='goBack'
        >
          <img src='/backButton/black.png' alt='back button' />
        </button>
      </NavLink>
      <img
        src='/newYear2024/history.png'
        alt='seasonal event header'
        style={{
          width: '8vw',
          marginRight: '3vw',
          zIndex: 999,
        }}
        onClick={() => {
          ModalStore.setIsModalOpen('familyRecordModal')
        }}
      />
    </header>
  )
}

export default EventPageHeader
