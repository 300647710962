import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import GganbuDeleteModal from './gganbuDeleteModal'
import { useSearchParam } from 'react-use'
import BottomEventIcons from './bottomEventIcons'
import Gganbus from './gganbus'
import GganbuIntroModal from './gganbuIntroModal'
import ModalStore from '../../store/ModalStore'
import SellerFarmList from './sellerFarmList'

const GganbuWithEventIcons = observer(
  ({
    token,
    userId,
    itemType,
    clearedAlwayzFarm,
    userCodePushVersion,
    singleDealInfo,
    setOpenProduceFertMachineModal,
    producingFertMachine,
    produceFertMachineHistory,
    singleDealParticipated,
    giveWaterCount,
    gganbuData,
    setGganbuData,
    gganbuId = '',
    isFromGganbuIntro = false,
    water,
    farmData,
    setOpenFashionModal,
    setOpenLifeModal,
    setOpenSeasonOffModal,
    setOpenJuwelryModal,
    sellerFarmList,
    sellerStoreId = '',
    canClickGetWater,
    canClickGiveWater,
    nutrimentLeavesOn,
  }) => {
    const randomString = Math.random().toString(36).slice(2, 8)

    const [isButtonPressedAlwayzFarm, setIsButtonPressedAlwayzFarm] =
      useState(false)

    const [loadingToGganbuIndicator, setLoadingToGganbuIndicator] =
      useState(false)

    const gganbuScrollRef = useRef()
    const prevScrollPosition = useSearchParam('prevScrollPosition')
    const [scrollPositionX, setScrollPositionX] = useState(0)
    const timer = useRef(null)

    const getGganbuList = async () => {
      const result = await backendApis.getGganbuList(token)
      if (result?.data) {
        setGganbuData(result?.data)
      }
    }

    useEffect(() => {
      setScrollPositionX(prevScrollPosition)
      gganbuScrollRef?.current?.scrollTo(Number(prevScrollPosition), 0)
    }, [gganbuScrollRef, prevScrollPosition])

    if (farmData?.giveWaterCount <= 20)
      return (
        <div
          style={{
            flex: 1,
            marginBottom: '3vw',
            width: '100%',
            height: '20vw',
            // backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: -1,
          }}
        />
      )

    return (
      <div>
        <div
          style={{
            flex: 1,
            marginBottom: '3vw',
            width: '100%',
            height: '20vw',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: -1,
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              overflowX: 'scroll',
              marginLeft: '1vw',
              marginRight: '1vw',
              paddingTop: '1vw',
            }}
            ref={gganbuScrollRef}
            onScroll={() => {
              if (timer.current) clearTimeout(timer.current)
              timer.current = setTimeout(() => {
                setScrollPositionX(gganbuScrollRef?.current?.scrollLeft)
                timer.current = null
              }, 100)
            }}
          >
            <BottomEventIcons
              token={token}
              userId={userId}
              itemType={itemType}
              randomString={randomString}
              clearedAlwayzFarm={clearedAlwayzFarm}
              userCodePushVersion={userCodePushVersion}
              singleDealInfo={singleDealInfo}
              setOpenProduceFertMachineModal={setOpenProduceFertMachineModal}
              producingFertMachine={producingFertMachine}
              produceFertMachineHistory={produceFertMachineHistory}
              singleDealParticipated={singleDealParticipated}
              giveWaterCount={giveWaterCount}
              water={water}
              farmData={farmData}
              setOpenFashionModal={setOpenFashionModal}
              setOpenLifeModal={setOpenLifeModal}
              setOpenSeasonOffModal={setOpenSeasonOffModal}
              setOpenJuwelryModal={setOpenJuwelryModal}
              nutrimentLeavesOn={nutrimentLeavesOn}
            />

            <SellerFarmList
              sellerFarmList={sellerFarmList}
              token={token}
              userId={userId}
              randomString={randomString}
              scrollPositionX={scrollPositionX}
              itemType={itemType}
              setLoadingToGganbuIndicator={setLoadingToGganbuIndicator}
              gganbuData={gganbuData}
              gganbuId={gganbuId}
              sellerStoreId={sellerStoreId}
              farmData={farmData}
              canClickGetWater={canClickGetWater}
              canClickGiveWater={canClickGiveWater}
            />

            <Gganbus
              isFromGganbuIntro={isFromGganbuIntro}
              gganbuData={gganbuData}
              token={token}
              userId={userId}
              itemType={itemType}
              randomString={randomString}
              scrollPositionX={scrollPositionX}
              setLoadingToGganbuIndicator={setLoadingToGganbuIndicator}
              userCodePushVersio={userCodePushVersion}
              gganbuId={gganbuId}
            />
          </div>
        </div>

        {ModalStore?.isModalOpen === 'gganbuDelete' && (
          <GganbuDeleteModal
            gganbuData={gganbuData}
            token={token}
            setGganbuData={setGganbuData}
            // onChanged={() => {
            //   //친구 목록이 변경됨 ( 삭제/추가 )
            //   getGganbuList()
            // }}
          />
        )}
        {ModalStore?.isModalOpen === 'gganbuFarmIntro' && (
          <GganbuIntroModal
            clearedAlwayzFarm={clearedAlwayzFarm}
            token={token}
            userId={farmData?.userId}
            itemType={itemType}
            gganbuData={gganbuData}
            isButtonPressedAlwayzFarm={isButtonPressedAlwayzFarm}
            setIsButtonPressedAlwayzFarm={setIsButtonPressedAlwayzFarm}
          />
        )}
      </div>
    )
  },
)
export default GganbuWithEventIcons
