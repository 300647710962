import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import AuthStore from 'store/AuthStore'
import ModalStore from 'store/ModalStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import backendApis from 'utils/backendApis'

const FarmEfficacyModal = observer(
  ({
    level,
    itemType,
    remainExp,
    expToLevelUp,
    giveWaterBasket,
    setGiveWaterButtonClickedCount,
  }) => {
    const throttleRef = useRef({})

    const remainPercentage = (
      (expToLevelUp / (remainExp + expToLevelUp)) *
      100
    )?.toFixed(2)

    useEffect(() => {
      if (AuthStore.token) {
        backendApis.updatelastUpdatedAt(AuthStore.token, 'PUT', {
          updateType: 'farmEfficacy.showedAt',
        })
      }
    }, [AuthStore.token])

    return (
      <div className='flex-1 flex flex-col fixed left-0 top-0 z-[1100] w-full h-full bg-[rgba(0,0,0,0.8)] items-center justify-center'>
        {/* 타이틀 */}
        <img
          src={`modal/farmEfficacy/${itemType}_title.png`}
          className='w-[80vw]'
        />
        <div className='font-bold text-white'>
          {level === 6 ? '작물 수확까지' : '다음 단계까지'} {remainPercentage}%
          남았어요!
        </div>

        {/* 컨텐츠 */}
        <img
          src={`modal/farmEfficacy/${itemType}_content.png`}
          className='w-[85vw]'
        />
        <div className='mb-1 text-sm text-white'>
          위 이미지와 실제 상품이 다를 수 있어요.
        </div>

        {/* 물주기 버튼 */}
        <div
          className='w-[80vw]'
          onClick={() => {
            ModalStore.setIsModalOpen('basic')
            AlfarmEventLogger({
              throttleRef,
              locationType: 'FarmEfficacyModal',
              locationName: 'CTA_button',
              eventType: 'click',
              collection: 'UserAlfarmClickLog',
            })
            giveWaterBasket()
            setGiveWaterButtonClickedCount((prev) => prev + 1)
          }}
        >
          <img src={`modal/farmEfficacy/giveWaterButton.png`} />
        </div>
        <div
          className='mt-2 text-sm text-gray-400 underline'
          onClick={() => {
            AlfarmEventLogger({
              throttleRef,
              locationType: 'FarmEfficacyModal',
              locationName: 'reject_button',
              eventType: 'click',
              collection: 'UserAlfarmClickLog',
            })
            ModalStore.setIsModalOpen('basic')
            backendApis.updatelastUpdatedAt(AuthStore.token, 'PUT', {
              updateType: 'farmEfficacy.rejectedAt',
            })
          }}
        >
          일주일 동안 보지 않기
        </div>

        {/* 닫기 버튼 */}
        <button
          className='z-[1101] w-[8%] top-[10vw] right-[6vw] absolute'
          onClick={() => {
            AlfarmEventLogger({
              throttleRef,
              locationType: 'FarmEfficacyModal',
              locationName: 'X_button',
              eventType: 'click',
              collection: 'UserAlfarmClickLog',
            })
            ModalStore.setIsModalOpen('basic')
          }}
        >
          <img className='z-[1101]' src='/icon/modalCloseButton.png' alt='' />
        </button>
      </div>
    )
  },
)

export default FarmEfficacyModal
