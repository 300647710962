import { useEffect, useState } from 'react'
import UserStore from '../../store/UserStore'

import backendApis from 'utils/backendApis'
import ModalStore from 'store/ModalStore'
import WhiteTextTimer from 'comps/WhiteTextTimer'
import { checkIsTodayWithISOString } from 'utils/utils'

const RecommendedProductAdButton = ({ token, farmData, closeBottomSheet }) => {
  const MAXIMUM_AD_VIEW_COUNT = 2
  const AD_TIME_INTERVAL_HOURS = 6
  const CODE_PUSH_VERSION_LIMIT = '6.8.37' // 이 버전부터 노출됨

  const [adTarget, setAdTarget] = useState(false) // 광고 타겟 여부
  const [isAdViewAvailable, setIsAdViewAvailable] = useState(false) // 광고 보기 가능한지 - final result
  const [hasRemainingViewCounts, setHasRemainingViewCounts] = useState(null) // 오늘 광고 본 개수
  const [isIntervalTimeReady, setIsIntervalTimeReady] = useState(null) // 광고 보기 간격
  const [hasAdStock, setHasAdStock] = useState(null) // 디비에 광고 잔여 수량

  const [buttonText, setButtonText] = useState('준비 중')
  const [remainedIntervalTime, setRemainedIntervalTime] = useState(0) // 광고 보기 간격 남은 시간
  const [isTimerOn, setIsTimerOn] = useState(false)

  const [buttonClicked, setButtonClicked] = useState(false)

  useEffect(() => {
    checkAdTarget()
    checkAdStatus()
  }, [])

  useEffect(() => {
    setAdStatus()
  }, [hasRemainingViewCounts, hasAdStock, isIntervalTimeReady])

  const checkAdTarget = () => {
    if (
      UserStore?.cherryPickerForAd ||
      UserStore.codePushVersionFromAlwayzApp < CODE_PUSH_VERSION_LIMIT
    )
      return
    setAdTarget(true)
  }

  const checkAdStatus = async () => {
    const clearedAdHistory = await getAdHistory()

    checkViewCounts(clearedAdHistory)
    checkIntervalTime(clearedAdHistory)
    await checkAdStock()
  }

  const setAdStatus = () => {
    if (hasRemainingViewCounts == false) {
      setButtonText('완료')
      return
    }

    if (hasAdStock == false) {
      setButtonText('오늘 마감')
      return
    }

    if (isIntervalTimeReady == false) {
      setButtonText('대기 중')
      setIsTimerOn(true)
      return
    }
    if (isIntervalTimeReady && hasAdStock && hasRemainingViewCounts) {
      setButtonText('구경하기')
      setIsAdViewAvailable(true)
    }
  }

  const getAdHistory = async () => {
    try {
      const result = await backendApis.getWaterBottomSheetInfo(token)
      if (!result) return null

      // 있으면 리턴
      const { clearedRecommendedProductAdHistory: clearedAdHistory } = result
      if (clearedAdHistory) return clearedAdHistory

      // 없으면 초기값 세팅하고 초기값 리턴
      const defaultHistory = {
        clearedAt: new Date(
          Date.now() - 24 * 60 * 60 * 1000, // 24시간(하루)을 밀리초로 변환
        ).toISOString(),
        clearedCount: 0,
      }
      await setAdHistory(defaultHistory.clearedAt, defaultHistory.clearedCount)
      return defaultHistory
    } catch (error) {
      console.error('Failed to get ad history:', error)
      return null
    }
  }

  const setAdHistory = async (clearedAt, clearedCount) => {
    const historyInfo = {
      clearedAt: clearedAt,
      clearedCount: clearedCount,
    }
    try {
      const result = await backendApis.setClearedRecommendedProductAdHistory(
        token,
        'PUT',
        historyInfo,
      )
      if (!result.data) {
        throw new Error('Failed to update ad history')
      }

      return result.data
    } catch (error) {
      console.error('Failed to update ad history:', error)
      return null
    }
  }

  const checkViewCounts = (adInfo) => {
    if (!adInfo) return

    const todayClearedCount = checkIsTodayWithISOString(adInfo?.clearedAt)
      ? adInfo?.clearedCount
      : 0
    const hasRemainingViewCounts = todayClearedCount < MAXIMUM_AD_VIEW_COUNT
    setHasRemainingViewCounts(hasRemainingViewCounts)
  }

  const checkIntervalTime = (adInfo) => {
    if (!adInfo) return

    const lastClearedTime = new Date(adInfo?.clearedAt)
    const timeDifference = new Date().getTime() - lastClearedTime.getTime()
    const isIntervalReady =
      timeDifference >= AD_TIME_INTERVAL_HOURS * 60 * 60 * 1000
    setIsIntervalTimeReady(isIntervalReady)
    setRemainedIntervalTime(
      new Date(
        new Date(adInfo?.clearedAt).getTime() +
          1000 * 60 * 60 * AD_TIME_INTERVAL_HOURS,
      ),
    )
  }

  const checkAdStock = async () => {
    const result = await backendApis.checkCPMAdSetExist()
    if (result?.status === 200) {
      setHasAdStock(result?.data)
    }
  }

  const openAdModal = () => {
    closeBottomSheet()
    ModalStore.setIsModalOpen('recommendedProductAd')
  }

  const getButton = () => {
    const baseButtonClasses = `right-0 absolute py-[2vw] text-[4vw] rounded-[2vw] translate-y-[-50%] top-[50%] z-[100] mr-[4vw] w-[21%]`

    if (!isAdViewAvailable) {
      if (hasRemainingViewCounts && hasAdStock && isTimerOn) {
        return (
          <div className={`${baseButtonClasses} text-[#fff] font-light`}>
            <WhiteTextTimer
              onTimeEnd={() => {
                setIsTimerOn(false)
                setIsIntervalTimeReady(true)
              }}
              timeStamp={remainedIntervalTime}
              timerMinutes={0}
              color='#442b22'
            />
          </div>
        )
      }

      if (hasRemainingViewCounts && hasAdStock && !isTimerOn) {
        return (
          <div
            className={`${baseButtonClasses} text-[#fff] font-bold bg-[#7ED321]`}
          >
            {buttonText}
          </div>
        )
      }

      return (
        <div className={`${baseButtonClasses} text-[#442b22] font-light`}>
          {buttonText}
        </div>
      )
    }

    return (
      <button
        className={`${baseButtonClasses} text-[#fff] font-bold 
        ${buttonClicked ? 'bg-[#ABE066]' : 'bg-[#7ED321]'}`}
        onPointerDown={() => setButtonClicked(true)}
        onPointerCancel={() => setButtonClicked(false)}
        onPointerUp={() => {
          setButtonClicked(false)
          openAdModal()
        }}
      >
        {buttonText}
      </button>
    )
  }

  return (
    adTarget && (
      <div>
        <div className='relative text-center'>
          <img
            className='z-[101] w-full'
            src='https://assets.ilevit.com/de5341bc-84fb-41b0-8eff-8bd14ba02a36.png'
            alt='recommended product ad'
          />
          {getButton()}
        </div>
      </div>
    )
  )
}

export default RecommendedProductAdButton
