import { useEffect, useState } from 'react'

export const useOnlyOneGifticonPerDay = () => {
  const [onlyOneGifticonPerDayModal, setonlyOneGifticonPerDayModal] =
    useState(false)

  useEffect(() => {
    const onlyOneGifticonPerDay = localStorage.getItem('onlyOneGifticonPerDay')
    if (!onlyOneGifticonPerDay) {
      // 모달 표시 로직
      setonlyOneGifticonPerDayModal(true)
      localStorage.setItem('onlyOneGifticonPerDay', 'true')
    }
  }, [])

  return { onlyOneGifticonPerDayModal, setonlyOneGifticonPerDayModal }
}
