import React, { useState, useEffect, useRef } from 'react'
import PlantBasicInfo from './PlantBasicInfo'
import { useInterval } from 'react-use'

window.onload = function () {
  let movementDistance = (window.innerWidth * 12) / 2 + 500
  document.documentElement.style.setProperty(
    '--movement-distance',
    `${movementDistance}px`,
  )
  let init =
    Math.floor((Math.random() * (window.innerWidth * 12)) / 2) +
    Math.random(400)
  document.documentElement.style.setProperty(
    '--initial-movement-distance',
    `${init}px`,
  )
}
const successMessages = [
  '진짜 배송왔어요 대박!! ',
  '품질이 너무 좋네요 ㅎㅎ',
  '엄청 보람차요. 수확하고 나니 기분이 좋네요!',
  '드디어 다 키웠다~~ 맛있게 먹겠습니다.',
  '수확 성공! 나는 진짜 농사왕',
  '와우! 수확 대성공!',
  '여러분도 할 수 있어요!! 화이팅 ㅎㅎ',
  '딸이 너무 좋아해요~^^',
  '수확 완료! 이번에는 계란 갑니다',
  '3번 째 수확입니다 ㅋㅋ',
  '이야~ 정말 신선하네요!',
  '한 번 수확하니까 멈출 수 없어요!',
  '처음으로 수확했는데 이렇게 재밌을 줄이야!',
  '너무 잘 자라줘서 뿌듯합니다!',
  '내 손으로 직접 수확하니 더 맛있을 것 같아요.',
  '이만큼 키운 보람이 있네요!',
  '정말 대박! 이런 수확은 처음이에요!',
  '드디어 열매를 맺었네요. 너무 행복해요!',
  '다음에도 꼭 성공할 거 같아요! 자신감 상승!',
  '모두가 함께 도와줘서 더 기뻐요.',
  '수확 후에 먹을 생각에 벌써 설레요!',
  '하루하루 잘 자라는 모습이 기분 좋아요!',
  '이번 수확, 정말 최고예요!',
  '주변 사람들도 다들 신기해하네요!',
  '내가 직접 재배한 걸 나눠줄 생각에 뿌듯해요.',
  '이렇게 많은 걸 수확할 줄 몰랐어요!',
  '오늘의 수확은 완벽합니다!',
  '수확하면서 농사의 재미를 알아가고 있어요.',
  '키우는 동안 걱정했는데, 수확하니 기쁨이 두 배네요!',
  '이번에도 성공! 이제 프로 농사꾼 같아요!',
]

const randomMessage =
  successMessages[Math.floor(Math.random() * successMessages?.length)]

const MovingComponentsScreen = ({ userList }) => {
  const [offset, setOffset] = useState(0)
  const [visible, setVisible] = useState(false)
  const [containerWidth, setContainerWidth] = useState(0)
  const offsetRef = useRef(offset) // create a ref for offset
  const [randomMessages, setRandomMessages] = useState([])

  useEffect(() => {
    const generateRandomMessages = () => {
      const messages = Array(30)
        .fill(null)
        .map(
          () =>
            successMessages[Math.floor(Math.random() * successMessages.length)],
        )
      setRandomMessages(messages)
    }

    generateRandomMessages()
  }, [])

  useInterval(() => {
    setOffset((prevOffset) => {
      const newOffset = prevOffset - 4
      offsetRef.current = newOffset // update offset ref
      return newOffset
    })
  }, 50)

  useEffect(() => {
    const containerWidth = document.getElementById('container').clientWidth
    setContainerWidth(containerWidth)
    const initialOffset = Math.floor(Math.random() * 300 + containerWidth / 2)
    setOffset(initialOffset)
    offsetRef.current = initialOffset // update offset ref
    setVisible(true)
  }, [])

  useEffect(() => {
    if (offsetRef.current < (containerWidth / 2) * -1) {
      // use offset ref
      setVisible(false)
      const newOffset = containerWidth / 2 + 500
      setOffset(newOffset)
      offsetRef.current = newOffset // update offset ref
      setTimeout(() => {
        setVisible(true)
      }, 100)
    }
  }, [offset, containerWidth])

  const maskUserName = (name) => {
    if (!name) return ''

    const length = name.length
    const firstPart = Math.ceil(length / 3)
    const maskedPart = length - firstPart

    return name.substring(0, firstPart) + '*'.repeat(maskedPart)
  }

  return (
    <div style={styles.screen}>
      <div style={styles.componentsContainer} id='container'>
        {userList?.map((item, index) => (
          <div
            key={index}
            className='slide-left'
            style={{
              ...styles.component,
              display: visible ? 'flex' : 'none',
            }}
          >
            <div
              style={{
                width: '6vw',
                height: '6vw',
                borderRadius: 99,
                backgroundImage: `url(${item.thumbnailUserImageUri})`,
                marginRight: 8,
              }}
            />
            <div
              style={{
                fontSize: '4.5vw',
                padding: '1vw',
              }}
            >
              {randomMessages[1]}(
              {maskUserName(
                item?.userName.length > 5
                  ? item?.userName.slice(0, 5) + '..'
                  : item?.userName,
              )}
              님)
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const styles = {
  screen: {
    // width: "100%",
    // height: "100vh",
    // backgroundColor: "#f2f2f2",
    overflow: 'hidden',
    marginTop: 4,
  },
  componentsContainer: {
    display: 'flex',
    position: 'relative',
    width: '1400vw',
    justifyContent: 'space-around',
    alignItems: 'center',
    // height: "12vw",
    // height: "100%",
  },
  component: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // height: "10vw",
    backgroundColor: '#000000aa',
    fontSize: '3.5vw',
    color: 'white',
    borderRadius: 99,
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.3s ease',
    padding: 2,
    paddingRight: 8,
  },
}

export default MovingComponentsScreen
