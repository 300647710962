import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ModalStore from 'store/ModalStore'
import UserStore from 'store/UserStore'
import AlfarmEventLogger from 'utils/alfarmEventLogger'
import backendApis from 'utils/backendApis'

const MissionList = observer(({dailyBadahamData, refetchDailyBadahamData }) => {
  const throttleRef = useRef({})
  const [badahamConfig, setBadahamConfig] = useState(null)
  const [loadingStates, setLoadingStates] = useState({})
  const navigate = useNavigate()

  const getBadahamConfig = async () => {
    const res = await backendApis.getDailyEventQuestConfig({event: 'badaham'})
    if(res?.status === 200) {
      setBadahamConfig(res?.data)
    }
  }
  useEffect(() => {
    getBadahamConfig()
  }, [])

  const getButtonText = (mission) => {
    if(loadingStates[mission.questType]) return '처리중...'
    if(mission.questType === 'adWatch' && !mission.isActive) return '광고 준비중'
    
    const missionType = mission.questType
    const limitCount = badahamConfig?.[missionType]?.limitCount
    const clearedCount = mission?.clearedCount || 0
    const isCompleted = clearedCount >= limitCount

    return isCompleted ? '완료' : `${limitCount-clearedCount}번 가능`
  }
  
  const enableAdWatch = UserStore.isRewardAdReady
  const handleClickMissionButton = async ({limitCount, clearedCount, questType}) => {
    if(limitCount <= clearedCount || loadingStates[questType]){
      return;
    }
    
    AlfarmEventLogger({
      throttleRef,
      locationType: 'badahamScreen',
      locationName: `mission_list_${questType}`,
      eventType: 'click',
      collection: 'UserAlfarmClickLog',
      needSampling: false,
    })

    try {
      switch(questType) {
        case 'dailyCheckIn':
          setLoadingStates(prev => ({ ...prev, [questType]: true }))
          await backendApis.clearBadahamMission({ questType })
          refetchDailyBadahamData()
          break;
        case 'adWatch':
          if(!enableAdWatch) {
            break;
          }
          ModalStore.setIsModalOpen('rewardAdModalWater')
          break;
        case 'offerwall':
          navigate('/offerwall?from=badaham')
          break;
      }
    } catch (error) {
      console.error('미션 처리 실패:', error)
    } finally {
      setLoadingStates(prev => ({ ...prev, [questType]: false }))
    }

    AlfarmEventLogger({
      throttleRef,
      locationType: 'page',
      locationName: 'badahamScreen',
      eventType: 'click',
      eventName: questType,
      data: {},
      collection: 'UserAlfarmClickLog',
    })
  }

  const missions = [
    {
      title: '출석하기',
      questType: 'dailyCheckIn',
      icon: '/ads/badahamMission/checkinIcon.png',
      description: (
        <div className='flex items-center text-[4vw] text-[#888888]'>
          하루 1번
        </div>
      ),
      clearedCount: dailyBadahamData?.questInfo?.dailyCheckIn?.length || 0,
      limitCount: badahamConfig?.dailyCheckIn?.limitCount,
    },
    {
      title: '광고보기',
      questType: 'adWatch',
      icon: '/ads/badahamMission/tvIcon.png',
      description: (
        <div className='flex items-center text-[4vw] text-[#888888]'>
          시청당 1번
          <img src='/icon/water.png' alt='' className='w-[5vw] mx-1' />
          지급
        </div>
      ),
      isActive: enableAdWatch,
      clearedCount: dailyBadahamData?.questInfo?.adWatch?.length || 0,
      limitCount: badahamConfig?.adWatch?.limitCount,
    },
    {
      title: '미션하고 보상받기',
      questType: 'offerwall',
      icon: '/ads/badahamMission/coinIcon.png',
      description: (
        <div className='flex items-center text-[4vw] text-[#888888]'>
          참여당 1번
          <img src='/icon/water.png' alt='' className='w-[5vw] mx-1' />
          지급
        </div>
      ),
      clearedCount: dailyBadahamData?.questInfo?.offerwall?.length || 0,
      limitCount: badahamConfig?.offerwall?.limitCount,
    },
  ]

  return (
    <div className='w-full rounded-[12px] bg-[#FFF] px-[6px] py-[16px]'>
      {missions
        .sort((a, b) => {
          // MEMO(민국): 클리어한 미션 리스트에서 뒤로 미루기 위한 로직
          const aCompleted = a.clearedCount >= a.limitCount;
          const bCompleted = b.clearedCount >= b.limitCount;
          if (aCompleted && !bCompleted) return 1;
          if (!aCompleted && bCompleted) return -1;
          return 0;
        })
        .map((mission) => (
          <div key={mission.title} className='px-[20px] py-[4px]'>
            <div className='w-full flex items-center h-[7vh] justify-between'>
              <div className='flex items-center'>
                <img src={mission.icon} alt='icon' className='w-12' />
                <div className='flex flex-col h-full justify-center ml-2'>
                  <div className='text-black font-bold'>{mission.title}</div>
                  {mission.description}{' '}
                </div>
              </div>
              <div
                className='rounded-lg p-[2vw] font-bold w-[94px] flex justify-center'
                style={{
                  backgroundColor: mission.clearedCount >= mission.limitCount || 
                    loadingStates[mission.questType]
                      ? '#F3F3F3' 
                      : mission.questType === 'adWatch' && !mission.isActive
                        ? 'transparent'  // 광고 준비중일 때는 배경색 없음
                        : '#E7FCFF',
                  color: mission.clearedCount >= mission.limitCount || 
                    loadingStates[mission.questType] ||
                    (mission.questType === 'adWatch' && !mission.isActive)
                      ? '#8E8E8E' 
                      : '#26C0DB',
                  cursor: loadingStates[mission.questType] || 
                    (mission.questType === 'adWatch' && !mission.isActive)
                      ? 'not-allowed' 
                      : 'pointer'
                }}
                onClick={() => {
                  handleClickMissionButton({
                    limitCount: mission.limitCount, 
                    clearedCount: mission.clearedCount, 
                    questType: mission.questType
                  }) 
                }}
              >
                {getButtonText(mission)}
              </div>
            </div>
            {missions.indexOf(mission) !== missions.length - 1 && (
                <div className='w-full h-[1px] bg-[#F6F6FA]'></div>
            )}
          </div>
        ))}
    </div>
  )
})

export default MissionList
