import { observable } from 'mobx'

const AlwayzUserStore = observable({
  alwayzUserData: {},
  teamDealId: null,

  setAlwayzUserData(data) {
    this.alwayzUserData = data
  },
  setTeamDealId(teamDealId) {
    this.teamDealId = teamDealId
  },

  setProperty(obj, keys, value) {
    let key
    while (keys.length > 1) {
      key = keys.shift()
      if (key && !Object.prototype.hasOwnProperty.call(obj, key)) obj[key] = {}
      if (key) obj = obj[key]
    }
    obj[keys[0]] = value
  },

  set(key, value) {
    if (key.includes('.')) {
      const keys = key.split('.')
      this.setProperty(this, keys, value)
    } else {
      this[key] = value
    }
  },
})

export default AlwayzUserStore
