import { observer } from 'mobx-react-lite'
import React from 'react'
import backendApis from 'utils/backendApis'
import centerConfetti from '../../json/gifJson/confetti/centerConfetti.json'
import LottieGif from './LottieGif'
import ModalStore from 'store/ModalStore'

const OfferwallMissionCompleteModal = observer(({ farmData }) => {
  const rewardTypeKOR = [
    {
      rewardType: 'fertilizerSet',
      rewardTypeKOR: '고급비료',
      imgSrc: '/icon/fertilizerSet.png',
    },
    {
      rewardType: 'fertilizer',
      rewardTypeKOR: '일반비료',
      imgSrc: '/icon/fertilizer.png',
    },
    { rewardType: 'water', rewardTypeKOR: '물', imgSrc: '/icon/water.png' },
  ]

  // rewardList에서 보상 정보를 추출
  const rewards = farmData.unCheckedOfferwallHistory || []

  // 문구 설정
  const rewardText = rewards
    ?.map((item) => {
      const rewardInfo = rewardTypeKOR?.find(
        (reward) => reward.rewardType === item.rewardType,
      )
      if (item.rewardType === 'water') {
        return `${rewardInfo?.rewardTypeKOR} ${item.rewardAmount}g`
      } else {
        return `${rewardInfo?.rewardTypeKOR} ${item.rewardAmount}개`
      }
    })
    .join(', ')

  // 마지막 보상 종류에 따른 조사 처리
  const lastRewardText = rewardText.endsWith('g')
    ? '을 받았어요!'
    : '를 받았어요!'

  // 이미지 필터링
  const rewardImages = rewards.map((item) => {
    const rewardInfo = rewardTypeKOR?.find(
      (reward) => reward?.rewardType === item?.rewardType,
    )
    return rewardInfo?.imgSrc
  })
  const uniqueRewardImages = rewardImages.filter(
    (item, index) => rewardImages.indexOf(item) === index,
  )

  return (
    <>
      <div className='flex absolute top-0 left-0 w-full h-full z-[140]'>
        <div className='bg-black absolute w-full h-full opacity-80' />
        <LottieGif
          animationData={centerConfetti}
          className='absolute z-[140] top-[50vw]'
        />
        <img
          className='absolute w-[100vw] top-[80vw] left-[50vw] transform translate-x-[-50%] translate-y-[-50%] p-[4vw]'
          src='/modal/missionRewardModal.png'
          alt='missionReward'
        />
        <div className='absolute w-[60vw] h-[55vw] top-[107vw] left-[50%] transform translate-x-[-50%] translate-y-[-50%] p-[4vw]'>
          <div className='flex w-full justify-center'>
            {/* 보상 이미지 출력 */}
            {uniqueRewardImages.map((src, index) => (
              <img
                key={index}
                className='w-[18vw] mt-[4vw] mx-[1vw]'
                alt='reward'
                src={src}
              />
            ))}
          </div>
          <div className='text-black text-center text-[4.8vw] mt-[6vw]'>
            {/* 보상 텍스트 출력 */}
            {rewardText + lastRewardText}
          </div>
        </div>
        <button
          className='absolute w-[60vw] h-[12vw] top-[145vw] left-[50%] transform translate-x-[-50%] translate-y-[-50%] flex justify-center z-[150]'
          onClick={async () => {
            ModalStore.setIsModalOpen('basic')
            await backendApis.updateOfferwallHistoryChecked()
          }}
        >
          <img
            className='w-[80%] h-auto'
            src='/icon/greenConfirmButton.png'
            alt='confirm'
          />
        </button>
      </div>
    </>
  )
})

export default OfferwallMissionCompleteModal
